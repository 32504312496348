var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-date-picker", {
        attrs: {
          value: _vm.date,
          mode: "single",
          name: _vm.name,
          "input-props": _vm.inputProps
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }