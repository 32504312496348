<template>
    <div>
        <h4>Scoresheets in localStorage</h4>
        <div
            v-for="(value, key) in localStorageValues"
            :key="key"
        >
            <div v-if="key.includes('scoresheet')">
                <hr />
                <strong>{{ key }}</strong>
                <br />{{ value }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
  data () {
    return {
      localStorageValues: window.localStorage
    }
  }
}

</script>
