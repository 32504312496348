var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-10" }, [
    _c(
      "form",
      {
        attrs: { method: "post", novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "name" } }, [_vm._v("Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$v.formData.name.$model,
                expression: "$v.formData.name.$model"
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid": _vm.attemptSubmit && _vm.$v.formData.name.$invalid,
              "is-valid": _vm.attemptSubmit && !_vm.$v.formData.name.$invalid
            },
            attrs: { id: "name", type: "text", placeholder: "Enter name" },
            domProps: { value: _vm.$v.formData.name.$model },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.$v.formData.name, "$model", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          !_vm.$v.formData.name.required && _vm.attemptSubmit
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("\n                This field is required\n            ")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$v.formData.name.maxLength && _vm.attemptSubmit
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("\n                This maximum is 255\n            ")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$v.formData.name.minLength && _vm.attemptSubmit
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n                This minimum length is 2\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "company" } }, [_vm._v("Company")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$v.formData.company.$model,
                expression: "$v.formData.company.$model"
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid":
                _vm.attemptSubmit && _vm.$v.formData.company.$invalid,
              "is-valid": _vm.attemptSubmit && !_vm.$v.formData.company.$invalid
            },
            attrs: {
              id: "company",
              type: "text",
              placeholder: "Enter company"
            },
            domProps: { value: _vm.$v.formData.company.$model },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.$v.formData.company, "$model", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          !_vm.$v.formData.company.required && _vm.attemptSubmit
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("\n                This field is required\n            ")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$v.formData.company.maxLength && _vm.attemptSubmit
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("\n                This maximum is 255\n            ")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$v.formData.company.minLength && _vm.attemptSubmit
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n                This minimum length is 2\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "email" } }, [_vm._v("Email address")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$v.formData.email.$model,
                expression: "$v.formData.email.$model"
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid": _vm.attemptSubmit && _vm.$v.formData.email.$invalid,
              "is-valid": _vm.attemptSubmit && !_vm.$v.formData.email.$invalid
            },
            attrs: {
              id: "email",
              type: "email",
              "aria-describedby": "emailHelp",
              placeholder: "Enter email"
            },
            domProps: { value: _vm.$v.formData.email.$model },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.$v.formData.email, "$model", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          !_vm.$v.formData.email.required && _vm.attemptSubmit
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("\n                This field is required\n            ")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$v.formData.email.email && _vm.attemptSubmit
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("\n                Enter a valid email\n            ")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$v.formData.email.maxLength && _vm.attemptSubmit
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("\n                This maximum is 255\n            ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "small",
            { staticClass: "form-text text-muted", attrs: { id: "emailHelp" } },
            [_vm._v("We'll never share your email with anyone else.")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "phone" } }, [_vm._v("Phone number")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$v.formData.phone.$model,
                expression: "$v.formData.phone.$model"
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid": _vm.attemptSubmit && _vm.$v.formData.phone.$invalid,
              "is-valid": _vm.attemptSubmit && !_vm.$v.formData.phone.$invalid
            },
            attrs: { id: "phone", type: "phone", placeholder: "###-###-####" },
            domProps: { value: _vm.$v.formData.phone.$model },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.$v.formData.phone, "$model", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          !_vm.$v.formData.phone.required && _vm.attemptSubmit
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("\n                This field is required\n            ")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$v.formData.phone.maxLength && _vm.attemptSubmit
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("\n                This maximum is 255\n            ")
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "name" } }, [_vm._v("Message")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$v.formData.message.$model,
                expression: "$v.formData.message.$model"
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid":
                _vm.attemptSubmit && _vm.$v.formData.message.$invalid,
              "is-valid": _vm.attemptSubmit && !_vm.$v.formData.message.$invalid
            },
            attrs: { id: "message", placeholder: "Enter a message" },
            domProps: { value: _vm.$v.formData.message.$model },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.$v.formData.message, "$model", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          !_vm.$v.formData.message.required && _vm.attemptSubmit
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("\n                This field is required\n            ")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$v.formData.message.minLength && _vm.attemptSubmit
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("\n                Please tell us more\n            ")
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: {
              id: "submit-button",
              type: "submit",
              disabled: _vm.submitStatus === "PENDING"
            }
          },
          [
            _c("i", { staticClass: "fas fa-check-circle" }),
            _vm._v("\n            Submit\n        ")
          ]
        ),
        _vm._v("\n         "),
        _c(
          "a",
          {
            attrs: { href: "javascript:void(0)" },
            on: { click: _vm.clearForm }
          },
          [_vm._v("Clear")]
        ),
        _vm._v(" "),
        _vm.submitStatus === "OK"
          ? _c("p", { staticClass: "alert alert-success mt-2" }, [
              _vm._v(
                "\n            Thanks for your submission! A representative will be in touch with you.\n        "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.submitStatus === "ERROR"
          ? _c("p", { staticClass: "alert alert-warning mt-2" }, [
              _vm._v("\n            Please fill the form correctly.\n        ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.submitStatus === "PENDING"
          ? _c("p", { staticClass: "alert alert-info mt-2" }, [
              _vm._v("\n            Sending...\n        ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.submitStatus === "FAILED"
          ? _c("p", { staticClass: "alert alert-danger mt-2" }, [
              _vm._v("\n            Failed to complete your request\n        ")
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }