<template>
    <div class="form-row">
        <div class="col-12">
            <!-- Contests -->
            <div class="form-group">
                <label for="contest-select">Contest</label>
                <select
                    id="contest-select"
                    v-model="request.contest_id"
                    name="contest_id"
                    :class="['form-control', (errors.contest_id && errors.contest_id.length > 0) ? 'is-invalid' : '']"
                    @change="handleOnContestChange"
                >
                    <option
                        value=""
                    >
                        -- All Contests --
                    </option>
                    <option
                        v-for="contest in localContests"
                        :key="contest.id"
                        :value="contest.id"
                    >
                        {{ trimTitle(contest.title) }}...
                    </option>
                </select>
                <div
                    v-if="errors.contest_id && errors.contest_id.length > 0"
                    class="invalid-feedback"
                >
                    {{ errors.contest_id[0] }}
                </div>
            </div>

            <!-- Classes -->
            <div class="form-group">
                <div v-if="localContestClasses && localContestClasses.length > 0">
                    <label for="class-select">Class</label>
                    <select
                        id="class-select"
                        v-model="request.class_id"
                        name="class_id"
                        :class="['form-control', (errors.class_id && errors.class_id.length > 0) ? 'is-invalid' : '']"
                        @change="handleOnClassChange"
                    >
                        <option value="">
                            -- All Classes --
                        </option>
                        <option
                            v-for="contestClass in localContestClasses"
                            :key="contestClass.id"
                            :value="contestClass.id"
                        >
                            {{ removeTrailingZero(contestClass.number) }} - {{ contestClass.name }}
                        </option>
                    </select>
                    <div
                        v-if="errors.class_id && errors.class_id.length > 0"
                        class="invalid-feedback"
                    >
                        {{ errors.class_id[0] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    contests: {
      type: Array,
      default: () => [],
      required: true
    },
    request: {
      type: Object,
      default: function () {
        return {
          contest_id: ''
        }
      }
    },
    errors: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      localContests: this.contests,
      localContestClasses: []
    }
  },
  created: function () {
    this.getContestClass(this.request.contest_id)
    if (!this.request.contest_id) {
      this.request.contest_id = ''
    }
    if (!this.request.class_id) {
      this.request.class_id = ''
    }
  },
  methods: {
    trimTitle (title) {
      return title.substring(0, 40)
    },
    handleOnContestChange (e) {
      this.request.contest_id = e.target.value
      this.getContestClass(e.target.value)
      this.errors.contest_id = []
      this.errors.class_id = []
      this.request.class_id = ''
    },
    handleOnClassChange (e) {
      this.request.class_id = e.target.value
    },
    getContestClass (id) {
      let contestClasses = []
      this.localContests.map(function (contest) {
        if (Number(id) === Number(contest.id)) {
          contestClasses = contest.contest_classes
        }
      })
      this.localContestClasses = contestClasses
    },
    removeTrailingZero (number) {
      return parseFloat(number)
    }
  }
}
</script>
