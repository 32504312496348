<script>
import { HorizontalBar } from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  props: {
    chartdata: {
      type: Object,
      default () {
        return {}
      }
    },
    options: {
      type: Object,
      default () {
        return {
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    }
  },
  watch: {
    chartdata: function () {
      // this.chart.destroy();
      this.renderChart(this.chartdata, this.options)
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>
