var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _vm.isLoadingContest
          ? _c("div", { staticClass: "w-100 text-center h3" }, [
              _c("i", { staticClass: "fas fa-spinner fa-spin" }),
              _vm._v(" Loading...\n        ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.contests, function(contest) {
          return _c(
            "div",
            { key: contest.id, attrs: { id: "contest-" + contest.id } },
            [
              _c(
                "a",
                {
                  staticClass:
                    "btn btn-primary mb-3 d-block w-100 btn-lg rounded-0 font-weight-bold",
                  class: {
                    active: contest.id == _vm.activeContest,
                    disabled: _vm.isLoadingClasses
                  },
                  attrs: { disabled: _vm.isLoadingClasses },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.getContestClasses(contest.id)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas",
                    class: {
                      "fa-chevron-up":
                        contest.id == _vm.activeContest &&
                        _vm.isLoadingClasses == false,
                      "fa-spinner fa-spin":
                        contest.id == _vm.activeContest &&
                        _vm.isLoadingClasses == true,
                      "fa-chevron-down": contest.id != _vm.activeContest
                    }
                  }),
                  _vm._v(" \n                "),
                  _c("i", {
                    staticClass: "fas",
                    class: {
                      "fa-globe": contest.category == "wccc",
                      "fa-flag-usa": contest.category == "usccc"
                    }
                  }),
                  _vm._v(
                    "\n                " +
                      _vm._s(contest.title) +
                      "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              contest.id == _vm.activeContest
                ? _c("div", [
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-info w-100 mb-3 font-weight-bold rounded-0",
                        class: {
                          active: _vm.finalThreeResultsVisible === false
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.showFinalThreeResults($event)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fas",
                          class: {
                            "fa-chevron-up":
                              _vm.finalThreeResultsVisible === true,
                            "fa-chevron-down":
                              _vm.finalThreeResultsVisible === false
                          }
                        }),
                        _vm._v(
                          "\n                    CHAMPION and Runners Up\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.isLoadingFinalThree &&
                    _vm.finalThreeResults[_vm.activeContest] &&
                    _vm.finalThreeResultsVisible == true
                      ? _c("div", { staticClass: "container border mb-3" }, [
                          _c(
                            "div",
                            { staticClass: "row" },
                            _vm._l(
                              _vm.finalThreeResults[_vm.activeContest].results,
                              function(final, key) {
                                return _c(
                                  "div",
                                  {
                                    key: key,
                                    staticClass: "col-12 col-md-4 p-2"
                                  },
                                  [
                                    _c("h5", [
                                      _vm._v(_vm._s(_vm.awardTitle(key, final)))
                                    ]),
                                    _vm._v(" "),
                                    _c("strong", [_vm._v("Maker:")]),
                                    _c("br"),
                                    _vm._v(_vm._s(final.maker)),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("strong", [_vm._v("Cheese:")]),
                                    _c("br"),
                                    _vm._v(_vm._s(final.description)),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("strong", [_vm._v("Location:")]),
                                    _c("br"),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(final.company)
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(final.city) +
                                        _vm._s(
                                          final.state ? ", " + final.state : ""
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(final.country)
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("strong", [_vm._v("Score:")]),
                                    _c("br"),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(parseFloat(final.score)) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              contest.id == _vm.activeContest && !_vm.isLoadingClasses
                ? _c("div", [
                    !_vm.showTop3PerClass
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "btn btn-info w-100 mb-3 font-weight-bold rounded-0",
                            class: {
                              active: contest.id == _vm.activeContestTopThree,
                              disabled: _vm.isLoadingTopThreePerClass
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.getTopThree(contest.id)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-chevron-right" }),
                            _vm._v(
                              "\n                    View Top 3 Per Class\n                "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showTop3PerClass
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "btn btn-info w-100 mb-3 font-weight-bold rounded-0",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.getByClass()
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa fa-chevron-left" }),
                            _vm._v(
                              "\n                    View All Results by Class\n                "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "border border-dark p-2 mb-3" },
                      [
                        _c(
                          "h4",
                          {
                            staticClass: "text-center py-2 bg-dark text-white"
                          },
                          [
                            _c("i", { staticClass: "fas fa-award" }),
                            _vm._v(
                              " Viewing " +
                                _vm._s(
                                  _vm.showTop3PerClass
                                    ? "Top Three / Class"
                                    : "Results by Class"
                                ) +
                                "\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showTop3PerClass == true,
                                expression: "showTop3PerClass == true"
                              }
                            ]
                          },
                          [
                            _c(
                              "div",
                              {
                                class: {
                                  "text-center": _vm.isLoadingTopThreePerClass
                                }
                              },
                              [
                                _vm.showTop3PerClass == true &&
                                _vm.isLoadingTopThreePerClass == true
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-spinner fa-spin fa-3x"
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.topThreeResults[contest.id],
                                  function(result, index) {
                                    return _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.showTop3PerClass &&
                                              !_vm.isLoadingTopThreePerClass,
                                            expression:
                                              "(showTop3PerClass && !isLoadingTopThreePerClass)"
                                          }
                                        ],
                                        key: index,
                                        attrs: { id: "result-" + result.id }
                                      },
                                      [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "mt-3 bg-dark text-white p-2"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(result.class.name) +
                                                " (" +
                                                _vm._s(
                                                  _vm.cleanClassNumber(
                                                    result.class.number
                                                  )
                                                ) +
                                                ")\n                                "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(result.entries, function(
                                          entry,
                                          entryId
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: entryId,
                                              staticClass: "container"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-md-none" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            Place: " +
                                                          _vm._s(entry.place) +
                                                          "\n                                        "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-2"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-md-none font-weight-bold"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                Team/Maker:\n                                            "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(entry.maker) +
                                                          "\n                                        "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-3"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-md-none font-weight-bold"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                Company:\n                                            "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            entry.company
                                                          ) +
                                                          "\n                                        "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(entry.city) +
                                                          _vm._s(
                                                            entry.state
                                                              ? ", " +
                                                                  entry.state
                                                              : ""
                                                          )
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            entry.country
                                                          ) +
                                                          "\n                                        "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-1"
                                                    },
                                                    [
                                                      entry.score_average !==
                                                      null
                                                        ? _c("div", [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-md-none font-weight-bold"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                    Score:\n                                                "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  parseFloat(
                                                                    entry.score_average
                                                                  )
                                                                ) +
                                                                "\n                                            "
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-1"
                                                    },
                                                    [
                                                      entry.place == 1
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "Best of Class"
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      entry.place == 2
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "Second Award"
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      entry.place == 3
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "Third Award"
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-2"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-md-none font-weight-bold"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                Description:\n                                            "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            entry.brand_name
                                                          ) +
                                                          "\n                                        "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("hr")
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  }
                                )
                              ],
                              2
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.contestClasses[contest.id], function(
                          contestClass
                        ) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.showTop3PerClass,
                                  expression: "!showTop3PerClass"
                                }
                              ],
                              key: contestClass.id,
                              attrs: { id: "class-" + contestClass.id }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-light w-100 rounded-0 mb-2",
                                  class: {
                                    "font-weight-bold active":
                                      contestClass.id == _vm.activeContestClass,
                                    disabled: _vm.isLoadingResults == true
                                  },
                                  attrs: { disabled: _vm.isLoadingResults },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.getClassResults(
                                        contestClass.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas",
                                    class: {
                                      "fa-chevron-up":
                                        contestClass.id ==
                                          _vm.activeContestClass &&
                                        _vm.isLoadingResults == false,
                                      "fa-spinner fa-spin":
                                        contestClass.id ==
                                          _vm.activeContestClass &&
                                        _vm.isLoadingResults == true,
                                      "fa-chevron-down":
                                        contestClass.id !=
                                        _vm.activeContestClass
                                    }
                                  }),
                                  _vm._v(
                                    " \n                            " +
                                      _vm._s(contestClass.name) +
                                      " (" +
                                      _vm._s(
                                        _vm.cleanClassNumber(
                                          contestClass.number
                                        )
                                      ) +
                                      ")\n                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              contestClass.id == _vm.activeContestClass &&
                              !_vm.isLoadingResults
                                ? _c(
                                    "div",
                                    { staticClass: "mb-3" },
                                    _vm._l(
                                      _vm.classResults[_vm.activeContestClass]
                                        .entry_scores,
                                      function(score, scoreKey) {
                                        return _c(
                                          "div",
                                          { key: scoreKey },
                                          _vm._l(
                                            _vm.sortEntries(score.entries),
                                            function(entry, key) {
                                              return _c("div", { key: key }, [
                                                _c(
                                                  "div",
                                                  { staticClass: "d-md-none" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            Place: " +
                                                            _vm._s(
                                                              scoreKey + 1
                                                            ) +
                                                            "\n                                        "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 col-md-2"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-md-none font-weight-bold"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                Team/Maker:\n                                            "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              entry.maker
                                                            ) +
                                                            "\n                                        "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 col-md-3"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-md-none font-weight-bold"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                Company:\n                                            "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              entry.company
                                                            ) +
                                                            "\n                                        "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 col-md-3"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(entry.city) +
                                                            _vm._s(
                                                              entry.state
                                                                ? ", " +
                                                                    entry.state
                                                                : ""
                                                            )
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              entry.country
                                                            ) +
                                                            "\n                                        "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 col-md-1"
                                                      },
                                                      [
                                                        entry.score_average !==
                                                        null
                                                          ? _c("div", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-md-none font-weight-bold"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                    Score:\n                                                "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "\n                                                " +
                                                                  _vm._s(
                                                                    parseFloat(
                                                                      entry.score_average
                                                                    )
                                                                  ) +
                                                                  "\n                                            "
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 col-md-1"
                                                      },
                                                      [
                                                        scoreKey == 0
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "Best of Class"
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        scoreKey == 1
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "Second Award"
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        scoreKey == 2
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "Third Award"
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 col-md-2"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-md-none font-weight-bold"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                Description:\n                                            "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              entry.brand_name
                                                            ) +
                                                            "\n                                        "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("hr")
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e()
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                : _vm._e()
            ]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }