<template>
    <div
        v-if="totalOnline !== null && successfulCheckout !== true"
        class="row"
    >
        <div class="col-12">
            <form
                id="check-form"
                method="post"
                novalidate="novalidate"
                @submit.prevent="handleFormSubmit"
            >
                <div class="row">
                    <div class="col-12">
                        <h2 v-if="paymentMethod == 'Credit Card' || paymentMethod === null">
                            TOTAL: ${{ totalOnline }} - {{ entriesCount }} Entries
                        </h2>
                        <h2 v-if="paymentMethod == 'Check' || paymentMethod === null">
                            TOTAL: ${{ totalMail }} - {{ entriesCount }} Entries
                        </h2>
                    </div>
                </div>
                <hr />
                <h3>{{ translations.bill_information }}</h3>

                <div class="form-row">
                    <div class="col-12 col-md-6">
                        <!-- Email -->
                        <div class="form-group">
                            <label for="bill-email">{{ translations.bill_email }}</label>
                            <input
                                id="bill-email"
                                v-model="localUser.email"
                                type="type"
                                name="x_email"
                                class="form-control"
                                :class="{ 'is-invalid': ((errors.x_email != null || $v.localUser.email.$invalid) && attemptSubmit), 'is-valid': !$v.localUser.email.$invalid }"
                            />
                            <div
                                v-if="(errors.x_email)"
                                class="invalid-feedback"
                            >
                                {{ errors.x_email[0] }}
                            </div>
                            <div
                                v-if="!$v.localUser.email.required && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field is required
                            </div>
                            <div
                                v-if="!$v.localUser.email.email && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field must be an email address.
                            </div>
                            <div
                                v-if="!$v.localUser.email.maxLength && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field must be maximum 60 characters.
                            </div>
                        </div>
                    </div>
                    <!-- phone -->
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label for="bill-phone">{{ translations.bill_phone }}</label>
                            <input
                                id="bill-phone"
                                v-model="localUser.phone"
                                type="type"
                                name="x_phone"
                                class="form-control"
                                :class="{ 'is-invalid': ((errors.x_phone != null || $v.localUser.phone.$invalid) && attemptSubmit), 'is-valid': !$v.localUser.phone.$invalid }"
                            />
                            <div
                                v-if="(errors.x_phone)"
                                class="invalid-feedback"
                            >
                                {{ errors.x_phone[0] }}
                            </div>
                            <div
                                v-if="!$v.localUser.phone.required && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field is required
                            </div>
                            <div
                                v-if="!$v.localUser.phone.maxLength && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field must be maximum 20 characters.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-12">
                        <!-- Company -->
                        <div class="form-group">
                            <label for="bill-company">{{ translations.bill_company }}</label>
                            <input
                                id="bill-company"
                                v-model="localUser.company"
                                type="text"
                                name="x_company"
                                class="form-control"
                                :class="{ 'is-invalid': ((errors.x_company != null || $v.localUser.company.$invalid) && attemptSubmit), 'is-valid': !$v.localUser.company.$invalid }"
                            />
                            <div
                                v-if="(errors.x_company)"
                                class="invalid-feedback"
                            >
                                {{ errors.x_company[0] }}
                            </div>
                            <div
                                v-if="!$v.localUser.company.maxLength && attemptSubmit"
                                class="invalid-feedback"
                            >
                                The maximum length is 50 characters.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-12 col-md-6">
                        <!-- First Name -->
                        <div class="form-group">
                            <label for="bill-first-name">{{ translations.bill_first_name }}</label>
                            <input
                                id="bill-first-name"
                                v-model="localUser.first_name"
                                type="text"
                                name="x_first_name"
                                class="form-control"
                                :class="{ 'is-invalid': ((errors.x_first_name != null || $v.localUser.first_name.$invalid) && attemptSubmit), 'is-valid': !$v.localUser.first_name.$invalid }"
                            />
                            <div
                                v-if="(errors.x_first_name)"
                                class="invalid-feedback"
                            >
                                {{ errors.x_first_name[0] }}
                            </div>
                            <div
                                v-if="!$v.localUser.first_name.required && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field is required
                            </div>
                            <div
                                v-if="!$v.localUser.first_name.maxLength && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field must be maximum 50 characters.
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <!-- Last Name -->
                        <div class="form-group">
                            <label for="bill-last-name">{{ translations.bill_last_name }}</label>
                            <input
                                id="bill-last-name"
                                v-model="localUser.last_name"
                                type="text"
                                name="x_last_name"
                                class="form-control"
                                :class="{ 'is-invalid': ((errors.x_last_name != null || $v.localUser.last_name.$invalid) && attemptSubmit), 'is-valid': !$v.localUser.last_name.$invalid }"
                            />
                            <div
                                v-if="(errors.x_last_name)"
                                class="invalid-feedback"
                            >
                                {{ errors.x_last_name[0] }}
                            </div>
                            <div
                                v-if="!$v.localUser.last_name.required && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field is required
                            </div>
                            <div
                                v-if="!$v.localUser.last_name.maxLength && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field must be maximum 50 characters.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <!-- country -->
                        <div class="form-group">
                            <label for="bill-address">{{ translations.bill_country }}</label>
                            <select
                                id="bill-country"
                                v-model="localUser.country"
                                type="text"
                                name="x_country"
                                class="form-control"
                                :class="{ 'is-invalid': ((errors.x_country != null || $v.localUser.country.$invalid) && attemptSubmit), 'is-valid': !$v.localUser.country.$invalid }"
                                @change="handleCountryState"
                            >
                                <option value="">
                                    -- Country --
                                </option>
                                <option
                                    v-for="country in sortedCountries"
                                    :key="country"
                                    :value="country"
                                >
                                    {{ countries[country] }}
                                </option>
                            </select>
                            <div
                                v-if="(errors.x_country)"
                                class="invalid-feedback"
                            >
                                {{ errors.x_country[0] }}
                            </div>
                            <div
                                v-if="!$v.localUser.country.required && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field is required
                            </div>
                            <div
                                v-if="(!$v.localUser.country.maxLength || !$v.localUser.country.minLength) && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field must be maximum 2 characters.
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="(selectedCountry !== '' && selectedCountry !== null)"
                    class="form-row"
                >
                    <div class="col-12">
                        <!-- address -->
                        <div class="form-group">
                            <label for="bill-address">{{ translations.bill_address }}</label>
                            <input
                                id="bill-address"
                                v-model="localUser.address"
                                type="text"
                                name="x_address"
                                class="form-control"
                                :class="{ 'is-invalid': ((errors.x_address != null || $v.localUser.address.$invalid) && attemptSubmit), 'is-valid': !$v.localUser.address.$invalid }"
                            />
                            <div
                                v-if="(errors.x_address)"
                                class="invalid-feedback"
                            >
                                {{ errors.x_address[0] }}
                            </div>
                            <div
                                v-if="!$v.localUser.address.required && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field is required
                            </div>
                            <div
                                v-if="!$v.localUser.address.maxLength && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field must be maximum 60 characters.
                            </div>
                        </div>
                    </div>
                </div>

                <!-- city/state/zip -->
                <div
                    v-if="(selectedCountry !== '' && selectedCountry !== null)"
                    class="form-row"
                >
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label for="bill-city">{{ translations.bill_city }}</label>
                            <input
                                id="bill-city"
                                v-model="localUser.city"
                                type="text"
                                name="x_city"
                                class="form-control"
                                :class="{ 'is-invalid': ((errors.x_city != null || $v.localUser.city.$invalid) && attemptSubmit), 'is-valid': !$v.localUser.city.$invalid }"
                            />
                            <div
                                v-if="(errors.x_city)"
                                class="invalid-feedback"
                            >
                                {{ errors.x_city[0] }}
                            </div>
                            <div
                                v-if="!$v.localUser.city.required && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field is required
                            </div>
                            <div
                                v-if="!$v.localUser.city.maxLength && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field must be maximum 35 characters.
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="form-group">
                            <label for="bill-state">{{ translations.bill_state }}</label>
                            <input
                                v-if="(selectedCountry !== 'US' && selectedCountry !== 'CA')"
                                id="bill-state"
                                v-model="localUser.state"
                                type="text"
                                name="x_state"
                                class="form-control"
                                :class="{ 'is-invalid': ((errors.x_state != null || $v.localUser.state.$invalid) && attemptSubmit), 'is-valid': !$v.localUser.state.$invalid }"
                            />
                            <select
                                v-if="(selectedCountry === 'US')"
                                id="bill-state"
                                v-model="localUser.state"
                                name="x_state"
                                class="form-control"
                                :class="{ 'is-invalid': ((errors.x_state != null || $v.localUser.state.$invalid) && attemptSubmit), 'is-valid': !$v.localUser.state.$invalid }"
                            >
                                <option value="">
                                    -- State --
                                </option>
                                <option
                                    v-for="(name, abbreviation) in unitedStates"
                                    :key="abbreviation"
                                    :value="abbreviation"
                                >
                                    {{ name }}
                                </option>
                            </select>
                            <select
                                v-if="(selectedCountry === 'CA')"
                                id="bill-state"
                                v-model="localUser.state"
                                name="x_state"
                                class="form-control"
                                :class="{ 'is-invalid': ((errors.x_state != null || $v.localUser.state.$invalid) && attemptSubmit), 'is-valid': !$v.localUser.state.$invalid }"
                            >
                                <option value="">
                                    -- Province --
                                </option>
                                <option
                                    v-for="(name, abbreviation) in canadianProvinces"
                                    :key="abbreviation"
                                    :value="abbreviation"
                                >
                                    {{ name }}
                                </option>
                            </select>

                            <div
                                v-if="(errors.x_state)"
                                class="invalid-feedback"
                            >
                                {{ errors.x_state[0] }}
                            </div>
                            <div
                                v-if="!$v.localUser.state.required && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field is required
                            </div>
                            <div
                                v-if="!$v.localUser.state.maxLength && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field must be maximum 25 characters.
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="form-group">
                            <label for="bill-zip">{{ translations.bill_zip }}</label>
                            <input
                                id="bill-zip"
                                v-model="localUser.zip"
                                type="text"
                                name="x_zip"
                                class="form-control"
                                :class="{ 'is-invalid': ((errors.x_zip != null || $v.localUser.zip.$invalid) && attemptSubmit), 'is-valid': !$v.localUser.zip.$invalid }"
                            />
                            <div
                                v-if="(errors.x_zip)"
                                class="invalid-feedback"
                            >
                                {{ errors.x_zip[0] }}
                            </div>
                            <div
                                v-if="!$v.localUser.zip.required && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field is required
                            </div>
                            <div
                                v-if="!$v.localUser.zip.maxLength && attemptSubmit"
                                class="invalid-feedback"
                            >
                                This field must be maximum 12 characters.
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
                <h3>{{ translations.bill_method }}</h3>
                <!-- payment method -->
                <div class="form-group row">
                    <div class="col-12">
                        <!-- if mail payments are enabled for the contests in the cart -->
                        <button-input v-show="usePriceMail === 1"
                            :options="[{ label: 'Credit Card ($' + totalOnline + ')', value: 'Credit Card'}, { label: 'Mail/Check ($' + totalMail + ')', value: 'Check' }]"
                            input-name="payment_method"
                            :active="paymentMethod"
                            :input-label="translations.bill_method"
                            input-id="payment-method"
                            @clicked="setupFormByPaymentMethod"
                        />
                        <!-- only credit cards are enabled for the contests in the cart -->
                        <button-input v-show="usePriceMail !== 1"
                            :options="[{ label: 'Credit Card ($' + totalOnline + ')', value: 'Credit Card'}]"
                            input-name="payment_method"
                            :active="paymentMethod"
                            :input-label="translations.bill_method"
                            input-id="payment-method"
                            @clicked="setupFormByPaymentMethod"
                        />
                        <div
                            v-if="(errors.payment_method)"
                            class="invalid-feedback d-block"
                        >
                            {{ errors.payment_method[0] }}
                        </div>
                    </div>
                </div>
                <hr />
                <div v-show="paymentMethod == 'Credit Card'">
                    <h3>{{ translations.bill_card_information }}</h3>
                    <!-- card/exp/ccv -->
                    <div class="form-row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label for="bill-card-number">{{ translations.bill_card_number }}</label>
                                <input
                                    id="bill-card-number"
                                    v-model="cardInformation.x_card_num"
                                    type="text"
                                    name="x_card_num"
                                    class="form-control"
                                    :class="{ 'is-invalid': ($v.cardInformation.x_card_num.$invalid && attemptSubmit), 'is-valid': !$v.cardInformation.x_card_num.$invalid }"
                                />
                                <div
                                    v-if="!$v.cardInformation.x_card_num.required && attemptSubmit"
                                    class="invalid-feedback"
                                >
                                    This field is required.
                                </div>
                                <div
                                    v-if="!$v.cardInformation.x_card_num.minLength && attemptSubmit"
                                    class="invalid-feedback"
                                >
                                    The credit card must be between 13-16 characters.
                                </div>
                                <div
                                    v-if="!$v.cardInformation.x_card_num.maxLength && attemptSubmit"
                                    class="invalid-feedback"
                                >
                                    The credit card must be between 13-16 characters.
                                </div>
                                <div
                                    v-if="!$v.cardInformation.x_card_num.numeric && attemptSubmit"
                                    class="invalid-feedback"
                                >
                                    The credit card must contain only numbers.
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <div class="form-group">
                                <label for="bill-exp">{{ translations.bill_exp_date }}</label>
                                <input
                                    id="bill-exp"
                                    v-model="cardInformation.x_exp_date"
                                    placeholder="MM/YY"
                                    type="text"
                                    name="x_exp_date"
                                    class="form-control"
                                    :class="{ 'is-invalid': ($v.cardInformation.x_exp_date.$invalid && attemptSubmit), 'is-valid': !$v.cardInformation.x_exp_date.$invalid }"
                                />
                                <div
                                    v-if="!$v.cardInformation.x_exp_date.required && attemptSubmit"
                                    class="invalid-feedback"
                                >
                                    This field is required.
                                </div>
                                <div
                                    v-if="!$v.cardInformation.x_exp_date.expirationDate && attemptSubmit"
                                    class="invalid-feedback"
                                >
                                    This field must match MM/YY.
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <div class="form-group">
                                <label for="bill-ccs">{{ translations.bill_ccv }}</label>
                                <input
                                    id="bill-ccv"
                                    v-model="cardInformation.x_card_code"
                                    type="text"
                                    name="x_card_code"
                                    class="form-control"
                                    :class="{ 'is-invalid': ($v.cardInformation.x_card_code.$invalid && attemptSubmit), 'is-valid': !$v.cardInformation.x_card_code.$invalid }"
                                />
                                <div
                                    v-if="!$v.cardInformation.x_card_code.required && attemptSubmit"
                                    class="invalid-feedback"
                                >
                                    This field is required.
                                </div>
                                <div
                                    v-if="!$v.cardInformation.x_card_code.minLength && attemptSubmit"
                                    class="invalid-feedback"
                                >
                                    This field must between 3 and 4 digits.
                                </div>
                                <div
                                    v-if="!$v.cardInformation.x_card_code.maxLength && attemptSubmit"
                                    class="invalid-feedback"
                                >
                                    This field must between 3 and 4 digits.
                                </div>
                                <div
                                    v-if="!$v.cardInformation.x_card_code.numeric && attemptSubmit"
                                    class="invalid-feedback"
                                >
                                    The card code must contain only numbers.
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <div
                    v-if="(Object.keys(errors).length || $v.$anyError)"
                    class="row mt-3"
                >
                    <div class="col-12">
                        <div class="alert alert-warning rounded-0">
                            Please check your form above for missing information.
                        </div>
                    </div>
                </div>

                <div
                    v-if="(authorizeMessage !== null)"
                    class="row mt-3"
                >
                    <div class="col-12">
                        <div class="alert alert-danger rounded-0">
                            {{ authorizeMessage }}
                        </div>
                    </div>
                </div>

                <div class="form-group row mt-4">
                    <div class="col">
                        <div class="btn-group btn-group-toggle d-block">
                            <button
                                type="submit"
                                class="btn btn-primary"
                                :class="(disabledOnlineEntry) ? 'd-none' : 'd-inline'"
                                style="width: 75%;"
                                :disabled="disabledOnlineEntry"
                            >
                                <i class="fas fa-shopping-cart"></i>&nbsp;Complete Your Order
                            </button><button
                                type="submit"
                                :class="(disabledOnlineEntry) ? 'd-block' : 'd-none'"
                                class="btn btn-light border border-primary rounded"
                                style="width: 100%;"
                                :disabled="disabledOnlineEntry"
                            >
                                <i class="fas fa-spinner fa-spin"></i>&nbsp;Working...
                            </button><button
                                type="button"
                                onclick="window.location.href = '/user/checkout';"
                                class="btn btn-dark"
                                :class="(disabledOnlineEntry) ? 'd-none' : 'd-inline'"
                                style="width: 25%;"
                                :disabled="disabledOnlineEntry"
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- SUCCESSFUL TRANSACTION -->
    <div
        v-else-if="totalOnline !== null && successfulCheckout == true"
        class="row"
    >
        <div class="col-12">
            <div class="alert alert-success rounded-0 mb-3">
                <strong>Success</strong>&nbsp;&nbsp;Your order was placed successfully.&nbsp;&nbsp;<a
                    class="btn btn-success"
                    :href="`/user/order/${order.invoice_number}`"
                >To view your receipt or invoice click here.</a>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import ButtonInput from './ButtonInput'
import Checkout from '../../services/api/Checkout'
import { required, requiredIf, email, maxLength, minLength, numeric } from 'vuelidate/lib/validators'
import GeographyHelper from '../Helpers/GeographyHelper'

const expirationDate = (value, vm) => {
  if (vm.payment_method !== 'Credit Card') {
    return true
  }
  var test = new RegExp('^(0[123456789]|10|11|12)([/])([0-9][0-9])$')
  return test.test(value)
}
const validPaymentType = (value) => {
  if (value !== 'Credit Card' && value !== 'Check') {
    return false
  }
  return true
}

export default {
  components: {
    'button-input': ButtonInput
  },
  props: {
    countries: {
      type: Object,
      default () {
        return {}
      }
    },
    translations: {
      type: Object,
      default () {
        return {}
      }
    },
    user: {
      type: Object,
      default () {
        return {
          id: null,
          email: null,
          phone: null,
          company: null,
          first_name: null,
          last_name: null,
          address: null,
          city: null,
          state: null,
          country: null
        }
      }
    },
    authorize: {
      type: Object,
      default () {
        return {
          login: null,
          key: null,
          url: null
        }
      }
    },
    invoiceNumber: {
      type: String,
      default: null
    },
    usePriceMail: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      canadianProvinces: GeographyHelper.canadianProvinces,
      unitedStates: GeographyHelper.unitedStates,
      attemptSubmit: false,
      cardInformation: {
        payment_method: 'Credit Card',
        x_card_num: null,
        x_exp_date: null,
        x_card_code: null
      },
      localUser: {},
      authorizeMessage: null,
      order: {},
      successfulCheckout: false,
      paymentMethod: 'Credit Card',
      disabledOnlineEntry: false,
      cart: { entries: [] },
      errors: {},
      selectedCountry: null
    }
  },
  computed: {
    sortedCountries () {
      return Object.keys(this.countries).sort((a, b) => { return this.countries[a] - this.countries[b] })
    },
    totalMail () {
      let totalMail = 0
      if (this.cart.length) {
        this.cart.forEach(function (entry) {
          totalMail += entry.contest.computed_mail_price
        })
        return totalMail.toFixed(2)
      } else {
        return null
      }
    },
    entriesCount () {
      if (!this.cart.length) {
        return 0
      }
      return this.cart.length
    },
    totalOnline () {
      let totalOnline = 0

      if (this.cart.length) {
        this.cart.forEach(function (entry) {
          totalOnline += entry.contest.computed_online_price
        })
        return totalOnline.toFixed(2)
      } else {
        return null
      }
    }
  },
  created () {
    const vm = this
    axios.interceptors.response.use(
      (response) => response,
      function (error) {
        if (error.response && error.response.data.errors) {
          vm.errors = JSON.parse(error.response.data.errors)
        }
        Checkout.handleError(error)
      }
    )
  },
  async mounted () {
    this.localUser = this.user
    this.selectedCountry = this.localUser.country
    if (process.env.NODE_ENV === 'development') {
      this.cardInformation.x_card_num = 5424000000000015
      this.cardInformation.x_exp_date = '12/21'
      this.cardInformation.x_card_code = 999
    }
    Checkout.getCart().then((response) => {
      if (response && response.data) {
        this.cart = response.data
      }
    })
  },
  validations: {
    localUser: {
      email: { required, email, maxLength: maxLength(60) },
      phone: { required, maxLength: maxLength(20) },
      company: { maxLength: maxLength(50) },
      first_name: { required, maxLength: maxLength(50) },
      last_name: { required, maxLength: maxLength(50) },
      address: { required, maxLength: maxLength(60) },
      city: { required, maxLength: maxLength(35) },
      state: { required, maxLength: maxLength(25) },
      zip: { required, maxLength: maxLength(12) },
      country: { required, maxLength: maxLength(2), minLength: minLength(2) }
    },
    cardInformation: {
      payment_method: {
        required,
        validPaymentType: validPaymentType
      },
      x_card_num: {
        required: requiredIf(
          function () {
            return this.paymentMethod === 'Credit Card'
          }
        ),
        minLength: minLength(13),
        maxLength: maxLength(16),
        numeric
      },
      x_exp_date: {
        required: requiredIf(
          function () {
            return this.paymentMethod === 'Credit Card'
          }
        ),
        minLength: minLength(5),
        expirationDate: expirationDate
      },
      x_card_code: {
        required: requiredIf(
          function () {
            return this.paymentMethod === 'Credit Card'
          }
        ),
        minLength: minLength(3),
        numeric,
        maxLength: maxLength(4)
      }
    }
  },
  methods: {
    handleCountryState (event) {
      this.selectedCountry = event.target.value
    },
    makeExpirationDate (expiration) {
      const expirationParts = expiration.split('/')
      return '20' + expirationParts[1] + '-' + expirationParts[0]
    },
    getPreAuthorizeData () {
      const entries = []
      this.cart.forEach(function (entry) {
        entries.push(entry.id)
      })
      return {
        invoiceNumber: this.invoiceNumber,
        entryIds: entries,
        usePriceMail: this.usePriceMail
      }
    },
    getAuthorizeData () {
      return {
        createTransactionRequest: {
          merchantAuthentication: {
            name: this.authorize.login,
            transactionKey: this.authorize.key
          },
          refId: this.invoiceNumber,
          transactionRequest: {
            transactionType: 'authCaptureTransaction',
            amount: this.totalOnline.replace(/,/g, ''),
            payment: {
              creditCard: {
                cardNumber: this.cardInformation.x_card_num,
                expirationDate: this.makeExpirationDate(this.cardInformation.x_exp_date),
                cardCode: this.cardInformation.x_card_code
              }
            },
            order: {
              invoiceNumber: this.invoiceNumber,
              description: 'Online Entry: ' + this.cart.length + ' Entries'
            },
            lineItems: [{
              lineItem: {
                itemId: this.localUser.id,
                name: this.localUser.first_name + ' ' + this.localUser.last_name,
                description: 'User ID: ' + this.localUser.id,
                quantity: this.cart.length,
                unitPrice: this.cart[0].contest.computed_online_price.toFixed(2)
              }
            }],
            poNumber: this.invoiceNumber,
            customer: {
              type: 'business',
              id: this.user.id,
              email: this.localUser.email
            },
            billTo: {
              firstName: this.localUser.first_name,
              lastName: this.localUser.last_name,
              company: this.localUser.company,
              address: this.localUser.address,
              city: this.localUser.city,
              state: this.localUser.state,
              zip: this.localUser.zip,
              country: this.localUser.country
            }
          }
        }
      }
    },
    getPostCheckData () {
      // always executed
      return {
        formData: {
          x_email: this.localUser.email,
          x_phone: this.localUser.phone,
          x_company: this.localUser.company,
          x_first_name: this.localUser.first_name,
          x_last_name: this.localUser.last_name,
          x_address: this.localUser.address,
          x_city: this.localUser.city,
          x_state: this.localUser.state,
          x_zip: this.localUser.zip,
          x_country: this.localUser.country,
          payment_method: this.paymentMethod
        },
        entries: this.cart
      }
    },
    handleFormSubmit (event) {
      this.attemptSubmit = true
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        return
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
      }

      this.errors = {}
      const vm = this
      this.setDisabled()
      vm.authorizeMessage = null
      // send a POST request
      Checkout.getCart()
        .then((data) => {
          // Cart contents have changed
          if (vm.cart.length !== data.data.length) {
            document.getElementById('check-form').innerHTML = '<div class="alert alert-danger">Your cart changed since you began your checkout. Please return to the cart and try again.</div>'
            return
          }

          if (vm.paymentMethod === 'Check') {
            // Post data to the checkout
            Checkout.postCartCheck(vm.getPostCheckData()).then(data => {
              if (data) {
                if (data.successful_checkout === true) {
                  vm.successfulCheckout = true
                  vm.order = data.order
                }
              }
            })
          } else {
            // Post to Authorize.net
            data = vm.getAuthorizeData()

            Checkout.postCartInvoiceNumberBeforeAuthorizeNet(vm.getPreAuthorizeData())

            Checkout.postAuthorizeNet(vm.authorize.url, data).then(response => {
              // Handle errors
              if (response.messages.resultCode === 'Error') {
                vm.authorizeMessage = response.messages.message[0].text

                if (response.transactionResponse && response.transactionResponse.errors) {
                  vm.authorizeMessage += ' ' + response.transactionResponse.errors[0].errorText
                }
                return
              }
              if (response.transactionResponse && response.transactionResponse.errors) {
                vm.authorizeMessage = response.transactionResponse.errors[0].errorText
                return
              }

              // Post back to the server with Authorize.net data.
              Checkout.postCartAndAuthorizeNet({
                requestData: data,
                authorizeData: response,
                otherData: { phone: vm.localUser.phone, email: vm.localUser.email, paymentMethod: vm.paymentMethod }
              }).then(data => {
                if (data) {
                  if (data.successful_checkout === true) {
                    vm.successfulCheckout = true
                    vm.order = data.order
                    /** setTimeout(function () {
                      window.location.replace('/user/order/' + data.order.invoice_number)
                    }, 4000)**/
                  }
                }
              })
            })
          }
        })
        .finally(function () {
          setTimeout(function () {
            vm.disabledOnlineEntry = false
          }, 3000)
        })
    },
    setDisabled () {
      this.disabledOnlineEntry = true
    },
    setupFormByPaymentMethod (value) {
      if (value.value === 'Check' || value.value === 'Credit Card') {
        if (value.value === 'Check') {
          this.cardInformation.x_card_num = null
          this.cardInformation.x_exp_date = null
          this.cardInformation.x_card_code = null
        }

        this.cardInformation.payment_method = value.value
        this.paymentMethod = value.value
      }
    }
  }
}
</script>
