var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("hr"),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.activeEntry.id,
            expression: "!activeEntry.id"
          }
        ]
      },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-dark d-block w-100 mb-3",
            attrs: {
              type: "button",
              "data-toggle": "collapse",
              "data-target": "#collapseSettings",
              "aria-expanded": "false",
              "aria-controls": "collapseSettings"
            }
          },
          [
            _c("i", { staticClass: "fas fa-cog" }),
            _vm._v(
              " " +
                _vm._s(
                  _vm.activeJudge ? "Judge: " + _vm.activeJudge : "Settings >"
                ) +
                " (" +
                _vm._s(_vm.languages[_vm.language]) +
                ")\n        "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "collapse mt-3", attrs: { id: "collapseSettings" } },
          [
            _c("div", [
              _c(
                "h5",
                [
                  _vm._v(
                    "\n                    Languages:\n                    "
                  ),
                  _vm._l(_vm.languages, function(languageString, key) {
                    return _c(
                      "button",
                      {
                        key: key,
                        staticClass: "btn btn-sm btn-light ml-2 my-1",
                        class: { "btn-outline-dark": key === _vm.language },
                        attrs: { type: "button", value: key },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.setLanguage($event)
                          }
                        }
                      },
                      [
                        key === _vm.language
                          ? _c("i", { staticClass: "fas fa-check mr-1" })
                          : _vm._e(),
                        _vm._v(
                          "\n                        " +
                            _vm._s(languageString) +
                            "\n                    "
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _vm.activeJudge
              ? _c("div", [
                  _c("hr"),
                  _vm._v(" "),
                  _c("h5", [
                    _vm._v(
                      "\n                    You are: " +
                        _vm._s(_vm.activeJudge)
                    ),
                    _c("span", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary btn-sm ml-2",
                          attrs: { type: "button" },
                          on: { click: _vm.unsetActiveJudge }
                        },
                        [_vm._v("Change")]
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("hr")
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "h4",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.contestClass.name,
            expression: "contestClass.name"
          }
        ]
      },
      [
        _vm._v(
          "\n        " +
            _vm._s(_vm.contestClass.name + " (" + _vm.contestClass.number + ")")
        ),
        _c("span", [
          _vm._v("  "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.activeEntry.id,
                  expression: "!activeEntry.id"
                }
              ],
              staticClass: "btn btn-secondary btn-sm",
              attrs: { href: "/judge/scoring/" + _vm.contestClass.contest_id }
            },
            [_vm._v("Change Class")]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    !_vm.activeJudge
      ? _c(
          "div",
          _vm._l(_vm.judges, function(judge) {
            return _c(
              "button",
              {
                key: judge,
                staticClass: "btn btn-primary d-block my-1",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.activateJudge(judge)
                  }
                }
              },
              [_vm._v("\n            " + _vm._s(judge) + "\n        ")]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.gettingClass
      ? _c("div", { staticClass: "text-center h5 mb-2" }, [
          _c("i", { staticClass: "fas fa-spinner fa-spin" }),
          _vm._v(" Working...\n    ")
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.activeEntry.id && _vm.activeJudge
      ? _c(
          "div",
          [
            _vm.gettingClass === false
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-dark d-block text-center w-100",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.getClassEntries($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-sync-alt" }),
                    _vm._v("  Refresh Class\n        ")
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.classHasJudgingComplete
              ? _c(
                  "div",
                  { staticClass: "alert alert-danger text-center mt-3" },
                  [
                    _c("i", { staticClass: "fas fa-exclamation-circle" }),
                    _vm._v(
                      " This contest class has its results published.\n              "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-danger btn-sm text-white",
                        attrs: { href: "/judge/scoring" }
                      },
                      [_vm._v("Select Another Class")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.entries.length === 0
              ? _c(
                  "div",
                  { staticClass: "alert alert-info text-center mt-3" },
                  [
                    _vm._v(
                      "\n            There are not any entries in this class.\n        "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.entries, function(entry) {
              return _c("div", { key: entry.id }, [
                _c("div", { staticClass: "row border-bottom  py-3" }, [
                  _c("div", { staticClass: "col-12 col-md-1" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        class: {
                          "disabled btn-secondary":
                            entry.scoresheets.length >= 2 ||
                            entry.no_arrival == 1
                        },
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.getEntry(entry.id)
                          }
                        }
                      },
                      [
                        entry.class_number < 10
                          ? _c("span", [_vm._v("0")])
                          : _vm._e(),
                        _vm._v(
                          _vm._s(entry.class_number) + "\n                    "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-md-2 pt-3 pt-md-1" }, [
                    entry.no_arrival === 1
                      ? _c(
                          "span",
                          {
                            staticClass: "bg-info rounded p-1 text-white mt-1"
                          },
                          [_vm._v("No Arrival")]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-md-5 mt-2 mt-md-0" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(entry.description) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-4" },
                    _vm._l(entry.scoresheets, function(entryScoresheet, index) {
                      return _c("div", { key: entryScoresheet.id }, [
                        _c("strong", [
                          _vm._v("Judge " + _vm._s(index + 1) + ":")
                        ]),
                        _vm._v(" \n                        "),
                        entryScoresheet.comp_score
                          ? _c("span", { staticClass: "p-1 bg-info rounded" }, [
                              _vm._v("Comp. Score")
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                        " +
                            _vm._s(entryScoresheet.judges_name) +
                            "\n                    "
                        )
                      ])
                    }),
                    0
                  )
                ])
              ])
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showNoArrivalError === true
      ? _c("div", { staticClass: "alert alert-info text-center" }, [
          _vm._v(
            "\n        We show this entry has not arrived.\n        Entry number: " +
              _vm._s(_vm.activeEntry.class_number) +
              "  "
          ),
          _c("span", [
            _c(
              "button",
              {
                staticClass: "btn btn-info btn-sm",
                attrs: { type: "button" },
                on: { click: _vm.reloadClass }
              },
              [_vm._v("Select Another Entry")]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.activeEntry.id &&
    _vm.activeJudge &&
    _vm.showNoArrivalError === false &&
    !_vm.gettingClass
      ? _c("div", [
          _c("h4", [
            _vm._v("\n            Entry Number: "),
            _vm.activeEntry.class_number < 10
              ? _c("span", [_vm._v("0")])
              : _vm._e(),
            _vm._v(_vm._s(_vm.activeEntry.class_number) + " "),
            _c("span", [
              _c(
                "button",
                {
                  staticClass: "btn btn-info btn-sm",
                  attrs: { type: "button" },
                  on: { click: _vm.reloadClass }
                },
                [_vm._v("Change Entry")]
              )
            ])
          ]),
          _c("hr"),
          _vm._v(" "),
          _vm.classHasJudgingComplete
            ? _c(
                "div",
                { staticClass: "alert alert-danger text-center mt-3" },
                [
                  _c("i", { staticClass: "fas fa-exclamation-circle" }),
                  _vm._v(
                    " This contest class has its results published.\n              "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-danger btn-sm text-white",
                      attrs: { href: "/judge/scoring" }
                    },
                    [_vm._v("Select Another Class")]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.judgeAlreadyScored
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("\n            You already scored this entry. "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger btn-sm",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.reloadClass($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                Select Another Entry\n            "
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.activeEntry.scoresheets &&
          _vm.activeEntry.scoresheets.length >= 2 &&
          _vm.judgeAlreadyScored === false
            ? _c("div", { staticClass: "alert alert-danger text-center" }, [
                _vm._v("\n            This entry has been scored twice. "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger btn-sm",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.reloadClass($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                Select Another Entry\n            "
                    )
                  ]
                )
              ])
            : _c("div", [
                _vm.successfulScoresheetSave
                  ? _c("div", { staticClass: "alert alert-success my-3" }, [
                      _vm._v(
                        "\n                Your scoresheet was saved successfully. "
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info btn-sm",
                          attrs: { type: "button" },
                          on: { click: _vm.reloadClass }
                        },
                        [
                          _vm._v(
                            "\n                    Select Another Entry\n                "
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.successfulScoresheetSave
                  ? _c("div", [
                      _c(
                        "form",
                        {
                          attrs: { method: "post" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "h5",
                                { staticClass: "bg-dark text-white p-2" },
                                [
                                  _vm._v(
                                    "\n                                Complimentary Score?\n                                "
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm d-block mt-1 d-md-inline mt-md-0 ml-md-1",
                                      class: {
                                        "btn-danger":
                                          _vm.inputOtherFields.isCompScore,
                                        "bg-light": !_vm.inputOtherFields
                                          .isCompScore
                                      },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.handleCompScore($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.inputOtherFields.isCompScore,
                                            expression:
                                              "inputOtherFields.isCompScore"
                                          }
                                        ],
                                        staticClass: "fas fa-check mr-1"
                                      }),
                                      _vm._v(
                                        "\n                                    Make Complimentary\n                                "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.inputOtherFields.isCompScore
                            ? _c("div", { staticClass: "row mb-3" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "label",
                                    { attrs: { for: "comp-score-reason" } },
                                    [_vm._v("Complimentary Score Reason:")]
                                  ),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.inputOtherFields.compScoreReason,
                                        expression:
                                          "inputOtherFields.compScoreReason"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      "is-invalid": !_vm.fieldIsValid(
                                        "compScoreReason"
                                      )
                                    },
                                    attrs: {
                                      id: "comp-score-reason",
                                      type: "text",
                                      name: "compScoreReason"
                                    },
                                    domProps: {
                                      value:
                                        _vm.inputOtherFields.compScoreReason
                                    },
                                    on: {
                                      change: _vm.handleCompScoreReason,
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.inputOtherFields,
                                          "compScoreReason",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.fieldIsValid(
                                            "compScoreReason"
                                          ),
                                          expression:
                                            "!fieldIsValid('compScoreReason')"
                                        }
                                      ],
                                      staticClass: "invalid-feedback"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.errors["compScoreReason"]
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.scoresheet.defectArr, function(
                            defectGroup,
                            index
                          ) {
                            return _c("div", { key: index }, [
                              _c(
                                "h5",
                                { staticClass: "bg-dark text-white p-2" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.translateString(index)) +
                                      ": "
                                  ),
                                  _vm.scoresheet.defectDescriptArr[index]
                                    ? _c(
                                        "span",
                                        { staticClass: "text-warning" },
                                        [
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.scoresheet
                                                  .defectDescriptArr[index]
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.fieldIsValid("appearanceDefectsError") &&
                              (index === "Texture_and_Appearance_Defects" ||
                                index === "Appearance_Defects")
                                ? _c("div", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.errors["appearanceDefectsError"]
                                        ) +
                                        "\n                        "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "row mb-2" },
                                [
                                  _vm._l(defectGroup, function(defect) {
                                    return _c(
                                      "div",
                                      {
                                        key: defect,
                                        staticClass: "col-6 col-md-3 pb-2"
                                      },
                                      [
                                        _c("label", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.translateString(defect)
                                            ) + ":"
                                          )
                                        ]),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("input", {
                                          staticClass: "form-control defect",
                                          class: {
                                            "is-invalid": !_vm.fieldIsValid(
                                              index + "|" + defect
                                            )
                                          },
                                          staticStyle: { width: "7em" },
                                          attrs: {
                                            id: index + "|" + defect,
                                            type: "number",
                                            step: ".01",
                                            name: index + "[" + defect + "]"
                                          },
                                          on: {
                                            change: function($event) {
                                              $event.preventDefault()
                                              return _vm.checkInputDefect(
                                                $event
                                              )
                                            },
                                            focusout: _vm.getScore
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.fieldIsValid(
                                                  index + "|" + defect
                                                ),
                                                expression:
                                                  "!fieldIsValid(`${index}|${defect}`)"
                                              }
                                            ],
                                            staticClass: "invalid-feedback"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.errors[
                                                    index + "|" + defect
                                                  ]
                                                ) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-6 col-md-3 pb-2" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.translateString("Other")) +
                                          " 1:\n                                "
                                      ),
                                      _c("input", {
                                        staticClass: "form-control otherTitle",
                                        class: {
                                          "is-invalid": !_vm.fieldIsValid(
                                            index + "|other1Title"
                                          )
                                        },
                                        attrs: {
                                          id: index + "|other1Title",
                                          type: "text",
                                          name: index + "['other1Title']",
                                          placeholder: "Defect"
                                        },
                                        on: {
                                          change: function($event) {
                                            $event.preventDefault()
                                            return _vm.checkOtherTitle($event)
                                          },
                                          focusout: _vm.getScore
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.fieldIsValid(
                                                index + "|other1Title"
                                              ),
                                              expression:
                                                "!fieldIsValid(`${index}|other1Title`)"
                                            }
                                          ],
                                          staticClass: "invalid-feedback"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.errors[
                                                  index + "|other1Title"
                                                ]
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass: "form-control other mt-1",
                                        class: {
                                          "is-invalid": !_vm.fieldIsValid(
                                            index + "|other1"
                                          )
                                        },
                                        staticStyle: { width: "7em" },
                                        attrs: {
                                          id: index + "|other1",
                                          type: "number",
                                          step: ".01",
                                          placeholder: "0",
                                          name: index + "['other1']"
                                        },
                                        on: {
                                          change: function($event) {
                                            $event.preventDefault()
                                            return _vm.checkInputDefect($event)
                                          },
                                          focusout: _vm.getScore
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.fieldIsValid(
                                                index + "|other1"
                                              ),
                                              expression:
                                                "!fieldIsValid(`${index}|other1`)"
                                            }
                                          ],
                                          staticClass: "invalid-feedback"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.errors[index + "|other1"]
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-6 col-md-3 pb-2" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.translateString("Other")) +
                                          " 2:\n                                "
                                      ),
                                      _c("input", {
                                        staticClass: "form-control otherTitle",
                                        class: {
                                          "is-invalid": !_vm.fieldIsValid(
                                            index + "|other2Title"
                                          )
                                        },
                                        attrs: {
                                          id: index + "|other2Title",
                                          type: "text",
                                          name: index + "['other2Title']",
                                          placeholder: "Defect"
                                        },
                                        on: {
                                          change: function($event) {
                                            $event.preventDefault()
                                            return _vm.checkOtherTitle($event)
                                          },
                                          focusout: _vm.getScore
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.fieldIsValid(
                                                index + "|other2Title"
                                              ),
                                              expression:
                                                "!fieldIsValid(`${index}|other2Title`)"
                                            }
                                          ],
                                          staticClass: "invalid-feedback"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.errors[
                                                  index + "|other2Title"
                                                ]
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass: "form-control other mt-1",
                                        class: {
                                          "is-invalid": !_vm.fieldIsValid(
                                            index + "|other2"
                                          )
                                        },
                                        staticStyle: { width: "7em" },
                                        attrs: {
                                          id: index + "|other2",
                                          type: "number",
                                          step: ".01",
                                          placeholder: "0",
                                          name: index + "['other2']"
                                        },
                                        on: {
                                          change: function($event) {
                                            $event.preventDefault()
                                            return _vm.checkInputDefect($event)
                                          },
                                          focusout: _vm.getScore
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.fieldIsValid(
                                                index + "|other2"
                                              ),
                                              expression:
                                                "!fieldIsValid(`${index}|other2`)"
                                            }
                                          ],
                                          staticClass: "invalid-feedback"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.errors[index + "|other2"]
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                2
                              )
                            ])
                          }),
                          _vm._v(" "),
                          _c("h5", { staticClass: "bg-dark text-white p-2" }, [
                            _vm._v(
                              "\n                        Comments:\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.inputOtherFields.judgesComments,
                                    expression:
                                      "inputOtherFields.judgesComments"
                                  }
                                ],
                                staticClass: "form-control",
                                domProps: {
                                  value: _vm.inputOtherFields.judgesComments
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.inputOtherFields,
                                      "judgesComments",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("hr"),
                          _vm._v(" "),
                          _vm.errors.scoreCalculated
                            ? _c(
                                "div",
                                { staticClass: "alert alert-warning" },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.errors.scoreCalculated) +
                                      "\n                    "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isValid,
                                  expression: "!isValid"
                                }
                              ],
                              staticClass: "alert alert-warning"
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-exclamation-circle"
                              }),
                              _vm._v(
                                "  Please check for errors above.\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isSavingScoresheet,
                                  expression: "isSavingScoresheet"
                                }
                              ],
                              staticClass: "my-2 h5 text-center"
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-spinner fa-spin"
                              }),
                              _vm._v(" Saving...\n                    ")
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.postScoresheetErrors, function(
                            error,
                            key
                          ) {
                            return _c(
                              "div",
                              { key: key, staticClass: "alert alert-danger" },
                              [
                                _c("i", {
                                  staticClass: "fas fa-exclamation-circle"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(error[0]) +
                                    "\n                    "
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isSavingScoresheet,
                                  expression: "!isSavingScoresheet"
                                }
                              ],
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "button",
                                "data-toggle": "modal",
                                "data-target": "#reviewScoreModal"
                              }
                            },
                            [
                              _vm._v(
                                "\n                        Review Your Scoresheet\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "modal fade",
                              attrs: {
                                id: "reviewScoreModal",
                                tabindex: "-1",
                                role: "dialog",
                                "aria-labelledby": "reviewScoreModal",
                                "aria-hidden": "true"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "modal-dialog",
                                  attrs: { role: "document" }
                                },
                                [
                                  _c("div", { staticClass: "modal-content" }, [
                                    _vm._m(0),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "modal-body" },
                                      [
                                        _c("ModalReviewBody", {
                                          attrs: {
                                            "input-other-fields":
                                              _vm.inputOtherFields,
                                            "filtered-input-defects":
                                              _vm.filteredInputDefects,
                                            "translate-string":
                                              _vm.translateString,
                                            "active-judge": _vm.activeJudge,
                                            "active-entry": _vm.activeEntry,
                                            "score-calculated":
                                              _vm.scoreCalculated,
                                            "is-valid": _vm.isValid
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "modal-footer" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-secondary",
                                          attrs: {
                                            id: "close-modal-button",
                                            type: "button",
                                            "data-dismiss": "modal"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Close\n                                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.isValid
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-primary",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.postScoresheet(
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Save Your Scoresheet\n                                    "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  ])
                                ]
                              )
                            ]
                          )
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
        [
          _vm._v(
            "\n                                        Review Scoresheet\n                                    "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }