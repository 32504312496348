<template>
    <div>
        <div
            v-if="!filteredInputDefects.Flavor_Defects"
            class="alert alert-info"
        >
            <i class="fas fa-info-circle"></i>&nbsp;You did not deduct anything for flavor.
        </div>
        <div
            v-show="!isValid"
            class="alert alert-warning"
        >
            <i class="fas fa-exclamation-circle"></i>&nbsp;&nbsp;Please check the scoresheet for errors.
        </div>
        <h5 class="bg-dark p-2 text-white">
            Entry Information
        </h5>
        {{ activeEntry.class.number }}:<span v-if="activeEntry.class_number < 10">0</span>{{ `${activeEntry.class_number}` }} {{ activeEntry.class.name }}

        <h5 class="bg-dark p-2 text-white mt-3">
            Scoresheet Information
        </h5>

        <strong>Your Name: {{ activeJudge }}</strong><br />
        <strong>Is Comp Store?: {{ inputOtherFields.isCompScore ? 'Yes' : 'No' }}</strong>
        <div v-if="inputOtherFields.isCompScore">
            <strong>Complimentary Score Reason:</strong><br />
            {{ inputOtherFields.compScoreReason }}
        </div>

        <div
            v-for="(i, defectGroup) in filteredInputDefects"
            :key="defectGroup"
            class="mt-3"
        >
            <h5 class="bg-dark p-2 text-white">
                {{ translateString(defectGroup.replaceAll('_', ' ')) }}
            </h5>
            <div
                v-for="(defect, i2) in filteredInputDefects[defectGroup]"
                :key="i2"
            >
                {{ translateString(defect.name) }}: {{ defect.value }}<br />
            </div>
        </div>

        <h5 class="bg-dark p-2 text-white mt-3">
            Score
        </h5>
        <h4>{{ scoreCalculated }}</h4>
    </div>
</template>

<script>
export default {
  props: {
    scoreCalculated: {
      type: [String, Number],
      required: true
    },
    activeEntry: {
      type: Object,
      required: true
    },
    activeJudge: {
      type: String,
      required: true
    },
    translateString: {
      type: Function,
      required: true
    },
    inputOtherFields: {
      type: Object,
      required: true
    },
    filteredInputDefects: {
      type: [Array, Object],
      required: true
    },
    isValid: {
      type: Boolean,
      default: false,
      required: true
    }
  }
}
</script>
