<template>
    <div>
        <div v-if="!activeJudge">
            <h5>Select Your Language:</h5>
            <hr />
            <div>
                <a
                    v-for="(language, key) in languages"
                    :key="key"
                    class="btn btn-sm btn-secondary mt-1 mr-2"
                    :class="{ active: key === languageActive }"
                    @click="setLanguage($event, key)"
                >
                    {{ language }}
                </a>
            </div>
            <hr />
            <h5>Select Your Name:</h5>
            <hr />
            <div
                v-for="judge in judges"
                :key="judge"
            >
                <button
                    type="button"
                    class="btn btn-primary"
                    :value="judge"
                    @click="setActiveJudge"
                >
                    {{ judge }}
                </button>
                <hr />
            </div>
        </div>

        <div v-if="activeJudge">
            <h5>
                Judging as: {{ activeJudge }}
              &nbsp;
                <button
                    type="button"
                    role="button"
                    class="btn btn-sm btn-secondary mt-1 mt-md-0"
                    @click="unsetActiveJudge"
                >
                    Change
                </button>
            </h5>
            <hr />

            <!-- no active entry -->
            <div v-if="!activeEntry">
                <div
                    v-for="(entry, index) in entries"
                    :key="index"
                >
                    <div
                        v-if="checkScoredAlready(entry)"
                        class="btn"
                        style="cursor: initial;"
                    >
                        {{ entry.entry.contest_class.number }}:{{ entry.entry.class_number }}
                    </div>
                    <button
                        v-if="!checkScoredAlready(entry)"
                        type="button"
                        class="btn btn-primary"
                        :value="index"
                        @click="setActiveEntry"
                    >
                        {{ entry.entry.contest_class.number }}:{{ entry.entry.class_number }}
                    </button>
                    <hr />
                </div>
            </div>

            <!-- active entry -->
            <div v-if="activeEntry !== null && !successfulSubmit">
                <h4 class="bg-dark text-white p-2">
                    Entry: {{ activeEntry.entry.contest_class.number }}:{{ activeEntry.entry.class_number }}
                    &nbsp;
                    <button
                        class="btn btn-sm btn-secondary"
                        @click="unsetActiveEntry"
                    >
                        Change
                    </button>
                </h4>
                <hr />
                <form
                    method="post"
                    novalidate="novalidate"
                    @submit.prevent=""
                >
                    <div class="form-row">
                        <div class="col-12 col-md-6">
                            <label for="score-appearance">{{ translations[languageActive]["Appearance"] }}</label>
                            <input
                                id="score-appearance"
                                v-model="score.score_appearance"
                                type="number"
                                step=".01"
                                class="form-control"
                                :class="{ 'is-invalid': !fieldIsValid('score-appearance') }"
                                @change="checkInput"
                            >
                            <div
                                v-show="!fieldIsValid('score-appearance')"
                                class="invalid-feedback"
                            >
                                {{ errors['score-appearance'] }}
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <label for="score-flavor">{{ translations[languageActive]["Flavor"] }}</label>
                            <input
                                id="score-flavor"
                                v-model="score.score_flavor"
                                type="number"
                                step=".01"
                                class="form-control"
                                :class="{ 'is-invalid': !fieldIsValid('score-flavor') }"
                                @change="checkInput"
                            >
                            <div
                                v-show="!fieldIsValid('score-flavor')"
                                class="invalid-feedback"
                            >
                                {{ errors['score-flavor'] }}
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12">
                            <div v-if="totalScoreValid">
                                <hr />
                                <h5>Score: {{ score.total_score }}</h5>
                                <hr />
                                <div
                                    v-if="isSubmitting"
                                    class="text-center h5"
                                >
                                    <i class="fas fa-spinner fa-spin"></i>&nbsp;Saving
                                </div>
                                <div
                                    v-for="(postErrors, index) in postScoresheetErrors"
                                    :key="index"
                                    class="alert alert-danger rounded-0"
                                >
                                    <i class="fas fa-exclamation-circle"></i>&nbsp;{{ postErrors[0] }}
                                </div>
                                <button
                                    v-if="!isSubmitting"
                                    type="button"
                                    role="button"
                                    class="btn btn-primary w-100"
                                    @click.prevent="submitScore"
                                >
                                    <i class="fas fa-gavel"></i>&nbsp;Submit Score
                                </button>
                            </div>
                            <div v-if="!totalScoreValid">
                                <label for="button">&nbsp;</label>
                                <div class="alert alert-warning rounded-0">
                                    Please check for errors. Your total score is {{ score.total_score }}.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <!-- Success message -->
            <div
                v-if="successfulSubmit"
                class="alert alert-success rounded-0"
            >
                <i class="fas fa-check-circle"></i>&nbsp;Your score was saved.&nbsp;
                <button
                    class="btn btn-sm btn-success"
                    @click="unsetActiveEntry"
                >
                    Select another entry.
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import Cookie from 'js-cookie'
import axios from 'axios'
import _ from 'lodash'

export default {
  props: {
    entries: {
      type: Array,
      required: true
    },
    judges: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isSubmitting: false,
      successfulSubmit: false,
      postScoresheetErrors: {},
      activeEntry: null,
      activeJudge: null,
      errors: {},
      languageActive: 'en',
      languages: {
        en: 'English',
        fr: 'French',
        de: 'German',
        it: 'Italian',
        es: 'Spanish'
      },
      translations: {
        en: {
          Appearance: 'Appearance',
          Flavor: 'Flavor'
        },
        de: {
          Appearance: 'Aussehen',
          Flavor: 'Geschmack'
        },
        es: {
          Appearance: 'Apariencia',
          Flavor: 'Sabor'
        },
        fr: {
          Appearance: 'Apparence',
          Flavor: 'Saveur'
        },
        it: {
          Appearance: 'Aspetto',
          Flavor: 'Gusto'
        }
      },
      score: {
        score_appearance: null,
        score_flavor: null,
        total_score: 0
      }
    }
  },
  computed: {
    totalScoreValid () {
      const totalScore = this.getTotalScore()

      if (totalScore > 100 || totalScore < 0) {
        this.$set(this.errors, 'total-score-error', 'Score must be between 0 and 100.')
        return false
      }
      this.$delete(this.errors, 'total-score-error')
      return true
    }
  },
  mounted () {
    if (Cookie.get('judge_final')) {
      this.activeJudge = Cookie.get('judge_final')
    }
    if (Cookie.get('language_final')) {
      this.languageActive = Cookie.get('language_final')
    }
  },
  methods: {
    setLanguage (e, language) {
      this.languageActive = language
      Cookie.set('language_final', this.languageActive, { sameSite: 'Lax' })
    },
    checkScoredAlready (entry) {
      const exists = entry.entry.contest_final_round_entry_scores.find(vendor => vendor.judges_name === this.activeJudge)
      if (exists) {
        return true
      }
      return false
    },
    async submitScore () {
      this.checkNotEmptyScores()

      if (!_.isEmpty(this.errors)) {
        return
      }
      try {
        this.isSubmitting = true
        this.postScoresheetErrors = {}
        return axios.post(
          '/api-session/judging/final-round',
          { entry_id: this.activeEntry.entry.id, judges_name: this.activeJudge, score: this.score }
        ).then((response) => {
          if (response.data.success) {
            this.successfulSubmit = true
          }
        }).catch((error) => {
          if (error.response && error.response.status && (error.response.status === 401 || error.response.status === 403)) {
            window.location.replace(window.location.href)
            return
          }

          if (error.response && error.response.status && error.response.status === 422) {
            this.postScoresheetErrors = error.response.data.errors
            return
          }

          alert(error.message)
          return error
        }).finally(() => {
          this.isSubmitting = false
        })
      } catch (ex) {
        alert(ex)
      }
    },
    fieldIsValid (fieldId) {
      if (Object.prototype.hasOwnProperty.call(this.errors, fieldId) && this.errors[fieldId] !== false) {
        return false
      }
      return true
    },
    checkNotEmptyScores () {
      if (!this.score.score_flavor) {
        this.$set(this.errors, 'score-flavor', 'You must enter your scores.')
      }

      if (!this.score.score_appearance) {
        this.$set(this.errors, 'score-appearance', 'You must enter your scores.')
      }
    },
    checkInput (event) {
      this.getTotalScore()
      this.checkNotEmptyScores()

      const regexp = new RegExp('^\\d+(\\.\\d{1,2})?$')
      event.target.value = event.target.value.trim()
      // if a number not a string and length > 0
      if (!isNaN(event.target.value) && event.target.value.length > 0) {
        event.target.value = parseFloat(event.target.value)
      }
      // if the value is 0 then change to empty.
      // if (event.target.value === '0') {
      //  event.target.value = ''
      // }

      // delete error if length is 0
      if (event.target.value.length === 0) {
        this.$set(this.errors, event.target.id, 'Field is required.')
        return
      }

      // if it's a two digit decimal
      if (!regexp.test(event.target.value)) {
        this.$set(this.errors, event.target.id, `Only 2 digit positive decimals: ${event.target.value}`)
        return
      } else {
        this.$delete(this.errors, event.target.id)
      }

      // if it's a negative number
      if (event.target.value < 0) {
        this.$set(this.errors, event.target.id, 'Only positive numbers.')
        return
      } else {
        this.$delete(this.errors, event.target.id)
      }

      // if > 50
      if (event.target.value > 50) {
        this.$set(this.errors, event.target.id, 'Maximum 50 points.')
      } else {
        this.$delete(this.errors, event.target.id)
      }
    },
    getTotalScore () {
      let total = 0
      const appearance = parseFloat(this.score.score_appearance)
      if (!isNaN(appearance)) {
        total += appearance
      }

      const flavor = parseFloat(this.score.score_flavor)
      if (!isNaN(flavor)) {
        total += flavor
      }
      this.score.total_score = parseFloat(total.toFixed(10))
      return this.score.total_score
    },
    setActiveEntry (event) {
      this.activeEntry = this.entries[event.target.value]
    },
    unsetActiveEntry (event) {
      window.location.replace(window.location.href)
      // this.activeEntry = null
    },
    unsetActiveJudge (event) {
      Cookie.remove('judge_final')
      this.unsetActiveEntry()
    },
    setActiveJudge (event) {
      this.activeJudge = event.target.value
      Cookie.set('judge_final', this.activeJudge, { sameSite: 'Lax' })
    }
  }
}
</script>
