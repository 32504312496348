var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    JSON.stringify(_vm.errors) !== "{}"
      ? _c(
          "div",
          {
            staticClass: "alert alert-warning rounded-0",
            attrs: { role: "alert" }
          },
          [
            _c("i", { staticClass: "fas fa-exclamation-circle" }),
            _vm._v("  Please check the fields below for errors.\n    ")
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: {
          id: "entry-update",
          method: "post",
          novalidate: "novalidate",
          action: _vm.formAction
        }
      },
      [
        _c("input", {
          attrs: { name: "_method", type: "hidden" },
          domProps: { value: _vm.formMethod }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "_token" },
          domProps: { value: _vm.csrf }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label",
                  attrs: { for: "entry-class" }
                },
                [_vm._v(_vm._s(_vm.translations.class))]
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.localData.contest_class_id,
                      expression: "localData.contest_class_id"
                    }
                  ],
                  class: [
                    "form-control",
                    _vm.errors.contest_class_id &&
                    _vm.errors.contest_class_id.length > 0
                      ? "is-invalid"
                      : ""
                  ],
                  attrs: {
                    id: "entry-class",
                    name: "contest_class_id",
                    type: "text"
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.localData,
                          "contest_class_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.updateDescription
                    ]
                  }
                },
                [
                  _c("option", { attrs: { value: "", disabled: "" } }, [
                    _vm._v(
                      "\n                            -- " +
                        _vm._s(_vm.translations.class) +
                        " --\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.classes, function(contestClass) {
                    return _c(
                      "option",
                      {
                        key: contestClass.id,
                        domProps: { value: contestClass.id }
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(contestClass.name) +
                            " (" +
                            _vm._s(
                              _vm.removeTrailingZero(contestClass.number)
                            ) +
                            ")\n                        "
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.errors.contest_class_id &&
              _vm.errors.contest_class_id.length > 0
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.errors.contest_class_id[0]) +
                        "\n                    "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.classDescription
              ? _c(
                  "div",
                  { staticClass: "mb-3 border p-1 rounded text-info" },
                  [
                    _c("i", { staticClass: "fas fa-info-circle" }),
                    _vm._v(
                      " " + _vm._s(_vm.classDescription) + "\n                "
                    )
                  ]
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label",
                  attrs: { for: "brand-name" }
                },
                [_vm._v(_vm._s(_vm.translations.brand_name))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localData.brand_name,
                    expression: "localData.brand_name"
                  }
                ],
                staticClass: "form-control",
                class: [
                  "form-control",
                  _vm.errors.brand_name && _vm.errors.brand_name.length > 0
                    ? "is-invalid"
                    : ""
                ],
                attrs: { id: "brand-name", type: "text", name: "brand_name" },
                domProps: { value: _vm.localData.brand_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.localData, "brand_name", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.brand_name && _vm.errors.brand_name.length > 0
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.errors.brand_name[0]) +
                        "\n                    "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label mb-0 mb-md-2",
                  attrs: { for: "description" }
                },
                [_vm._v(_vm._s(_vm.translations.description) + ": ")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "d-block d-md-inline",
                  class: [
                    { "text-danger": _vm.computedDescriptionLength > 200 },
                    { "text-info": 200 - _vm.computedDescriptionLength >= 0 }
                  ]
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(200 - _vm.computedDescriptionLength) +
                      " Characters Left\n                    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localData.description,
                    expression: "localData.description"
                  }
                ],
                staticClass: "form-control",
                class: [
                  "form-control",
                  _vm.errors.description && _vm.errors.description.length > 0
                    ? "is-invalid"
                    : ""
                ],
                attrs: { id: "description", name: "description" },
                domProps: { value: _vm.localData.description },
                on: {
                  keypress: _vm.trimLines,
                  blur: _vm.trimString,
                  change: _vm.trimLines,
                  paste: function($event) {
                    $event.preventDefault()
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.localData, "description", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.description && _vm.errors.description.length > 0
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.errors.description[0]) +
                        "\n                    "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "form-control-label", attrs: { for: "maker" } },
                [_vm._v(_vm._s(_vm.translations.maker_team))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localData.maker,
                    expression: "localData.maker"
                  }
                ],
                staticClass: "form-control",
                class: [
                  "form-control",
                  _vm.errors.maker && _vm.errors.maker.length > 0
                    ? "is-invalid"
                    : ""
                ],
                attrs: { id: "maker", type: "text", name: "maker" },
                domProps: { value: _vm.localData.maker },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.localData, "maker", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.maker && _vm.errors.maker.length > 0
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.errors.maker[0]) +
                        "\n                    "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label",
                  attrs: { for: "company" }
                },
                [_vm._v(_vm._s(_vm.translations.company))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localData.company,
                    expression: "localData.company"
                  }
                ],
                staticClass: "form-control",
                class: [
                  "form-control",
                  _vm.errors.company && _vm.errors.company.length > 0
                    ? "is-invalid"
                    : ""
                ],
                attrs: { id: "company", type: "text", name: "company" },
                domProps: { value: _vm.localData.company },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.localData, "company", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.company && _vm.errors.company.length > 0
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.errors.company[0]) +
                        "\n                    "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col-12 col-md-3" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "form-control-label", attrs: { for: "pieces" } },
                [_vm._v(_vm._s(_vm.translations.number_of_pieces))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localData.pieces,
                    expression: "localData.pieces"
                  }
                ],
                staticClass: "form-control",
                class: [
                  "form-control",
                  _vm.errors.pieces && _vm.errors.pieces.length > 0
                    ? "is-invalid"
                    : ""
                ],
                attrs: { id: "pieces", type: "number", name: "pieces" },
                domProps: { value: _vm.localData.pieces },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.localData, "pieces", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.pieces && _vm.errors.pieces.length > 0
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.errors.pieces[0]) +
                        "\n                    "
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6 col-md-3" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "form-control-label", attrs: { for: "weight" } },
                [_vm._v(_vm._s(_vm.translations.weight))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localData.weight,
                    expression: "localData.weight"
                  }
                ],
                staticClass: "form-control",
                class: [
                  "form-control",
                  _vm.errors.weight && _vm.errors.weight.length > 0
                    ? "is-invalid"
                    : ""
                ],
                attrs: { id: "weight", type: "number", name: "weight" },
                domProps: { value: _vm.localData.weight },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.localData, "weight", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.weight && _vm.errors.weight.length > 0
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.errors.weight[0]) +
                        "\n                    "
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-6 col-md-3" },
            [
              _c("button-input", {
                attrs: {
                  options: [
                    { label: "Lbs", value: "lbs" },
                    { label: "Kgs", value: "kgs" }
                  ],
                  "input-name": "weight_unit",
                  active: _vm.request.weight_unit
                    ? _vm.request.weight_unit
                    : "lbs",
                  "input-label": _vm.translations.weight_unit,
                  "input-id": "weight-unit"
                }
              }),
              _vm._v(" "),
              _vm.errors.weight_unit && _vm.errors.weight_unit.length > 0
                ? _c("div", { staticClass: "invalid-feedback d-block" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.weight_unit[0]) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "col-6 mb-3" },
            [
              _c(
                "label",
                {
                  staticClass: "form-control-label",
                  attrs: { for: "date-manufacture" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.translations.date_manufacture) +
                      " (MM/DD/YYYY format - editable after submission)"
                  )
                ]
              ),
              _vm._v(" "),
              _c("date-picker-contest", {
                attrs: {
                  date: _vm.computedDate,
                  name: "date_manufacture",
                  "input-props": {
                    placeholder: "MM/DD/YYYY",
                    class: [
                      "form-control",
                      _vm.errors.date_manufacture &&
                      _vm.errors.date_manufacture.length > 0
                        ? "is-invalid"
                        : ""
                    ],
                    name: "date_manufacture",
                    id: "date-manufacture"
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.date_manufacture &&
              _vm.errors.date_manufacture.length > 0
                ? _c("div", { staticClass: "invalid-feedback d-block" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.date_manufacture[0]) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label",
                  attrs: { for: "lot_number" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.translations.lot_number) +
                      " (editable after submission)"
                  )
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localData.lot_number,
                    expression: "localData.lot_number"
                  }
                ],
                staticClass: "form-control",
                class: [
                  "form-control",
                  _vm.errors.lot_number && _vm.errors.lot_number.length > 0
                    ? "is-invalid"
                    : ""
                ],
                attrs: { id: "lot_number", type: "text", name: "lot_number" },
                domProps: { value: _vm.localData.lot_number },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.localData, "lot_number", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.lot_number && _vm.errors.lot_number.length > 0
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.errors.lot_number[0]) +
                        "\n                    "
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label",
                  attrs: { for: "moisture" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.translations.moisture) +
                      " (editable after submission)"
                  )
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localData.moisture,
                    expression: "localData.moisture"
                  }
                ],
                staticClass: "form-control",
                class: [
                  "form-control",
                  _vm.errors.moisture && _vm.errors.moisture.length > 0
                    ? "is-invalid"
                    : ""
                ],
                attrs: { id: "moisture", type: "text", name: "moisture" },
                domProps: { value: _vm.localData.moisture },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.localData, "moisture", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.moisture && _vm.errors.moisture.length > 0
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.errors.moisture[0]) +
                        "\n                    "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label mb-0 mb-md-2",
                  attrs: { for: "country" }
                },
                [_vm._v(_vm._s(_vm.translations.country))]
              ),
              _vm._v(" "),
              _vm.contest.category !== "wccc"
                ? _c("span", { staticClass: "d-block d-md-inline text-info" }, [
                    _vm._v(
                      "\n                        Your entry MUST be from the United States for this contest.\n                    "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.localData.country,
                      expression: "localData.country"
                    }
                  ],
                  staticClass: "form-control",
                  class: [
                    "form-control",
                    _vm.errors.country && _vm.errors.country.length > 0
                      ? "is-invalid"
                      : ""
                  ],
                  attrs: { id: "country", name: "country", type: "text" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.localData,
                          "country",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.handleCountryState
                    ]
                  }
                },
                [
                  _c("option", { attrs: { value: "", disabled: "" } }, [
                    _vm._v(
                      "\n                            -- Country --\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.computedCountries, function(value, name) {
                    return _c(
                      "option",
                      { key: name, domProps: { value: name } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(value) +
                            "\n                        "
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.errors.country && _vm.errors.country.length > 0
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.errors.country[0]) +
                        "\n                    "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.selectedCountry !== null
          ? _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "col-12 col-md-6" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-control-label",
                      attrs: { for: "city" }
                    },
                    [_vm._v(_vm._s(_vm.translations.city))]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.localData.city,
                        expression: "localData.city"
                      }
                    ],
                    staticClass: "form-control",
                    class: [
                      "form-control",
                      _vm.errors.city && _vm.errors.city.length > 0
                        ? "is-invalid"
                        : ""
                    ],
                    attrs: { id: "city", type: "text", name: "city" },
                    domProps: { value: _vm.localData.city },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.localData, "city", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.city && _vm.errors.city.length > 0
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.errors.city[0]) +
                            "\n                    "
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-md-6" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-control-label",
                      attrs: { for: "state" }
                    },
                    [_vm._v(_vm._s(_vm.translations.state))]
                  ),
                  _vm._v(" "),
                  _vm.selectedCountry !== "US" && _vm.selectedCountry !== "CA"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.localData.state,
                            expression: "localData.state"
                          }
                        ],
                        staticClass: "form-control",
                        class: [
                          "form-control",
                          _vm.errors.state && _vm.errors.state.length > 0
                            ? "is-invalid"
                            : ""
                        ],
                        attrs: { id: "state", type: "text", name: "state" },
                        domProps: { value: _vm.localData.state },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.localData,
                              "state",
                              $event.target.value
                            )
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedCountry === "US"
                    ? _c(
                        "select",
                        {
                          class: [
                            "form-control",
                            _vm.errors.state && _vm.errors.state.length > 0
                              ? "is-invalid"
                              : ""
                          ],
                          attrs: { id: "state", name: "state" }
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(
                              "\n                            -- State --\n                        "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.unitedStates, function(
                            name,
                            abbreviation
                          ) {
                            return _c(
                              "option",
                              {
                                key: abbreviation,
                                domProps: {
                                  selected: abbreviation == _vm.request.state,
                                  value: abbreviation
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(name) +
                                    "\n                        "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedCountry === "CA"
                    ? _c(
                        "select",
                        {
                          class: [
                            "form-control",
                            _vm.errors.state && _vm.errors.state.length > 0
                              ? "is-invalid"
                              : ""
                          ],
                          attrs: { id: "state", name: "state" }
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(
                              "\n                            -- Province --\n                        "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.canadianProvinces, function(
                            name,
                            abbreviation
                          ) {
                            return _c(
                              "option",
                              {
                                key: abbreviation,
                                domProps: {
                                  selected: abbreviation == _vm.request.state,
                                  value: abbreviation
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(name) +
                                    "\n                        "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errors.state && _vm.errors.state.length > 0
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.errors.state[0]) +
                            "\n                    "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label",
                  attrs: { for: "user_notes" }
                },
                [_vm._v(_vm._s(_vm.translations.user_notes))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localData.user_notes,
                    expression: "localData.user_notes"
                  }
                ],
                staticClass: "form-control",
                class: [
                  "form-control",
                  _vm.errors.user_notes && _vm.errors.user_notes.length > 0
                    ? "is-invalid"
                    : ""
                ],
                attrs: { id: "user_notes", type: "text", name: "user_notes" },
                domProps: { value: _vm.localData.user_notes },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.localData, "user_notes", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.user_notes && _vm.errors.user_notes.length > 0
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.errors.user_notes[0]) +
                        "\n                    "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "div",
            { staticClass: "col text-right" },
            [
              _c("submit-clear-block", {
                attrs: {
                  "clear-title": "Reset",
                  "search-title": _vm.searchTitle,
                  "font-awesome-icon": "fas fa-shopping-cart"
                }
              })
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }