<template>
    <div class="row">
        <div class="col-12">
            <h3>{{ draggingInfo }}</h3>
            <draggable
                :list="classes"
                class="list-group"
                ghost-class="ghost"
                @start="dragging = true"
                @end="dragging = false"
            >
                <div
                    v-for="element in classes"
                    :key="element.name"
                    class="list-group-item font-weight-bold"
                >
                    <span class="fa-stack fa-lg">
                        <i class="fa fa-square-o fa-stack-2x text-info"></i>
                        <i class="fas fa-arrows-alt-v fa-stack-1x"></i>
                    </span>&nbsp;
                    {{ element.name }}&nbsp;({{ parseFloat(element.number) }})
                    <input
                        type="hidden"
                        :name="getInputName(element.id)"
                        :value="element.id"
                    />
                </div>
            </draggable>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  order: 0,
  components: {
    draggable
  },
  props: {
    classes: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dragging: false
    }
  },
  computed: {
    draggingInfo () {
      return this.dragging ? 'dragging' : ''
    }
  },
  methods: {
    getInputName: function (id) {
      return `contest_class_id[${id}]`
    }
  }
}
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
