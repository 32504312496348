var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.draggingInfo))]),
        _vm._v(" "),
        _c(
          "draggable",
          {
            staticClass: "list-group",
            attrs: { list: _vm.classes, "ghost-class": "ghost" },
            on: {
              start: function($event) {
                _vm.dragging = true
              },
              end: function($event) {
                _vm.dragging = false
              }
            }
          },
          _vm._l(_vm.classes, function(element) {
            return _c(
              "div",
              {
                key: element.name,
                staticClass: "list-group-item font-weight-bold"
              },
              [
                _c("span", { staticClass: "fa-stack fa-lg" }, [
                  _c("i", {
                    staticClass: "fa fa-square-o fa-stack-2x text-info"
                  }),
                  _vm._v(" "),
                  _c("i", { staticClass: "fas fa-arrows-alt-v fa-stack-1x" })
                ]),
                _vm._v(
                  " \n                " +
                    _vm._s(element.name) +
                    " (" +
                    _vm._s(parseFloat(element.number)) +
                    ")\n                "
                ),
                _c("input", {
                  attrs: { type: "hidden", name: _vm.getInputName(element.id) },
                  domProps: { value: element.id }
                })
              ]
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }