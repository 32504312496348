<template>
    <div>
        <hr />
        <div v-show="!activeEntry.id">
            <button
                class="btn btn-dark d-block w-100 mb-3"
                type="button"
                data-toggle="collapse"
                data-target="#collapseSettings"
                aria-expanded="false"
                aria-controls="collapseSettings"
            >
                <i class="fas fa-cog"></i>&nbsp;{{ activeJudge ? `Judge: ${activeJudge}` : 'Settings >' }} ({{ languages[language] }})
            </button>
            <div
                id="collapseSettings"
                class="collapse mt-3"
            >
                <div>
                    <h5>
                        Languages:
                        <button
                            v-for="(languageString, key) in languages"
                            :key="key"
                            type="button"
                            class="btn btn-sm btn-light ml-2 my-1"
                            :class="{ 'btn-outline-dark': key === language }"
                            :value="key"
                            @click.prevent="setLanguage"
                        >
                            <i
                                v-if="key === language"
                                class="fas fa-check mr-1"
                            ></i>
                            {{ languageString }}
                        </button>
                    </h5>
                </div>

                <div v-if="activeJudge">
                    <hr />
                    <h5>
                        You are: {{ activeJudge }}<span><button
                            class="btn btn-secondary btn-sm ml-2"
                            type="button"
                            @click="unsetActiveJudge"
                        >Change</button></span>
                    </h5>
                </div>
                <hr />
            </div>
        </div>

        <h4 v-show="contestClass.name">
            {{ `${contestClass.name} (${contestClass.number})` }}<span>&nbsp;&nbsp;<a
                v-show="!activeEntry.id"
                class="btn btn-secondary btn-sm"
                :href="`/judge/scoring/${contestClass.contest_id}`"
            >Change Class</a></span>
        </h4>

        <hr />
        <div v-if="!activeJudge">
            <button
                v-for="judge in judges"
                :key="judge"
                type="button"
                class="btn btn-primary d-block my-1"
                @click="activateJudge(judge)"
            >
                {{ judge }}
            </button>
        </div>

        <div
            v-if="gettingClass"
            class="text-center h5 mb-2"
        >
            <i class="fas fa-spinner fa-spin"></i>&nbsp;Working...
        </div>

        <!-- list the entries -->
        <div v-if="!activeEntry.id && activeJudge">
            <button
                v-if="gettingClass === false"
                type="button"
                class="btn btn-dark d-block text-center w-100"
                @click.prevent="getClassEntries"
            >
                <i class="fas fa-sync-alt"></i>&nbsp;&nbsp;Refresh Class
            </button>

            <div
                v-if="classHasJudgingComplete"
                class="alert alert-danger text-center mt-3"
            >
                <i class="fas fa-exclamation-circle"></i>&nbsp;This contest class has its results published.
                &nbsp;&nbsp;<a
                    class="btn btn-danger btn-sm text-white"
                    href="/judge/scoring"
                >Select Another Class</a>
            </div>

            <div
                v-if="entries.length === 0"
                class="alert alert-info text-center mt-3"
            >
                There are not any entries in this class.
            </div>
            <div
                v-for="entry in entries"
                :key="entry.id"
            >
                <div class="row border-bottom  py-3">
                    <div class="col-12 col-md-1">
                        <button
                            type="button"
                            class="btn btn-primary"
                            :class="{ 'disabled btn-secondary': entry.scoresheets.length >= 2 || entry.no_arrival == 1 }"
                            @click.prevent="getEntry(entry.id)"
                        >
                            <span v-if="entry.class_number < 10">0</span>{{ entry.class_number }}
                        </button>
                    </div>
                    <div class="col-12 col-md-2 pt-3 pt-md-1">
                        <span
                            v-if="entry.no_arrival === 1"
                            class="bg-info rounded p-1 text-white mt-1"
                        >No Arrival</span>
                    </div>
                    <div class="col-12 col-md-5 mt-2 mt-md-0">
                        {{ entry.description }}
                    </div>
                    <div class="col-12 col-md-4">
                        <div
                            v-for="(entryScoresheet, index) in entry.scoresheets"
                            :key="entryScoresheet.id"
                        >
                            <strong>Judge {{ index + 1 }}:</strong>&nbsp;
                            <span
                                v-if="entryScoresheet.comp_score"
                                class="p-1 bg-info rounded"
                            >Comp. Score</span>
                            {{ entryScoresheet.judges_name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- No Arrival Error -->
        <div
            v-if="showNoArrivalError === true"
            class="alert alert-info text-center"
        >
            We show this entry has not arrived.
            Entry number: {{ activeEntry.class_number }}&nbsp;&nbsp;<span><button
                class="btn btn-info btn-sm"
                type="button"
                @click="reloadClass"
            >Select Another Entry</button></span>
        </div>

        <!-- The Scoring area -->
        <div v-if="activeEntry.id && activeJudge && showNoArrivalError === false && !gettingClass">
            <h4>
                Entry Number: <span v-if="activeEntry.class_number < 10">0</span>{{ activeEntry.class_number }} <span><button
                    type="button"
                    class="btn btn-info btn-sm"
                    @click="reloadClass"
                >Change Entry</button></span>
            </h4><hr />
            <div
                v-if="classHasJudgingComplete"
                class="alert alert-danger text-center mt-3"
            >
                <i class="fas fa-exclamation-circle"></i>&nbsp;This contest class has its results published.
                &nbsp;&nbsp;<a
                    class="btn btn-danger btn-sm text-white"
                    href="/judge/scoring"
                >Select Another Class</a>
            </div>
            <div
                v-if="judgeAlreadyScored"
                class="alert alert-danger"
            >
                You already scored this entry. <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click.prevent="reloadClass"
                >
                    Select Another Entry
                </button>
            </div>
            <div
                v-if="activeEntry.scoresheets && activeEntry.scoresheets.length >= 2 && judgeAlreadyScored === false"
                class="alert alert-danger text-center"
            >
                This entry has been scored twice. <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click.prevent="reloadClass"
                >
                    Select Another Entry
                </button>
            </div>
            <div v-else>
                <div
                    v-if="successfulScoresheetSave"
                    class="alert alert-success my-3"
                >
                    Your scoresheet was saved successfully. <button
                        type="button"
                        class="btn btn-info btn-sm"
                        @click="reloadClass"
                    >
                        Select Another Entry
                    </button>
                </div>
                <div v-if="!successfulScoresheetSave">
                    <!-- The Scoresheet area -->
                    <form
                        method="post"
                        @submit.prevent=""
                    >
                        <div class="row">
                            <div class="col-12">
                                <h5 class="bg-dark text-white p-2">
                                    Complimentary Score?
                                    <button
                                        type="button"
                                        class="btn btn-sm d-block mt-1 d-md-inline mt-md-0 ml-md-1"
                                        :class="{ 'btn-danger': inputOtherFields.isCompScore, 'bg-light': !inputOtherFields.isCompScore }"
                                        @click.prevent="handleCompScore"
                                    >
                                        <i
                                            v-show="inputOtherFields.isCompScore"
                                            class="fas fa-check mr-1"
                                        ></i>
                                        Make Complimentary
                                    </button>
                                </h5>
                            </div>
                        </div>
                        <div
                            v-if="inputOtherFields.isCompScore"
                            class="row mb-3"
                        >
                            <div class="col">
                                <label for="comp-score-reason">Complimentary Score Reason:</label><br />
                                <input
                                    id="comp-score-reason"
                                    v-model="inputOtherFields.compScoreReason"
                                    type="text"
                                    class="form-control"
                                    :class="{ 'is-invalid': !fieldIsValid('compScoreReason') }"
                                    name="compScoreReason"
                                    @change="handleCompScoreReason"
                                >
                                <div
                                    v-show="!fieldIsValid('compScoreReason')"
                                    class="invalid-feedback"
                                >
                                    {{ errors['compScoreReason'] }}
                                </div>
                            </div>
                        </div>

                        <!-- defect group -->
                        <div
                            v-for="(defectGroup, index) in scoresheet.defectArr"
                            :key="index"
                        >
                            <h5 class="bg-dark text-white p-2">
                                {{ translateString(index) }}: <span
                                    v-if="scoresheet.defectDescriptArr[index]"
                                    class="text-warning"
                                ><small>{{ scoresheet.defectDescriptArr[index] }}</small></span>
                            </h5>
                            <div
                                v-if="(!fieldIsValid(`appearanceDefectsError`) && (index === 'Texture_and_Appearance_Defects' || index === 'Appearance_Defects'))"
                                class="text-danger"
                            >
                                {{ errors['appearanceDefectsError'] }}
                            </div>
                            <div class="row mb-2">
                                <!-- defect attributes-->
                                <div
                                    v-for="(defect) in defectGroup"
                                    :key="defect"
                                    class="col-6 col-md-3 pb-2"
                                >
                                    <label
                                        class="mb-0"
                                    >{{ translateString(defect) }}:</label><br />
                                    <input
                                        :id="`${index}|${defect}`"
                                        class="form-control defect"
                                        :class="{ 'is-invalid': !fieldIsValid(`${index}|${defect}`) }"
                                        style="width: 7em;"
                                        type="number"
                                        step=".01"
                                        :name="`${index}[${defect}]`"
                                        @change.prevent="checkInputDefect"
                                        @focusout="getScore"
                                    />
                                    <div
                                        v-show="!fieldIsValid(`${index}|${defect}`)"
                                        class="invalid-feedback"
                                    >
                                        {{ errors[`${index}|${defect}`] }}
                                    </div>
                                </div>
                                <div class="col-6 col-md-3 pb-2">
                                    {{ translateString('Other') }} 1:
                                    <input
                                        :id="`${index}|other1Title`"
                                        type="text"
                                        class="form-control otherTitle"
                                        :class="{ 'is-invalid': !fieldIsValid(`${index}|other1Title`) }"
                                        :name="`${index}['other1Title']`"
                                        placeholder="Defect"
                                        @change.prevent="checkOtherTitle"
                                        @focusout="getScore"
                                    >
                                    <div
                                        v-show="!fieldIsValid(`${index}|other1Title`)"
                                        class="invalid-feedback"
                                    >
                                        {{ errors[`${index}|other1Title`] }}
                                    </div>

                                    <input
                                        :id="`${index}|other1`"
                                        style="width: 7em;"
                                        type="number"
                                        step=".01"
                                        placeholder="0"
                                        class="form-control other mt-1"
                                        :class="{ 'is-invalid': !fieldIsValid(`${index}|other1`) }"
                                        :name="`${index}['other1']`"
                                        @change.prevent="checkInputDefect"
                                        @focusout="getScore"
                                    >
                                    <div
                                        v-show="!fieldIsValid(`${index}|other1`)"
                                        class="invalid-feedback"
                                    >
                                        {{ errors[`${index}|other1`] }}
                                    </div>
                                </div>
                                <div class="col-6 col-md-3 pb-2">
                                    {{ translateString('Other') }} 2:
                                    <input
                                        :id="`${index}|other2Title`"
                                        type="text"
                                        class="form-control otherTitle"
                                        :class="{ 'is-invalid': !fieldIsValid(`${index}|other2Title`) }"
                                        :name="`${index}['other2Title']`"
                                        placeholder="Defect"
                                        @change.prevent="checkOtherTitle"
                                        @focusout="getScore"
                                    >
                                    <div
                                        v-show="!fieldIsValid(`${index}|other2Title`)"
                                        class="invalid-feedback"
                                    >
                                        {{ errors[`${index}|other2Title`] }}
                                    </div>

                                    <input
                                        :id="`${index}|other2`"
                                        style="width: 7em;"
                                        type="number"
                                        step=".01"
                                        placeholder="0"
                                        class="form-control other mt-1"
                                        :class="{ 'is-invalid': !fieldIsValid(`${index}|other2`) }"
                                        :name="`${index}['other2']`"
                                        @change.prevent="checkInputDefect"
                                        @focusout="getScore"
                                    >
                                    <div
                                        v-show="!fieldIsValid(`${index}|other2`)"
                                        class="invalid-feedback"
                                    >
                                        {{ errors[`${index}|other2`] }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 class="bg-dark text-white p-2">
                            Comments:
                        </h5>
                        <div class="row">
                            <div class="col">
                                <textarea
                                    v-model="inputOtherFields.judgesComments"
                                    class="form-control"
                                ></textarea>
                            </div>
                        </div>
                        <hr />
                        <div
                            v-if="errors.scoreCalculated"
                            class="alert alert-warning"
                        >
                            {{ errors.scoreCalculated }}
                        </div>
                        <div
                            v-show="!isValid"
                            class="alert alert-warning"
                        >
                            <i class="fas fa-exclamation-circle"></i>&nbsp;&nbsp;Please check for errors above.
                        </div>

                        <div
                            v-show="isSavingScoresheet"
                            class="my-2 h5 text-center"
                        >
                            <i class="fas fa-spinner fa-spin"></i>&nbsp;Saving...
                        </div>
                        <!-- save scoresheet errors -->
                        <div
                            v-for="(error, key) in postScoresheetErrors"
                            :key="key"
                            class="alert alert-danger"
                        >
                            <i class="fas fa-exclamation-circle"></i>&nbsp;{{ error[0] }}
                        </div>

                        <button
                            v-show="!isSavingScoresheet"
                            type="button"
                            class="btn btn-primary"
                            data-toggle="modal"
                            data-target="#reviewScoreModal"
                        >
                            Review Your Scoresheet
                        </button>

                        <!-- Modal -->
                        <div
                            id="reviewScoreModal"
                            class="modal fade"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="reviewScoreModal"
                            aria-hidden="true"
                        >
                            <div
                                class="modal-dialog"
                                role="document"
                            >
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5
                                            id="exampleModalLabel"
                                            class="modal-title"
                                        >
                                            Review Scoresheet
                                        </h5>
                                        <button
                                            type="button"
                                            class="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <ModalReviewBody
                                            :input-other-fields="inputOtherFields"
                                            :filtered-input-defects="filteredInputDefects"
                                            :translate-string="translateString"
                                            :active-judge="activeJudge"
                                            :active-entry="activeEntry"
                                            :score-calculated="scoreCalculated"
                                            :is-valid="isValid"
                                        >
                                        </ModalReviewBody>
                                    </div>
                                    <div class="modal-footer">
                                        <button
                                            id="close-modal-button"
                                            type="button"
                                            class="btn btn-secondary"
                                            data-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                        <button
                                            v-if="isValid"
                                            type="button"
                                            class="btn btn-primary"
                                            @click.prevent="postScoresheet"
                                        >
                                            Save Your Scoresheet
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import Cookie from 'js-cookie'
import arraySort from 'array-sort'
import ModalReviewBody from './ModalReviewBody'
import moment from 'moment'

export default {
  components: { ModalReviewBody },
  props: {
    classId: {
      type: Number,
      required: true
    },
    judges: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      languages: { en: 'English', de: 'Deutsche', fr: 'Français', it: 'Italian', es: 'Español' },
      scoreCalculated: 100,
      language: 'en',
      activeJudge: false,
      gettingClass: false,
      gettingScoresheet: false,
      isSavingScoresheet: false,
      successfulScoresheetSave: false,
      showNoArrivalError: false,
      scoresheet: {},
      scoresheetTranslations: [],
      postScoresheetErrors: {},
      entries: [],
      contest: {},
      contestClass: {},
      activeEntry: {},
      errors: {},
      inputDefects: {},
      inputOtherDefects: {},
      inputOtherFields: {
        isCompScore: false,
        compScoreReason: '',
        judgesComments: ''
      }
    }
  },
  computed: {
    classHasJudgingComplete () {
      if (this.contestClass.judging_complete === 1) {
        this.$set(this.errors, 'classHasJudgingComplete', 'This class has its results published.')
        return true
      }
      return false
    },
    judgeAlreadyScored () {
      for (var i in this.activeEntry.scoresheets) {
        if (this.activeEntry.scoresheets[i].judges_name === this.activeJudge) {
          this.$set(this.errors, 'judgeAlreadyScored', 'You already scored this entry.')
          return true
        }
      }
      return false
    },
    isValid () {
      return !(Object.keys(this.errors).length > 0)
    },
    getPostData () {
      return {
        entry_id: this.activeEntry.id,
        contest_class_id: this.activeEntry.class.id,
        judges_name: this.activeJudge,
        judges_comments: this.inputOtherFields.judgesComments,
        comp_score: this.inputOtherFields.isCompScore,
        comp_score_reason: this.inputOtherFields.compScoreReason,
        defects: this.filteredInputDefects
      }
    },
    filteredInputDefects () {
      const filtered = {}
      for (var i in this.inputDefects) {
        if (this.inputDefects[i].length > 0) {
          const parts = i.split('|')
          if (!filtered[parts[0]]) {
            filtered[parts[0]] = []
          }
          filtered[parts[0]].push({ name: parts[1], value: this.inputDefects[i] })
        }
      }
      // other field defects
      for (var i2 in this.inputOtherDefects) {
        if (this.inputOtherDefects[i2].title && this.inputOtherDefects[i2].value) {
          const parts = i2.split('|')
          if (!filtered[parts[0]]) {
            filtered[parts[0]] = []
          }
          filtered[parts[0]].push({ name: this.inputOtherDefects[i2].title, value: this.inputOtherDefects[i2].value })
        }
      }
      return filtered
    }
  },
  mounted () {
    this.getScoresheet()
    this.getClassEntries()
    if (Cookie.get('judge')) {
      this.activeJudge = Cookie.get('judge')
    }
    if (Cookie.get('language')) {
      this.language = Cookie.get('language')
    }
  },
  methods: {
    localStorage (values) {
      try {
        var dateFormat = moment().format('YYYY-MM-DD HHmmss')
        values.timestamp = new Date().getTime()

        localStorage.setItem(dateFormat + '-scoresheet-' + this.activeEntry.class.number + ':' + this.activeEntry.class_number, JSON.stringify(values))

        for (var key in localStorage) {
          if (key.includes('scoresheet')) {
            var scoresheet = JSON.parse(localStorage.getItem(key))

            // remove item if older then 6 hours.
            if (scoresheet.timestamp + 21600000 < new Date().getTime()) {
              localStorage.removeItem(key)
            }
          }
        }
      } catch (e) {
        // console.log(e)
      }
    },
    textureAppearanceError: function () {
      let totalDefects = 0
      for (var i in this.inputDefects) {
        if (i.indexOf('Texture_and_Appearance_Defects|') >= 0 || i.indexOf('Appearance_Defects|') >= 0) {
          if (!isNaN(this.inputDefects[i]) && this.inputDefects[i].length > 0) {
            totalDefects += parseFloat(this.inputDefects[i])
          }
        }
      }
      if (totalDefects > 0.5) {
        this.$set(this.errors, 'appearanceDefectsError', 'Total for section must be <= .5.')
      } else {
        this.$delete(this.errors, 'appearanceDefectsError')
      }
    },
    handleCompScoreReason (event) {
      if (this.inputOtherFields.isCompScore && this.inputOtherFields.compScoreReason.length === 0) {
        this.$set(this.errors, 'compScoreReason', 'You must provide a reason.')
      } else {
        this.$delete(this.errors, 'compScoreReason')
      }
    },
    handleCompScore (event) {
      if (!this.inputOtherFields.isCompScore) {
        this.inputOtherFields.isCompScore = true
        if (this.inputOtherFields.compScoreReason.length === 0) {
          this.$set(this.errors, 'compScoreReason', 'You must provide a reason.')
        }
      } else {
        this.inputOtherFields.compScoreReason = ''
        this.inputOtherFields.isCompScore = false
        this.$delete(this.errors, 'compScoreReason')
      }
    },
    setLanguage (event) {
      this.language = event.target.value
      this.getClassEntries().then(() => {
        Cookie.set('language', this.language, { sameSite: 'Lax' })
      })
    },
    getDefectFields () {
      const defectFields = document.getElementsByClassName('defect')
      for (var i = 0; i < defectFields.length; i++) {
        this.$set(this.inputDefects, defectFields[i].id, defectFields[i].value)
      }
    },
    getOtherDefectFields () {
      const defectFields = document.getElementsByClassName('other')
      for (var i = 0; i < defectFields.length; i++) {
        const titleField = document.getElementById(defectFields[i].id + 'Title').value
        if (titleField.length > 0 && defectFields[i].value.length > 0) {
          this.$set(this.inputOtherDefects, defectFields[i].id, { title: titleField, value: defectFields[i].value })
        } else {
          this.$delete(this.inputOtherDefects, defectFields[i].id)
        }
      }
    },
    getScore () {
      this.getDefectFields()
      this.getOtherDefectFields()
      this.textureAppearanceError()
      let totalDefects = 0
      for (var i in this.inputDefects) {
        if (!isNaN(this.inputDefects[i]) && this.inputDefects[i].length > 0) {
          totalDefects += parseFloat(this.inputDefects[i])
        }
      }

      for (var i2 in this.inputOtherDefects) {
        if (!isNaN(this.inputOtherDefects[i2].value) && this.inputOtherDefects[i2].value.length > 0) {
          totalDefects += parseFloat(this.inputOtherDefects[i2].value)
        }
      }
      this.scoreCalculated = (100 - totalDefects).toFixed(2)

      if (this.scoreCalculated < 0) {
        this.$set(this.errors, 'scoreCalculated', `Your score is < 0: ${this.scoreCalculated}`)
      } else {
        this.$delete(this.errors, 'scoreCalculated')
      }
    },
    fieldIsValid (fieldId) {
      if (Object.prototype.hasOwnProperty.call(this.errors, fieldId) && this.errors[fieldId] !== false) {
        return false
      }
      return true
    },
    checkOtherTitle (event) {
      if (event.target.value.length > 30) {
        this.$set(this.errors, event.target.id, 'Limit to 30 characters.')
      } else {
        this.$delete(this.errors, event.target.id)
      }
    },
    checkInputDefect (event) {
      const regexp = new RegExp('^\\d+(\\.\\d{1,2})?$')
      event.target.value = event.target.value.trim()
      // if a number not a string and length > 0
      if (!isNaN(event.target.value) && event.target.value.length > 0) {
        event.target.value = parseFloat(event.target.value)
      }
      // if the value is 0 then change to empty.
      if (event.target.value === '0') {
        event.target.value = ''
        return
      }

      // delete error if length is 0
      if (event.target.value.length === 0) {
        this.$delete(this.errors, event.target.id)
        return
      }

      // if it's a two digit decimal
      if (!regexp.test(event.target.value)) {
        this.$set(this.errors, event.target.id, `Only 2 digit positive decimals: ${event.target.value}`)
      } else {
        this.$delete(this.errors, event.target.id)
      }
    },
    translateString (text) {
      text = text.replace(/_/g, ' ')
      if (this.language === 'en') {
        return text
      }

      if (this.scoresheetTranslations[this.language][text]) {
        return this.scoresheetTranslations[this.language][text]
      }
      return text
    },
    reloadClass () {
      window.location.replace(window.location.href)
    },
    unsetActiveEntry () {
      this.getClassEntries().then(() => {
        this.showNoArrivalError = false
        this.postScoresheetErrors = []
        this.errors = {}
        this.successfulScoresheetSave = false
        this.inputDefects = {}
        this.inputOtherDefects = {}
        this.inputOtherFields = {
          isCompScore: false,
          compScoreReason: '',
          judgesComments: ''
        }
        this.activeEntry = {}
        this.scoreCalculated = 100
      })
    },
    activateJudge (judge) {
      this.getClassEntries().then(() => {
        this.activeJudge = judge
        Cookie.set('judge', this.activeJudge, { sameSite: 'Lax' })
      })
    },
    async unsetActiveJudge () {
      this.unsetActiveEntry()
      this.activeJudge = false
    },
    async getEntry (id) {
      return this.getScoresheet().then(() => {
        return this.getClassEntries().then(() => {
          const activeEntry = this.entries.find(function (entry, index) {
            if (entry.id === id) {
              return true
            }
          })
          this.activeEntry = activeEntry

          if (this.activeEntry.no_arrival === 1) {
            this.showNoArrivalError = true
          }
        })
      })
    },
    getClassEntries () {
      try {
        this.gettingClass = true
        this.entries = {}
        return axios.get(
          `/api-session/judging/class-entries/${this.classId}/${this.language}`
        ).then((response) => {
          if (response.data.success) {
            this.entries = arraySort(response.data.data.entries, ['class_number'])

            this.contest = response.data.data.meta.contest
            this.contestClass = response.data.data.meta.contest_class
          } else {
            this.contestClass = response.data.data.meta.contest_class
          }
        }).catch((error) => {
          if (error.response && error.response.status && (error.response.status === 401 || error.response.status === 403)) {
            window.location.replace(window.location.href)
            return
          }

          alert(error.message)
          return error
        }).finally(() => {
          this.gettingClass = false
        })
      } catch (ex) {
        alert(ex)
      }
    },
    async getScoresheet () {
      try {
        this.gettingScoresheet = true
        return axios.get(
          `/api-session/judging/class-scoresheet/${this.classId}`
        ).then((response) => {
          if (response.data.success) {
            this.scoresheetTranslations = response.data.data.translations
            this.scoresheet = response.data.data.scoresheet
          }
        }).catch((error) => {
          if (error.response && error.response.status && (error.response.status === 401 || error.response.status === 403)) {
            window.location.replace(window.location.href)
            return
          }

          alert(error.message)
          return error
        }).finally(() => {
          this.gettingScoresheet = false
        })
      } catch (ex) {
        alert(ex)
      }
    },
    async postScoresheet () {
      this.isSavingScoresheet = true
      this.postScoresheetErrors = {}
      document.getElementById('close-modal-button').click()
      this.localStorage(this.getPostData)

      try {
        this.gettingScoresheet = true
        return axios.post(
          `/api-session/judging/entry-scoresheet/${this.activeEntry.id}`,
          JSON.stringify(this.getPostData)
        ).then((response) => {
          if (response.data.success) {
            this.successfulScoresheetSave = true
          }
        }).catch((error) => {
          this.successfulScoresheetSave = false

          if (error.response && error.response.status && (error.response.status === 401 || error.response.status === 403)) {
            window.location.replace(window.location.href)
            return
          }

          if (error.response && error.response.status && error.response.status === 422) {
            this.postScoresheetErrors = error.response.data.errors
            return
          }

          alert(error.message)
          return error
        }).finally(() => {
          this.isSavingScoresheet = false
        })
      } catch (ex) {
        alert(ex)
      }
    }
  }
}
</script>
