<template>
    <div>
        <label
            v-show="(inputLabel.length > 0)"
            :for="inputId"
        >{{ inputLabel }}</label><br v-show="(inputLabel.length > 0)">
        <div class="btn-group btn-group-toggle">
            <button
                v-for="(option) in options"
                :key="option.value"
                :class="['btn', (valueSelected === option.value) ? 'btn-info active' : 'btn-secondary bg-light text-dark']"
                :dataValue="option.value"
                type="button"
                @click="handleClick(option, $event)"
            >
                <i :class="['fas', (valueSelected === option.value) ? 'fa-check' : '']"></i>&nbsp;{{ option.label }}
            </button>
        </div>
        <input
            :id="inputId"
            v-model="valueSelected"
            type="hidden"
            :name="inputName"
        />
    </div>
</template>

<script>
export default {
  name: 'ButtonInput',
  props: {
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    inputName: {
      type: String,
      default: ''
    },
    active: {
      type: String,
      default: ''
    },
    inputId: {
      type: String,
      default: 'button-select'
    },
    inputLabel: {
      type: String,
      default: 'inputLabel Property'
    }
  },
  data () {
    return {
      valueSelected: this.active
    }
  },
  watch: {
    active: function (newValue, oldValue) {
      this.valueSelected = newValue
    }
  },
  mounted: function () {
    this.valueSelected = this.active
  },
  methods: {
    handleClick (option, event) {
      this.valueSelected = option.value
      this.$emit('clicked', { value: this.valueSelected, id: this.inputId })

      return this.valueSelected
    }
  }
}
</script>
