var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hideAll !== false
      ? _c("div", [
          _c("p", { staticClass: "alert alert-danger" }, [
            _vm._v("\n            " + _vm._s(_vm.hideAll) + ". "),
            _c("a", { attrs: { href: _vm.selfHref } }, [
              _vm._v("Click here to reload the page and again.")
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.hideAll == false
      ? _c(
          "div",
          [
            _c("div", { staticClass: "h5 font-weight-bold" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.dataEntries.length) +
                  " Entries:\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "border border-dark py-1 px-2 mb-2" }, [
              _c("div", { staticClass: "d-block d-md-inline" }, [
                _vm._v("\n                Sort By: \n            ")
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-outline-primary btn-sm font-weight-bold",
                  class: { active: _vm.activeSort == "class_number" },
                  attrs: { type: "button", value: "class_number" },
                  on: {
                    click: function($event) {
                      return _vm.sortBy("class_number")
                    }
                  }
                },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeSort === "class_number",
                        expression: "activeSort === 'class_number'"
                      }
                    ],
                    staticClass: "fas fa-check"
                  }),
                  _vm._v("\n                Entry #\n            ")
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-outline-primary btn-sm font-weight-bold",
                  class: { active: _vm.activeSort == "score_average" },
                  attrs: { type: "button", value: "score_average" },
                  on: {
                    click: function($event) {
                      return _vm.sortBy("score_average")
                    }
                  }
                },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeSort === "score_average",
                        expression: "activeSort === 'score_average'"
                      }
                    ],
                    staticClass: "fas fa-check"
                  }),
                  _vm._v("\n                Score\n            ")
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-outline-primary btn-sm font-weight-bold",
                  class: { active: _vm.activeSort == "tag" },
                  attrs: { type: "button", value: "tag" },
                  on: {
                    click: function($event) {
                      return _vm.sortBy("tag")
                    }
                  }
                },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeSort === "tag",
                        expression: "activeSort === 'tag'"
                      }
                    ],
                    staticClass: "fas fa-check"
                  }),
                  _vm._v("\n                Tag\n            ")
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-outline-primary btn-sm font-weight-bold",
                  class: { active: _vm.activeSort == "picked" },
                  attrs: { type: "button", value: "picked" },
                  on: {
                    click: function($event) {
                      return _vm.sortBy("picked")
                    }
                  }
                },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeSort === "picked",
                        expression: "activeSort === 'picked'"
                      }
                    ],
                    staticClass: "fas fa-check"
                  }),
                  _vm._v("\n                Picked\n            ")
                ]
              )
            ]),
            _vm._v(" "),
            _vm._l(_vm.dataEntries, function(entry, id) {
              return _c(
                "div",
                { key: id, staticClass: "row border-bottom border-dark" },
                [
                  _c("div", { staticClass: "col-6 col-lg-2 my-3" }, [
                    _c(
                      "span",
                      { class: { h5: _vm.activeSort === "class_number" } },
                      [_c("strong", [_vm._v("Entry:")])]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("h4", [
                      _vm._v(
                        _vm._s(entry.contest_class.number) +
                          ":" +
                          _vm._s(entry.class_number)
                      )
                    ]),
                    _vm._v(" "),
                    entry.scoresheets &&
                    entry.scoresheets[0] &&
                    entry.scoresheets[0].comp_score
                      ? _c("span", { staticClass: "bg-warning rounded p-1" }, [
                          _vm._v(
                            "\n                    Comp. Score\n                "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 col-lg-3 my-3" },
                    [
                      _c("span", { class: { h5: _vm.activeSort === "tag" } }, [
                        _c("strong", [_vm._v("Tag:")])
                      ]),
                      _c("br"),
                      _vm._v(" "),
                      _c("entry-tagging", {
                        attrs: {
                          tags: _vm.tags,
                          selected: _vm.tagSelected(entry),
                          "entry-id": entry.id
                        },
                        on: {
                          taggingError: _vm.handleTaggingError,
                          taggingClick: _vm.handleTaggedEvent
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-lg-3 my-3" }, [
                    _vm._m(0, true),
                    _c("br"),
                    _vm._v(
                      "\n                " + _vm._s(entry.location.company)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          entry.location.city.length > 0
                            ? entry.location.city
                            : ""
                        ) +
                        _vm._s(
                          entry.location.state.length > 0
                            ? ", " + entry.location.state
                            : ""
                        ) +
                        "\n                "
                    ),
                    entry.location.country.length > 0
                      ? _c("div", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(entry.location.country) +
                              "\n                "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-2" }, [
                      _vm._m(1, true),
                      _c("br"),
                      _vm._v(
                        "\n                    " +
                          _vm._s(entry.weight) +
                          " " +
                          _vm._s(entry.weight_unit) +
                          " \n                    "
                      ),
                      _vm._m(2, true),
                      _vm._v(" \n                    " + _vm._s(entry.pieces)),
                      _c("br"),
                      _vm._v(" "),
                      _vm._m(3, true),
                      _c("br"),
                      _vm._v(
                        "\n                    " +
                          _vm._s(entry.description) +
                          "\n                "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 col-lg-1 my-3",
                      class: { h5: _vm.activeSort === "score_average" }
                    },
                    [
                      _vm._m(4, true),
                      _c("br"),
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            entry.scoresheets &&
                              entry.scoresheets[0] &&
                              entry.scoresheets[0].score_average
                              ? parseFloat(entry.scoresheets[0].score_average)
                              : "Not Judged"
                          ) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-3 my-3" },
                    [
                      _c("div", { staticClass: "mb-1" }, [
                        _c(
                          "span",
                          { class: { h5: _vm.activeSort === "picked" } },
                          [_c("strong", [_vm._v("Picked:")])]
                        )
                      ]),
                      _vm._v(" "),
                      _c("button-input", {
                        attrs: {
                          options: [
                            { label: "Yes", value: "1" },
                            { label: "No", value: "0" }
                          ],
                          "input-name": "picked",
                          active: entry.picked.toString(),
                          "input-label": "",
                          "input-id": "entry-" + id.toString()
                        },
                        on: { clicked: _vm.setPicked }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isSaving && id == _vm.isSaving,
                              expression: "isSaving && id == isSaving"
                            }
                          ],
                          staticClass: "mt-2"
                        },
                        [
                          _c("i", { staticClass: "fas fa-spinner fa-spin" }),
                          _vm._v(" Saving\n                ")
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("strong", [_vm._v("Company:")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("strong", [_vm._v("Weight:")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("strong", [_vm._v("Pieces:")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("strong", [_vm._v("Description:")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("strong", [_vm._v("Score:")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }