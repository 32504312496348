import axios from 'axios'

export default {
  async getCart () {
    try {
      return axios.get(
        '/api-session/cart',
        {}
      )
        .then(function (response) {
          if (response && response.data) {
            return response.data
          }
        })
    } catch (ex) {
      console.log(ex)
    }
  },
  async postAuthorizeNet (url, data) {
    try {
      return axios.post(url, data)
        .then(function (response) {
          if (response && response.data) {
            return response.data
          }
        })
    } catch (ex) {
      console.log(ex)
    }
  },

  /**
   * Run after the response from Authorize.net
   * @param  {object} data
   */
  async postCartInvoiceNumberBeforeAuthorizeNet (data) {
    try {
      return axios.post(
        '/api-session/cart/pre-authorize',
        data
      )
        .then(function (response) {
          if (response && response.data) {
            return response.data
          }
        })
    } catch (ex) {
      console.log(ex)
    }
  },

  /**
   * Run after the response from Authorize.net
   * @param  {object} data
   */
  async postCartAndAuthorizeNet (data) {
    try {
      return axios.post(
        '/api-session/cart/online',
        data
      )
        .then(function (response) {
          if (response && response.data) {
            return response.data
          }
        })
    } catch (ex) {
      console.log(ex)
    }
  },
  async postCartCheck (data) {
    try {
      return axios.post(
        '/api-session/cart/check',
        data
      )
        .then(function (response) {
          if (response && response.data) {
            return response.data
          }
        })
    } catch (ex) {
      console.log(ex)
    }
  },
  handleError (error) {
    if (error.response && error.response.status && error.response.status === 401) {
      window.location.replace(window.location.href)
      return
    }
    alert(error.message)
    return error
  }
}
