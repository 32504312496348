<template>
    <div>
        <form method="get">
            <div class="form-row">
                <div class="col-6">
                    <!-- Company -->
                    <div class="form-group">
                        <label for="contest-company">Company</label>
                        <input
                            id="contest-company"
                            v-model="request.company"
                            type="text"
                            name="company"
                            class="form-control"
                        />
                    </div>
                </div>
                <div class="col-6">
                    <!-- Email -->
                    <div class="form-group">
                        <label for="contest-email">Email</label>
                        <input
                            id="contest-email"
                            v-model="request.email"
                            type="type"
                            name="email"
                            class="form-control"
                        />
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col-6">
                    <!-- First Name -->
                    <div class="form-group">
                        <label for="first-name">First Name</label>
                        <input
                            id="first-name"
                            v-model="request.first_name"
                            type="text"
                            name="first_name"
                            class="form-control"
                        />
                    </div>
                </div>
                <div class="col-6">
                    <!-- Last Name -->
                    <div class="form-group">
                        <label for="last-name">Last Name</label>
                        <input
                            id="last-name"
                            v-model="request.last_name"
                            type="text"
                            name="last_name"
                            class="form-control"
                        />
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-12 col-md-6">
                    <!-- Role -->
                    <div class="form-group">
                        <label for="role">Role</label>
                        <select
                            id="role"
                            v-model="request.role"
                            :class="['form-control', (errors.role && errors.role.length > 0) ? 'is-invalid' : '']"
                            name="role"
                        >
                            <option value="">
                                -- all --
                            </option>
                            <option
                                v-for="role in roles"
                                :key="role"
                                :value="role"
                            >
                                {{ role }}
                            </option>
                        </select>
                        <div
                            v-if="errors.role && errors.role.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.role[0] }}
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-2">
                    <!-- Sort Order -->
                    <div class="form-group">
                        <label for="sort-field">Sort Field</label>
                        <select
                            v-model="request.sort_field"
                            class="form-control"
                            name="sort_field"
                            @change="handleSortFieldChange"
                        >
                            <option
                                v-for="option in sortField"
                                id="sort-field"
                                :key="option.value"
                                :value="option.value"
                            >
                                {{ option.label }}
                            </option>
                        </select>
                        <div
                            v-if="errors.sort_field && errors.sort_field.length > 0"
                            class="invalid-feedback d-block"
                        >
                            {{ errors.sort_field[0] }}
                        </div>
                    </div>
                </div>

                <div class="col-6 col-md-3">
                    <!-- Sort Direction -->
                    <div class="form-group">
                        <button-input
                            :options="sortOptions"
                            input-name="sort_order"
                            :active="sortOrderUpdate"
                            input-label="Sort Order"
                            input-id="sort-order"
                        >
                        </button-input>
                        <div
                            v-if="errors.sort_order && errors.sort_order.length > 0"
                            class="invalid-feedback d-block"
                        >
                            {{ errors.sort_order[0] }}
                        </div>
                    </div>
                </div>
            </div>
            <submit-clear-block />
        </form>
    </div>
</template>

<script>

import SubmitClearBlock from './SubmitClearBlock'
import ButtonInput from './ButtonInput'

export default {
  name: 'UserSearch',
  components: {
    'submit-clear-block': SubmitClearBlock,
    'button-input': ButtonInput
  },
  props: {
    request: {
      type: Object,
      default: function () {
        return {
          sort_field: 'last_name',
          sort_order: 'asc'
        }
      }
    },
    roles: {
      type: Array,
      default () {
        return []
      }
    },
    errors: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      sortOrderUpdate: this.request.sort_order,
      sortOptions: [{ label: 'ASC', value: 'asc' }, { label: 'DESC', value: 'desc' }],
      sortField: [
        {
          label: 'Last Login',
          value: 'last_login',
          sortOrder: 'desc'
        },
        {
          label: 'Company',
          value: 'company',
          sortOrder: 'asc'
        },
        {
          label: 'Last Name',
          value: 'last_name',
          sortOrder: 'asc'
        },
        {
          label: 'First Name',
          value: 'first_name',
          sortOrder: 'asc'
        }
      ]
    }
  },
  created: function () {
    if (!this.request.sort_field || !this.request.sort_field.length) {
      this.request.sort_field = 'last_name'
    }
    if (!this.request.sort_order || !this.request.sort_order.length) {
      this.sortOrderUpdate = 'asc'
    }

    if (!this.request.role || !this.request.role.length) {
      this.request.role = ''
    }
  },
  methods: {
    handleSortFieldChange (e) {
      this.setSortOrder(e.target.value)
    },
    setSortOrder (value) {
      for (var i = 0; i < this.sortField.length; i++) {
        if (value === this.sortField[i].value) {
          this.sortOrderUpdate = this.sortField[i].sortOrder
          break
        }
      }
    }
  }
}
</script>
