<template>
    <div>
        <div class="text-center">
            <span v-if="loading"><i class="fa fa-spinner fa-spin"></i>&nbsp;{{ !loaded ? 'Loading' : 'Refreshing' }}...</span>&nbsp;
        </div>
        <div class="overflow-auto border py-1">
            <bar-chart
                v-if="loaded"
                :chartdata="chartdata"
                :height="2500"
            />
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import BarChart from './BarChart.vue'

export default {
  name: 'BarChartContainer',
  components: { BarChart },
  props: {
    contestId: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    loading: false,
    loaded: false,
    chartdata: null
  }),
  async mounted () {
    this.getData()

    // polling every 20 seconds
    setInterval(() => {
      this.getData()
    }, 20000)
  },
  methods: {
    getData () {
      this.loading = true
      // setInterval(function(){ alert("Hello world"); }, 3000);
      try {
        return axios.get(`/api-session/entry-vs-judging/${this.contestId}`)
          .then((response) => {
            this.chartdata = response.data.data
            this.loaded = true
            this.loading = false
          }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 401) {
              window.location.replace(window.location.href)
              return
            }

            // alert(error.message)
            console.log(error)
            return error
          })
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>
