<template>
    <div>
        <form method="get">
            <contest-class-select
                :contests="contests"
                :request="request"
                :errors="errors"
            />

            <div class="form-row">
                <div class="col-6">
                    <!-- Class Number -->
                    <div class="form-group">
                        <label for="class-number">Entry Number</label>
                        <input
                            id="class-number"
                            v-model="request.class_number"
                            type="number"
                            name="class_number"
                            :class="['form-control', (errors.class_number && errors.class_number.length > 0) ? 'is-invalid' : '']"
                        />
                        <div
                            v-if="errors.class_number && errors.class_number.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.class_number[0] }}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <!-- Entry Identifier -->
                    <div class="form-group">
                        <label for="entry-id">Entry ID</label>
                        <input
                            id="entry-id"
                            v-model="request.id"
                            type="text"
                            name="id"
                            :class="['form-control', (errors.id && errors.id.length > 0) ? 'is-invalid' : '']"
                        />
                        <div
                            v-if="errors.id && errors.id.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.id[0] }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col-6">
                    <!-- Company -->
                    <div class="form-group">
                        <label for="contest-company">Company</label>
                        <input
                            id="contest-company"
                            v-model="request.company"
                            type="text"
                            name="company"
                            class="form-control"
                        />
                    </div>
                </div>
                <div class="col-6">
                    <!-- city -->
                    <div class="form-group">
                        <label for="contest-city">City</label>
                        <input
                            id="contest-city"
                            v-model="request.city"
                            type="text"
                            name="city"
                            class="form-control"
                        />
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col-6">
                    <!-- user first_name -->
                    <div class="form-group">
                        <label for="class-number">User First</label>
                        <input
                            id="first-namer"
                            v-model="request.first_name"
                            type="text"
                            name="first_name"
                            :class="['form-control', (errors.first_name && errors.first_name.length > 0) ? 'is-invalid' : '']"
                        />
                        <div
                            v-if="errors.first_name && errors.first_name.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.first_name[0] }}
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <!-- user last_name -->
                    <div class="form-group">
                        <label for="last-name">User Last</label>
                        <input
                            id="last-name"
                            v-model="request.last_name"
                            type="text"
                            name="last_name"
                            :class="['form-control', (errors.last_name && errors.last_name.length > 0) ? 'is-invalid' : '']"
                        />
                        <div
                            v-if="errors.last_name && errors.last_name.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.last_name[0] }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col-12 col-lg-3">
                    <!-- No Arrival -->
                    <div class="mb-3">
                        <button-input
                            :options="[{ label: 'All', value: ''}, {label: 'Yes', value: '0'}, {label: 'No', value: '1'}]"
                            input-name="no_arrival"
                            :active="request.no_arrival"
                            input-label="Arrived?"
                            input-id="no-arrival"
                        >
                        </button-input>
                        <div
                            v-if="errors.no_arrival && errors.no_arrival.length > 0"
                            class="invalid-feedback d-block"
                        >
                            {{ errors.no_arrival[0] }}
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-3">
                    <!-- Order Processed -->
                    <div class="mb-3">
                        <button-input
                            :options="[{ label: 'All', value: ''}, {label: 'Yes', value: '1'}, {label: 'No', value: '0'}]"
                            input-name="completed"
                            :active="request.completed"
                            input-label="Order Completed?"
                            input-id="order-completed"
                        >
                        </button-input>
                        <div
                            v-if="errors.completed && errors.completed.length > 0"
                            class="invalid-feedback d-block"
                        >
                            {{ errors.completed[0] }}
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-6">
                    <!-- Scoresheet Count -->
                    <div class="mb-3">
                        <button-input
                            :options="[{ label: 'All', value: ''}, {label: '0', value: '0'}, {label: '1', value: '1'}, {label: '2', value: '2'}, {label: '> 2', value: '3'}]"
                            input-name="scoresheets"
                            :active="request.scoresheets"
                            input-label="Scoresheet Count?"
                            input-id="scoresheets"
                        >
                        </button-input>
                        <div
                            v-if="errors.scoresheets && errors.scoresheets.length > 0"
                            class="invalid-feedback d-block"
                        >
                            {{ errors.scoresheets[0] }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-6 col-lg-3">
                    <!-- Sort Order -->
                    <div class="form-group">
                        <label for="sort-field">Sort Field</label>
                        <select
                            v-model="request.sort_field"
                            class="form-control"
                            name="sort_field"
                            @change="handleSortFieldChange"
                        >
                            <option
                                v-for="option in sortField"
                                id="sort-field"
                                :key="option.value"
                                :value="option.value"
                            >
                                {{ option.label }}
                            </option>
                        </select>
                        <div
                            v-if="errors.sort_field && errors.sort_field.length > 0"
                            class="invalid-feedback d-block"
                        >
                            {{ errors.sort_field[0] }}
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <!-- Sort Order -->
                    <div class="form-group">
                        <button-input
                            :options="sortOptions"
                            input-name="sort_order"
                            :active="sortOrderUpdate"
                            input-label="Sort Order"
                            input-id="sort-order"
                        >
                        </button-input>
                        <div
                            v-if="errors.sort_order && errors.sort_order.length > 0"
                            class="invalid-feedback d-block"
                        >
                            {{ errors.sort_order[0] }}
                        </div>
                    </div>
                </div>
            </div>
            <submit-clear-block />
        </form>
    </div>
</template>

<script>

import ButtonInput from './ButtonInput'
import SubmitClearBlock from './SubmitClearBlock'
import ContestClassSelect from './ContestClassSelect'

export default {
  name: 'ContestClassSelect',
  components: {
    'button-input': ButtonInput,
    'submit-clear-block': SubmitClearBlock,
    'contest-class-select': ContestClassSelect
  },
  props: {
    contests: {
      type: Array,
      default: () => [],
      required: true
    },
    request: {
      type: Object,
      default: function () {
        return {
          contest_id: ''
        }
      }
    },
    errors: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      localContests: this.contests,
      localContestClasses: [],
      sortOptions: [{ label: 'ASC', value: 'asc' }, { label: 'DESC', value: 'desc' }],
      sortField: [
        {
          label: 'Entry Date/ID',
          value: 'id',
          sortOrder: 'desc'
        },
        {
          label: 'Company',
          value: 'entry_company',
          sortOrder: 'asc'
        },
        {
          label: 'Entry Number',
          value: 'class_number',
          sortOrder: 'asc'
        },
        {
          label: 'Entrant Company',
          value: 'company',
          sortOrder: 'asc'
        }
      ],
      sortOrderUpdate: this.request.sort_order
    }
  },
  methods: {
    handleSortFieldChange (e) {
      this.setSortOrder(e.target.value)
    },
    setSortOrder (value) {
      for (var i = 0; i < this.sortField.length; i++) {
        if (value === this.sortField[i].value) {
          this.sortOrderUpdate = this.sortField[i].sortOrder
          break
        }
      }
    }
  }
}
</script>
