var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "label",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.inputLabel.length > 0,
            expression: "(inputLabel.length > 0)"
          }
        ],
        attrs: { for: _vm.inputId }
      },
      [_vm._v(_vm._s(_vm.inputLabel))]
    ),
    _c("br", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.inputLabel.length > 0,
          expression: "(inputLabel.length > 0)"
        }
      ]
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn-group btn-group-toggle" },
      _vm._l(_vm.options, function(option) {
        return _c(
          "button",
          {
            key: option.value,
            class: [
              "btn",
              _vm.valueSelected === option.value
                ? "btn-info active"
                : "btn-secondary bg-light text-dark"
            ],
            attrs: { dataValue: option.value, type: "button" },
            on: {
              click: function($event) {
                return _vm.handleClick(option, $event)
              }
            }
          },
          [
            _c("i", {
              class: [
                "fas",
                _vm.valueSelected === option.value ? "fa-check" : ""
              ]
            }),
            _vm._v(" " + _vm._s(option.label) + "\n        ")
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.valueSelected,
          expression: "valueSelected"
        }
      ],
      attrs: { id: _vm.inputId, type: "hidden", name: _vm.inputName },
      domProps: { value: _vm.valueSelected },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.valueSelected = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }