var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shown
    ? _c(
        "div",
        {
          class: [
            "alert",
            "rounded-0",
            "alert-dismissible",
            "fade",
            "show",
            "mb-3",
            _vm.status
          ],
          attrs: { role: "alert" }
        },
        [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.alert.title))
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.alert.message))]),
          _vm._v(" "),
          _vm._m(0)
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "alert",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }