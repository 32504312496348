<template>
    <div>
        <v-date-picker
            :value="date"
            mode="single"
            :name="name"
            :input-props="inputProps"
        />
    </div>
</template>

<script>

export default {
  props: {
    date: {
      type: [Date, String],
      required: false,
      default: ''
    },
    name: {
      type: String,
      default: 'date'
    },
    inputProps: {
      type: Object,
      required: true
    }
  }
}
</script>
