var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.dataSelected,
            expression: "dataSelected"
          }
        ],
        staticClass: "form-control",
        class: {
          "is-valid": _vm.showSuccess,
          "is-invalid": _vm.requestError && !_vm.isSaving,
          disabled: _vm.isSaving
        },
        attrs: { id: "tag-" + _vm.entryId, name: "tag" },
        on: {
          change: [
            function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.dataSelected = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.handleOnTagChange
          ]
        }
      },
      [
        _c("option", { attrs: { value: "" } }, [
          _vm._v("\n            -- No Tag --\n        ")
        ]),
        _vm._v(" "),
        _vm.dataSelected && _vm.dataSelected.length > 0
          ? _c("option", { domProps: { value: _vm.dataSelected } }, [
              _vm._v("\n            " + _vm._s(_vm.dataSelected) + "\n        ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.computedDataTags, function(tag, index) {
          return _c("option", { key: index, domProps: { value: tag } }, [
            _vm._v("\n            " + _vm._s(tag) + "\n        ")
          ])
        }),
        _vm._v(" "),
        _vm.dataSelected !== "1 - Best of Class"
          ? _c("option", { attrs: { value: "1 - Best of Class" } }, [
              _vm._v("\n            1 - Best of Class\n        ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataSelected !== "2 - Second Award"
          ? _c("option", { attrs: { value: "2 - Second Award" } }, [
              _vm._v("\n            2 - Second Award\n        ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataSelected !== "3 - Third Award"
          ? _c("option", { attrs: { value: "3 - Third Award" } }, [
              _vm._v("\n            3 - Third Award\n        ")
            ])
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.requestError && !_vm.isSaving,
            expression: "requestError && !isSaving"
          }
        ],
        staticClass: "text-danger"
      },
      [_vm._v("\n        " + _vm._s(_vm.requestError) + "\n    ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isSaving,
            expression: "isSaving"
          }
        ],
        staticClass: "mt-1"
      },
      [
        _c("i", { staticClass: "fas fa-spinner fa-spin" }),
        _vm._v(" Saving...\n    ")
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }