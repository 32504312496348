<script>

export default {
  methods: {

    /**
     * Returns the format in m/d/Y
     * @param  {string} manufactureDate A date starting with YYYY-MM-DD
     * @return {string} m/d/Y
     */
    manufactureDate: function (manufactureDate) {
      if (!manufactureDate) {
        return ''
      }
      const year = manufactureDate.substr(0, 4)
      const month = manufactureDate.substr(5, 2)
      const day = manufactureDate.substr(8, 2)

      const date1 = new Date(year, month, day)
      return date1.getMonth() + '/' + date1.getDate() + '/' + date1.getFullYear()
    },
    makeDate (manufactureDate) {
      if (!manufactureDate) {
        return ''
      }
      const year = manufactureDate.substr(0, 4)
      const month = manufactureDate.substr(5, 2) - 1
      const day = manufactureDate.substr(8, 2)

      const date1 = new Date(year, month, day)
      return date1
    }
  }
}
</script>
