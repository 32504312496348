<template>
    <div class="row">
        <div class="col-12 mb-3">
            <button
                class="btn btn-info w-100 mb-2 btn-lg font-weight-bold text-uppercase"
                @click="getEntries(contest.id)"
            >
                <i class="fas fa-plus-circle"></i>&nbsp;
                {{ contest.title }}&nbsp;<span
                    v-if="countEntries > 0"
                    class="badge badge-light"
                >{{ countEntries }}</span>
            </button>
            <div
                v-if="loading"
                class="text-center mt-2"
            >
                <i class="fas fa-spinner fa-2x fa-spin"></i>
            </div>

            <button
                v-if="entries.length > 0"
                class="btn btn-dark w-100 mb-2 mt-2"
                @click="close"
            >
                <i class="fas fa-chevron-up"></i>&nbsp;
                Close
            </button>
            <div
                v-for="(entry, index) in entries"
                :key="entry.id"
                class="border p-3 highlight mx-2"
            >
                <div class="row">
                    <div class="col-12">
                        <h5 class="font-weight-bold">
                            {{ parseFloat(entry.contest_class.number) }}:{{ entry.class_number }} -
                            {{ entry.contest_class.name }}
                            <span
                                v-if="!entry.order.completed"
                                class="badge badge-warning"
                            >Order Not Complete&nbsp;<i class="fas fa-exclamation-circle"></i></span>
                        </h5>
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <strong>Company:</strong><br />
                        {{ entry.location.company }}<br />
                        <div v-if="entry.location.address">
                            {{ entry.location.address }}<br />
                        </div>
                        {{ entry.location.city }}{{ entry.location.state ? `, ${entry.location.state}` : '' }}{{ entry.location.zip ? ` ${entry.location.zip}` : '' }}<br />
                        <div v-if="entry.location.country">
                            {{ entry.location.country }}<br />
                        </div><br />
                        <strong>Date Created:</strong><br />
                        {{ manufactureDate(entry.created_at) }}<br /><br />
                        <strong>Product Name/Brand Name:</strong><br />
                        {{ entry.brand_name }}<br /><br />
                        <strong>Description:</strong><br />
                        {{ entry.description }}<br /><br />
                        <strong>Maker/Team:</strong><br />
                        {{ entry.maker }}<br /><br />

                        <!-- date picker for mfr date -->
                        <div class="row">
                            <div class="col-6 col-md-6">
                                <user-entries-mfr-date
                                    :entry-prop="entry"
                                />
                            </div>
                        </div>

                        <!-- lot information -->
                        <label
                            :for="`lot-number-${index}`"
                            class="mt-3"
                        ><strong>Lot Number / Information:</strong></label>
                        <span
                            v-if="savingInformation === 'lot_number'"
                            class="text-center mt-2 d-inline text-primary"
                        >
                            <i class="fas fa-spinner fa-spin"></i>&nbsp;Saving...
                        </span>
                        <br />
                        <input
                            :id="`lot-number-${index}`"
                            v-model="entry.lot_number"
                            class="form-control w-75 d-inline"
                            :class="{ 'bg-secondary text-white': savingInformation === 'lot_number', 'is-invalid': postScoresheetErrors.lot_number }"
                            name="lot_number"
                            :disabled="savingInformation === 'lot_number'"
                            type="text"
                            @focusout="saveInformation($event, index, 'lot_number')"
                            @focusin="setFocus('lot_number')"
                        />&nbsp;<button
                            v-if="fieldFocus === 'lot_number'"
                            class="btn btn-primary d-inline"
                        >
                            Save
                        </button>
                        <div
                            v-if="postScoresheetErrors.lot_number"
                            class="invalid-feedback"
                        >
                            {{ postScoresheetErrors.lot_number[0] }}
                        </div>

                        <!-- moisture -->
                        <br />
                        <label
                            :for="`moisture-${index}`"
                            class="mt-3"
                        ><strong>Moisture Percentage:</strong></label>
                        <span
                            v-if="savingInformation === 'moisture'"
                            class="text-center mt-2 d-inline text-primary"
                        >
                            <i class="fas fa-spinner fa-spin"></i>&nbsp;Saving...
                        </span>
                        <br />
                        <input
                            :id="`moisture-${index}`"
                            v-model="entry.moisture"
                            class="form-control w-75 d-inline"
                            :class="{ 'bg-secondary text-white': savingInformation === 'moisture', 'is-invalid': postScoresheetErrors.moisture }"
                            name="moisture"
                            :disabled="savingInformation === 'moisture'"
                            type="text"
                            @focusout="saveInformation($event, index, 'moisture')"
                            @focusin="setFocus('moisture')"
                        />&nbsp;<button
                            v-if="fieldFocus === 'moisture'"
                            class="btn btn-primary d-inline"
                        >
                            Save
                        </button>
                        <div
                            v-if="postScoresheetErrors.moisture"
                            class="invalid-feedback"
                        >
                            {{ postScoresheetErrors.moisture[0] }}
                        </div>

                        <!-- user notes -->
                        <br />
                        <label
                            :for="`user_notes-${index}`"
                            class="mt-3"
                        ><strong>Notes for Your Personal Use:</strong></label>
                        <span
                            v-if="savingInformation === 'user_notes'"
                            class="text-center mt-2 d-inline text-primary"
                        >
                            <i class="fas fa-spinner fa-spin"></i>&nbsp;Saving...
                        </span>
                        <br />
                        <textarea
                            :id="`user_notes-${index}`"
                            v-model="entry.user_notes"
                            class="form-control w75 d-inline"
                            :class="{ 'bg-secondary text-white': savingInformation === 'user_notes', 'is-invalid': postScoresheetErrors.user_notes }"
                            name="user_notes"
                            :disabled="savingInformation === 'user_notes'"
                            @focusout="saveInformation($event, index, 'user_notes')"
                            @focusin="setFocus('user_notes')"
                        ></textarea>&nbsp;<button
                            v-if="fieldFocus === 'user_notes'"
                            class="btn btn-primary d-inline"
                        >
                            Save
                        </button>
                        <div
                            v-if="postScoresheetErrors.user_notes"
                            class="invalid-feedback"
                        >
                            {{ postScoresheetErrors.user_notes[0] }}
                        </div>

                        <div
                            v-if="postScoresheetErrors.id"
                            class="alert alert-danger rounded-0 mt-3"
                        >
                            {{ postScoresheetErrors.id[0] }}
                        </div>
                    </div>
                    <div
                        class="col-12 col-md-3 mt-3 mt-md-0"
                    >
                        <div v-if="entry.contest_class.judging_complete && entry.scoresheets_count === 2">
                            <strong>Average Score:</strong><br />
                            <h5 class="mb-0">
                                {{ entry.scoresheets[0].score_average }}
                            </h5>
                            <div><strong>Place:</strong>&nbsp;{{ entry.contest_class_result ? entry.contest_class_result.place : 'n/a' }}</div>
                            <a
                                target="_blank"
                                :href="`/user/scoresheets/entry-pdf/${entry.id}`"
                                class="h5"
                            ><i class="fas fa-file-pdf"></i>&nbsp;Scoresheet</a>
                        </div>
                    </div>

                    <div class="col-12 col-md-3">
                        <div v-if="(entry.contest_class.judging_complete && entry.no_arrival === 1)">
                            <h5><span class="badge badge-warning"><i class="fas fa-exclamation-circle"></i>&nbsp;No Arrival</span></h5>
                        </div>

                        <div
                            v-if="entry.contest_class.judging_complete && entry.scoresheets_count === 2"
                        >
                            <strong>Individual Scores:</strong><br />
                            {{ entry.scoresheets[0].score }} - {{ entry.scoresheets[0].judges_name }}<br />
                            {{ entry.scoresheets[1].score }} - {{ entry.scoresheets[1].judges_name }}
                        </div>
                    </div>
                </div>
            </div>
            <button
                v-if="entries.length > 0"
                class="btn btn-dark w-100 mb-2 mt-2"
                @click="close"
            >
                Close
            </button>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import DateMixin from '../../mixins/DateMixin'
import UserEntriesMfrDate from './UserEntriesMfrDate'

export default {
  name: 'UserEntriesByContest',
  components: {
    'user-entries-mfr-date': UserEntriesMfrDate
  },
  mixins: [DateMixin],
  props: {
    contest: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      activeContest: this.contest.id,
      entries: [],
      countEntries: 0,
      errored: false,
      loading: false,
      savingInformation: false,
      savingMoistureInformation: false,
      postScoresheetErrors: {},
      fieldFocus: null
    }
  },
  methods: {
    setFocus (field) {
      this.fieldFocus = field
    },
    async saveInformation (event, entryIndex, fieldName) {
      this.savingInformation = fieldName
      this.fieldFocus = null

      // send a POST request
      return axios.put(
        `/api-session/entry/${this.entries[entryIndex].id}`,
        {
          id: this.entries[entryIndex].id,
          [fieldName]: this.entries[entryIndex][fieldName]
        }
      )
        .then(response => {
          if (response.data.success) {
            this.postScoresheetErrors = {}
          }
        })
        .catch(error => {
          this.errored = true
          if (error.response && error.response.status && error.response.status === 401) {
            window.location.replace(window.location.href)
            return
          }

          if (error.response && error.response.status && error.response.status === 422) {
            this.postScoresheetErrors = error.response.data.errors
            return
          }

          alert(error.message)
          console.log(error)
        })
        .finally(() => { this.savingInformation = false })
    },
    getEntries () {
      this.entries = []
      this.countEntries = 0
      this.makeRequest()
    },
    close () {
      this.entries = 0
      this.countEntries = 0
    },
    async makeRequest () {
      this.loading = true

      // send a POST request
      return axios.get(
        `/api-session/entries-json/${this.activeContest}`
      )
        .then(response => {
          this.entries = response.data.entries
          this.countEntries = response.data.count
        })
        .catch(error => {
          this.errored = true
          if (error.response && error.response.status && error.response.status === 401) {
            window.location.replace(window.location.href)
            return
          }
          alert(error.message)
          console.log(error)
        })
        .finally(() => { this.loading = false })
      // .then(function () {
      // always executed
      //  return
      // })
    }
  }
}
</script>
