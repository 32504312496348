var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "text-center" }, [
      _vm.loading
        ? _c("span", [
            _c("i", { staticClass: "fa fa-spinner fa-spin" }),
            _vm._v(" " + _vm._s(!_vm.loaded ? "Loading" : "Refreshing") + "...")
          ])
        : _vm._e(),
      _vm._v(" \n    ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "overflow-auto border py-1" },
      [
        _vm.loaded
          ? _c("bar-chart", {
              attrs: { chartdata: _vm.chartdata, height: 2500 }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }