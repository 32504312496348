<template>
    <div>
        <div
            v-if="JSON.stringify(errors) !== '{}'"
            role="alert"
            class="alert alert-warning rounded-0"
        >
            <i class="fas fa-exclamation-circle"></i>&nbsp;&nbsp;Please check the fields below for errors.
        </div>
        <form
            id="entry-update"
            method="post"
            novalidate="novalidate"
            :action="formAction"
        >
            <input
                name="_method"
                type="hidden"
                :value="formMethod"
            >
            <input
                type="hidden"
                name="_token"
                :value="csrf"
            >
            <!-- entry class -->
            <div class="form-row">
                <div class="col-12">
                    <div class="form-group">
                        <label
                            for="entry-class"
                            class="form-control-label"
                        >{{ translations.class }}</label>
                        <select
                            id="entry-class"
                            v-model="localData.contest_class_id"
                            name="contest_class_id"
                            type="text"
                            :class="['form-control', (errors.contest_class_id && errors.contest_class_id.length > 0) ? 'is-invalid' : '']"
                            @change="updateDescription"
                        >
                            <option
                                value=""
                                disabled
                            >
                                -- {{ translations.class }} --
                            </option>
                            <option
                                v-for="contestClass in classes"
                                :key="contestClass.id"
                                :value="contestClass.id"
                            >
                                {{ contestClass.name }} ({{ removeTrailingZero(contestClass.number) }})
                            </option>
                        </select>

                        <div
                            v-if="errors.contest_class_id && errors.contest_class_id.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.contest_class_id[0] }}
                        </div>
                    </div>
                    <div
                        v-if="classDescription"
                        class="mb-3 border p-1 rounded text-info"
                    >
                        <i class="fas fa-info-circle"></i>&nbsp;{{ classDescription }}
                    </div>
                </div>
            </div>

            <div class="form-row">
                <!-- brand name -->
                <div class="col-12">
                    <div class="form-group">
                        <label
                            for="brand-name"
                            class="form-control-label"
                        >{{ translations.brand_name }}</label>
                        <input
                            id="brand-name"
                            v-model="localData.brand_name"
                            type="text"
                            name="brand_name"
                            class="form-control"
                            :class="['form-control', (errors.brand_name && errors.brand_name.length > 0) ? 'is-invalid' : '']"
                        >
                        <div
                            v-if="errors.brand_name && errors.brand_name.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.brand_name[0] }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <!-- description -->
                <div class="col-12">
                    <div class="form-group">
                        <label
                            for="description"
                            class="form-control-label mb-0 mb-md-2"
                        >{{ translations.description }}: </label>
                        <span
                            class="d-block d-md-inline"
                            :class="[
                                {'text-danger': computedDescriptionLength > 200 },
                                {'text-info': 200 - computedDescriptionLength >= 0 }

                            ]"
                        >
                            {{ 200 - computedDescriptionLength }} Characters Left
                        </span>
                        <textarea
                            id="description"
                            v-model="localData.description"
                            name="description"
                            class="form-control"
                            :class="['form-control', (errors.description && errors.description.length > 0) ? 'is-invalid' : '']"
                            @keypress="trimLines"
                            @blur="trimString"
                            @change="trimLines"
                            @paste.prevent
                        />

                        <div
                            v-if="errors.description && errors.description.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.description[0] }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- maker -->
            <div class="form-row">
                <div class="col-12">
                    <div class="form-group">
                        <label
                            for="maker"
                            class="form-control-label"
                        >{{ translations.maker_team }}</label>
                        <input
                            id="maker"
                            v-model="localData.maker"
                            type="text"
                            name="maker"
                            class="form-control"
                            :class="['form-control', (errors.maker && errors.maker.length > 0) ? 'is-invalid' : '']"
                        >
                        <div
                            v-if="errors.maker && errors.maker.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.maker[0] }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- entry company -->
            <div class="form-row">
                <div class="col-12">
                    <div class="form-group">
                        <label
                            for="company"
                            class="form-control-label"
                        >{{ translations.company }}</label>
                        <input
                            id="company"
                            v-model="localData.company"
                            type="text"
                            name="company"
                            class="form-control"
                            :class="['form-control', (errors.company && errors.company.length > 0) ? 'is-invalid' : '']"
                        >
                        <div
                            v-if="errors.company && errors.company.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.company[0] }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- unit/weight/pieces -->
            <div class="form-row">
                <!-- pieces -->
                <div class="col-12 col-md-3">
                    <div class="form-group">
                        <label
                            for="pieces"
                            class="form-control-label"
                        >{{ translations.number_of_pieces }}</label>
                        <input
                            id="pieces"
                            v-model="localData.pieces"
                            type="number"
                            name="pieces"
                            class="form-control"
                            :class="['form-control', (errors.pieces && errors.pieces.length > 0) ? 'is-invalid' : '']"
                        >
                        <div
                            v-if="errors.pieces && errors.pieces.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.pieces[0] }}
                        </div>
                    </div>
                </div>

                <!-- weight -->
                <div class="col-6 col-md-3">
                    <div class="form-group">
                        <label
                            for="weight"
                            class="form-control-label"
                        >{{ translations.weight }}</label>
                        <input
                            id="weight"
                            v-model="localData.weight"
                            type="number"
                            name="weight"
                            class="form-control"
                            :class="['form-control', (errors.weight && errors.weight.length > 0) ? 'is-invalid' : '']"
                        >
                        <div
                            v-if="errors.weight && errors.weight.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.weight[0] }}
                        </div>
                    </div>
                </div>

                <!-- unit -->
                <div class="col-6 col-md-3">
                    <button-input
                        :options="[{ label: 'Lbs', value: 'lbs'}, { label: 'Kgs', value: 'kgs' }]"
                        input-name="weight_unit"
                        :active="(request.weight_unit ? request.weight_unit : 'lbs')"
                        :input-label="translations.weight_unit"
                        input-id="weight-unit"
                    />
                    <div
                        v-if="errors.weight_unit && errors.weight_unit.length > 0"
                        class="invalid-feedback d-block"
                    >
                        {{ errors.weight_unit[0] }}
                    </div>
                </div>
            </div>

            <div class="form-row">
                <!-- manufacture date -->
                <div class="col-6 mb-3">
                    <label
                        for="date-manufacture"
                        class="form-control-label"
                    >{{ translations.date_manufacture }} (MM/DD/YYYY format - editable after submission)</label>
                    <date-picker-contest
                        :date="computedDate"
                        name="date_manufacture"
                        :input-props="{ placeholder: 'MM/DD/YYYY', class: ['form-control', (errors.date_manufacture && errors.date_manufacture.length > 0) ? 'is-invalid' : ''], name: 'date_manufacture', id: 'date-manufacture' }"
                    />
                    <div
                        v-if="errors.date_manufacture && errors.date_manufacture.length > 0"
                        class="invalid-feedback d-block"
                    >
                        {{ errors.date_manufacture[0] }}
                    </div>
                </div>
            </div>

            <div class="form-row">
                <!-- lot number -->
                <div class="col-6">
                    <div class="form-group">
                        <label
                            for="lot_number"
                            class="form-control-label"
                        >{{ translations.lot_number }} (editable after submission)</label>
                        <input
                            id="lot_number"
                            v-model="localData.lot_number"
                            type="text"
                            name="lot_number"
                            class="form-control"
                            :class="['form-control', (errors.lot_number && errors.lot_number.length > 0) ? 'is-invalid' : '']"
                        >
                        <div
                            v-if="errors.lot_number && errors.lot_number.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.lot_number[0] }}
                        </div>
                    </div>
                </div>

                <!-- moisture -->
                <div class="col-6">
                    <div class="form-group">
                        <label
                            for="moisture"
                            class="form-control-label"
                        >{{ translations.moisture }} (editable after submission)</label>
                        <input
                            id="moisture"
                            v-model="localData.moisture"
                            type="text"
                            name="moisture"
                            class="form-control"
                            :class="['form-control', (errors.moisture && errors.moisture.length > 0) ? 'is-invalid' : '']"
                        >
                        <div
                            v-if="errors.moisture && errors.moisture.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.moisture[0] }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- countries -->
            <div class="form-row">
                <div class="col-12">
                    <div class="form-group">
                        <label
                            for="country"
                            class="form-control-label mb-0 mb-md-2"
                        >{{ translations.country }}</label>
                        <span
                            v-if="contest.category !== 'wccc'"
                            class="d-block d-md-inline text-info"
                        >
                            Your entry MUST be from the United States for this contest.
                        </span>
                        <select
                            id="country"
                            v-model="localData.country"
                            name="country"
                            type="text"
                            class="form-control"
                            :class="['form-control', (errors.country && errors.country.length > 0) ? 'is-invalid' : '']"
                            @change="handleCountryState"
                        >
                            <option
                                value=""
                                disabled
                            >
                                -- Country --
                            </option>
                            <option
                                v-for="(value, name) in computedCountries"
                                :key="name"
                                :value="name"
                            >
                                {{ value }}
                            </option>
                        </select>
                        <div
                            v-if="errors.country && errors.country.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.country[0] }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- city/state -->
            <div
                v-if="(selectedCountry !== null)"
                class="form-row"
            >
                <!-- city -->
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label
                            for="city"
                            class="form-control-label"
                        >{{ translations.city }}</label>
                        <input
                            id="city"
                            v-model="localData.city"
                            type="text"
                            name="city"
                            class="form-control"
                            :class="['form-control', (errors.city && errors.city.length > 0) ? 'is-invalid' : '']"
                        >
                        <div
                            v-if="errors.city && errors.city.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.city[0] }}
                        </div>
                    </div>
                </div>

                <!-- state -->
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label
                            for="state"
                            class="form-control-label"
                        >{{ translations.state }}</label>
                        <input
                            v-if="(selectedCountry !== 'US' && selectedCountry !== 'CA')"
                            id="state"
                            v-model="localData.state"
                            type="text"
                            name="state"
                            class="form-control"
                            :class="['form-control', (errors.state && errors.state.length > 0) ? 'is-invalid' : '']"
                        >
                        <select
                            v-if="(selectedCountry === 'US')"
                            id="state"
                            name="state"
                            :class="['form-control', (errors.state && errors.state.length > 0) ? 'is-invalid' : '']"
                        >
                            <option value="">
                                -- State --
                            </option>
                            <option
                                v-for="(name, abbreviation) in unitedStates"
                                :key="abbreviation"
                                :selected="(abbreviation == request.state)"
                                :value="abbreviation"
                            >
                                {{ name }}
                            </option>
                        </select>
                        <select
                            v-if="(selectedCountry === 'CA')"
                            id="state"
                            name="state"
                            :class="['form-control', (errors.state && errors.state.length > 0) ? 'is-invalid' : '']"
                        >
                            <option value="">
                                -- Province --
                            </option>
                            <option
                                v-for="(name, abbreviation) in canadianProvinces"
                                :key="abbreviation"
                                :selected="(abbreviation == request.state)"
                                :value="abbreviation"
                            >
                                {{ name }}
                            </option>
                        </select>
                        <div
                            v-if="errors.state && errors.state.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.state[0] }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- notes -->
                <div class="col-12">
                    <div class="form-group">
                        <label
                            for="user_notes"
                            class="form-control-label"
                        >{{ translations.user_notes }}</label>
                        <input
                            id="user_notes"
                            v-model="localData.user_notes"
                            type="text"
                            name="user_notes"
                            class="form-control"
                            :class="['form-control', (errors.user_notes && errors.user_notes.length > 0) ? 'is-invalid' : '']"
                        >
                        <div
                            v-if="errors.user_notes && errors.user_notes.length > 0"
                            class="invalid-feedback"
                        >
                            {{ errors.user_notes[0] }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <div class="col text-right">
                    <submit-clear-block
                        clear-title="Reset"
                        :search-title="searchTitle"
                        font-awesome-icon="fas fa-shopping-cart"
                    >
                    </submit-clear-block>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

import DatePickerContest from './DatePickerContest'
import ButtonInput from './ButtonInput'
import SubmitClearBlock from './SubmitClearBlock'
import GeographyHelper from '../Helpers/GeographyHelper'

export default {
  components: {
    'date-picker-contest': DatePickerContest,
    'button-input': ButtonInput,
    'submit-clear-block': SubmitClearBlock
  },
  props: {
    contest: {
      type: Object,
      required: true
    },
    searchTitle: {
      type: String,
      default: 'Add to Cart'
    },
    formMethod: {
      type: String,
      default: 'post'
    },
    translations: {
      type: Object,
      required: true,
      default: function () {
        return {
          class: 'Class',
          company: 'Company',
          city: 'City',
          state: 'State',
          country: 'Country',
          maker: 'Maker/Team',
          brand_name: 'Brand Name',
          date_manufacture: 'Manufacture Date',
          weight: 'Total Weight',
          weight_unit: 'Unit',
          number_of_pieces: 'Number of Pieces',
          moisture: '% Moisture',
          lot_number: 'Lot Number'
        }
      }
    },
    csrf: {
      type: String,
      required: true
    },
    formAction: {
      type: String,
      default: null
    },
    classes: {
      type: Object,
      required: true
    },
    countries: {
      type: Object,
      default: function () {
        return {}
      }
    },
    request: {
      type: Object,
      default: function () {
        return {
          contest_class_id: null,
          company: null,
          maker: null,
          brand_name: null,
          date_manufacture: null,
          city: null,
          state: null,
          country: null,
          weight: null,
          weight_unit: null,
          pieces: null,
          moisture: null,
          lot_number: null
        }
      }
    },
    errors: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      localData: {},
      classDescription: null,
      selectedCountry: null,
      canadianProvinces: GeographyHelper.canadianProvinces,
      unitedStates: GeographyHelper.unitedStates
    }
  },
  computed: {
    computedCountries () {
      if (this.contest.category && this.contest.category === 'usccc') {
        return { US: 'United States' }
      }
      return this.countries
    },
    computedDescriptionLength () {
      let length = 0
      if (this.localData.description) {
        length = this.localData.description.length
      }
      return length
    },
    computedDate () {
      if (!this.request.date_manufacture) {
        return null
      } else {
        let date = this.request.date_manufacture.split('/')
        if (date.length === 3) {
          return new Date(date[2], date[0] - 1, date[1])
        }

        date = this.request.date_manufacture.split('-')
        if (date.length === 3) {
          return new Date(date[0], date[1] - 1, date[2].substring(0, 2))
        }
      }
      return null
    }
  },
  mounted: function () {
    this.localData = this.request

    this.classDescription = null

    if (this.classes[this.request.contest_class_id]) {
      this.classDescription = this.classes[this.request.contest_class_id].description
    }
    if (this.request.country) {
      this.selectedCountry = this.request.country
    }

    this.trimLines()
  },
  methods: {
    trimString () {
      if (this.localData.description) {
        this.localData.description = this.localData.description.trim()
        return this.localData.description
      }
    },
    trimLines () {
      if (this.localData.description) {
        this.localData.description = this.localData.description.replace(/(\r\n|\n|\r)/gm, ' ').replace(/ +(?= )/g, '')
        return this.localData.description
      }
    },
    removeTrailingZero (number) {
      return parseFloat(number)
    },
    handleCountryState (event) {
      this.request.state = null
      this.selectedCountry = event.target.value
    },
    updateDescription (event) {
      this.classDescription = null
      if (this.classes[event.target.value]) {
        this.classDescription = this.classes[event.target.value].description
      }
    }
  }
}
</script>
