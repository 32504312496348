var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", [_vm._v("Scoresheets in localStorage")]),
      _vm._v(" "),
      _vm._l(_vm.localStorageValues, function(value, key) {
        return _c("div", { key: key }, [
          key.includes("scoresheet")
            ? _c("div", [
                _c("hr"),
                _vm._v(" "),
                _c("strong", [_vm._v(_vm._s(key))]),
                _vm._v(" "),
                _c("br"),
                _vm._v(_vm._s(value) + "\n        ")
              ])
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }