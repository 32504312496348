<template>
    <div class="form-group">
        <select
            :id="'tag-' + entryId"
            v-model="dataSelected"
            name="tag"
            class="form-control"
            :class="{ 'is-valid' : showSuccess, 'is-invalid': requestError && !isSaving, 'disabled': isSaving }"
            @change="handleOnTagChange"
        >
            <option value="">
                -- No Tag --
            </option>
            <option
                v-if="dataSelected && dataSelected.length > 0"
                :value="dataSelected"
            >
                {{ dataSelected }}
            </option>

            <option
                v-for="(tag, index) in computedDataTags"
                :key="index"
                :value="tag"
            >
                {{ tag }}
            </option>

            <option
                v-if="dataSelected !== '1 - Best of Class'"
                value="1 - Best of Class"
            >
                1 - Best of Class
            </option>
            <option
                v-if="dataSelected !== '2 - Second Award'"
                value="2 - Second Award"
            >
                2 - Second Award
            </option>
            <option
                v-if="dataSelected !== '3 - Third Award'"
                value="3 - Third Award"
            >
                3 - Third Award
            </option>
        </select>
        <div
            v-show="requestError && !isSaving"
            class="text-danger"
        >
            {{ requestError }}
        </div>
        <div
            v-show="isSaving"
            class="mt-1"
        >
            <i class="fas fa-spinner fa-spin"></i>&nbsp;Saving...
        </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
  props: {
    tags: {
      type: Array,
      required: true
    },
    selected: {
      type: String,
      default: null
    },
    entryId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      isSaving: false,
      showSuccess: false,
      dataSelected: '',
      dataTags: [],
      requestError: null,
      firstSelected: ''
    }
  },
  computed: {
    computedDataTags () {
      const tags = []
      if (this.firstSelected && this.firstSelected.length > 0 && this.firstSelected !== this.dataSelected) {
        tags.push(this.firstSelected)
      }
      this.dataTags.filter((item) => {
        if (item.tag !== this.dataSelected && !tags.includes(item.tag)) {
          tags.push(item.tag)
        }
      })
      return tags.sort()
    }
  },
  watch: {
    selected: function (newVal, oldVal) {
      this.selected = newVal
      this.dataSelected = newVal
      this.firstSelected = newVal
    }
  },
  mounted () {
    this.dataSelected = this.selected
    this.firstSelected = this.selected
    this.dataTags = this.tags
  },
  methods: {
    handleOnTagChange (e) {
      try {
        this.isSaving = true
        return axios.put(
          `/api-session/tag-entry/${this.entryId}`,
          { tag: e.target.value }
        ).then((response) => {
          this.requestError = false
          if (response.data.success) {
            this.showSuccess = true

            this.$emit('taggingClick', { event: e, entryId: this.entryId, selected: this.selected })

            const vm = this
            setTimeout(function () { vm.showSuccess = false }, 2000)
          }
        }).catch((error) => {
          if (error.response && error.response.status && error.response.status === 401) {
            window.location.replace(window.location.href)
            return
          }
          this.$emit('taggingError', { error: error, entryId: this.entryId, selected: this.selected })

          this.requestError = error.message
          alert(error.message)
          console.log(error)
          return error
        }).finally(() => {
          this.isSaving = false
        })
      } catch (ex) {
        alert(ex)
        console.log(ex)
      }
    }
  }
}
</script>
