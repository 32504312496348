<template>
    <div class="btn-group btn-group-toggle d-block">
        <button
            type="submit"
            :class="['btn', 'btn-primary', (disabledButtons) ? 'd-none' : '']"
            style="width: 75%"
            @click="buttonClick"
        >
            <i :class="fontAwesomeIcon"></i>&nbsp;{{ searchTitle }}
        </button><button
            type="submit"
            :class="['btn', 'btn-light border border-primary rounded', (disabledButtons) ? 'd-inline' : 'd-none']"
            style="width: 100%"
            disabled="true"
        >
            <i class="fas fa-spinner fa-spin"></i>&nbsp;Working...
        </button><button
            type="button"
            class="btn btn-dark"
            style="width: 25%"
            :onclick="resetString"
            :class="(disabledButtons) ? 'd-none' : ''"
            @click="buttonClick"
        >
            {{ clearTitle }}
        </button>
    </div>
</template>

<script>
export default {
  name: 'SubmitClearBlock',
  props: {
    resetLocation: {
      type: String,
      default: window.location.pathname
    },
    searchTitle: {
      type: String,
      default: 'Search'
    },
    clearTitle: {
      type: String,
      default: 'Clear'
    },
    fontAwesomeIcon: {
      type: String,
      default: 'fas fa-search'
    },
    spinnerOnSubmit: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      disabledButtons: false
    }
  },
  computed: {
    resetString: function () {
      return `window.location.href = '${this.resetLocation}';`
    }
  },
  methods: {
    buttonClick () {
      if (this.spinnerOnSubmit) {
        this.disabledButtons = true
        setTimeout(function () { this.disabledButtons = false }, 10000)
      }
    }
  }
}
</script>
