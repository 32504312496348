var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.filteredInputDefects.Flavor_Defects
        ? _c("div", { staticClass: "alert alert-info" }, [
            _c("i", { staticClass: "fas fa-info-circle" }),
            _vm._v(" You did not deduct anything for flavor.\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isValid,
              expression: "!isValid"
            }
          ],
          staticClass: "alert alert-warning"
        },
        [
          _c("i", { staticClass: "fas fa-exclamation-circle" }),
          _vm._v("  Please check the scoresheet for errors.\n    ")
        ]
      ),
      _vm._v(" "),
      _c("h5", { staticClass: "bg-dark p-2 text-white" }, [
        _vm._v("\n        Entry Information\n    ")
      ]),
      _vm._v("\n    " + _vm._s(_vm.activeEntry.class.number) + ":"),
      _vm.activeEntry.class_number < 10 ? _c("span", [_vm._v("0")]) : _vm._e(),
      _vm._v(
        _vm._s("" + _vm.activeEntry.class_number) +
          " " +
          _vm._s(_vm.activeEntry.class.name) +
          "\n\n    "
      ),
      _c("h5", { staticClass: "bg-dark p-2 text-white mt-3" }, [
        _vm._v("\n        Scoresheet Information\n    ")
      ]),
      _vm._v(" "),
      _c("strong", [_vm._v("Your Name: " + _vm._s(_vm.activeJudge))]),
      _c("br"),
      _vm._v(" "),
      _c("strong", [
        _vm._v(
          "Is Comp Store?: " +
            _vm._s(_vm.inputOtherFields.isCompScore ? "Yes" : "No")
        )
      ]),
      _vm._v(" "),
      _vm.inputOtherFields.isCompScore
        ? _c("div", [
            _c("strong", [_vm._v("Complimentary Score Reason:")]),
            _c("br"),
            _vm._v(
              "\n        " +
                _vm._s(_vm.inputOtherFields.compScoreReason) +
                "\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.filteredInputDefects, function(i, defectGroup) {
        return _c(
          "div",
          { key: defectGroup, staticClass: "mt-3" },
          [
            _c("h5", { staticClass: "bg-dark p-2 text-white" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.translateString(defectGroup.replaceAll("_", " "))
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _vm._l(_vm.filteredInputDefects[defectGroup], function(defect, i2) {
              return _c("div", { key: i2 }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.translateString(defect.name)) +
                    ": " +
                    _vm._s(defect.value)
                ),
                _c("br")
              ])
            })
          ],
          2
        )
      }),
      _vm._v(" "),
      _c("h5", { staticClass: "bg-dark p-2 text-white mt-3" }, [
        _vm._v("\n        Score\n    ")
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v(_vm._s(_vm.scoreCalculated))])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }