<template>
    <div class="w-100">
        <div class="mt-3">
            <strong>Date Manufacture (MM/DD/YYYY):</strong>
            <span
                v-if="savingManufactureDate"
                class="text-center mt-2 d-inline text-primary"
            >
                <i class="fas fa-spinner fa-spin"></i>&nbsp;Saving...
            </span>
        </div>
        <v-date-picker
            :value="makeDate(entry.date_manufacture)"
            mode="single"
            :update-on-input="true"
            :name="`date_manufacture[${entry.id}]`"
            :input-props="{ class: { 'form-control': true, 'bg-secondary text-white': savingManufactureDate }, 'placeholder': 'MM/DD/YYYY' }"
            @input="updateDate($event, entry.id)"
        />
        <div
            v-if="postScoresheetErrors.date_manufacture"
            class="invalid-feedback d-block"
        >
            {{ postScoresheetErrors.date_manufacture[0] }}
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import DateMixin from '../../mixins/DateMixin'

export default {
  mixins: [DateMixin],
  props: {
    entryProp: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      errored: false,
      savingManufactureDate: false,
      postScoresheetErrors: {},
      entry: {}
    }
  },
  mounted () {
    this.entry = this.entryProp
  },
  methods: {
    async updateDate (index, entryId) {
      const datestamp = Date.parse(index)

      if (!isNaN(datestamp)) {
        const date = new Date(datestamp)
        const month = `${date.getMonth() + 1}`.padStart(2, '0')
        const day = `${date.getDate()}`.padStart(2, '0')
        this.entry.date_manufacture = date.getFullYear() + '-' + month + '-' + day
      } else {
        this.entry.date_manufacture = index
      }

      this.savingManufactureDate = true
      // send a POST request
      return axios.put(
        `/api-session/entry/${entryId}`,
        { id: entryId, date_manufacture: this.entry.date_manufacture }
      )
        .then(response => {
          if (response.data.success) {
            this.postScoresheetErrors = {}
          }
        })
        .catch(error => {
          this.errored = true
          if (error.response && error.response.status && error.response.status === 401) {
            window.location.replace(window.location.href)
            return
          }

          if (error.response && error.response.status && error.response.status === 422) {
            this.postScoresheetErrors = error.response.data.errors
            return
          }

          alert(error.message)
          console.log(error)
        })
        .finally(() => { this.savingManufactureDate = false })
    }
  }
}
</script>
