var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("div", { staticClass: "mt-3" }, [
        _c("strong", [_vm._v("Date Manufacture (MM/DD/YYYY):")]),
        _vm._v(" "),
        _vm.savingManufactureDate
          ? _c(
              "span",
              { staticClass: "text-center mt-2 d-inline text-primary" },
              [
                _c("i", { staticClass: "fas fa-spinner fa-spin" }),
                _vm._v(" Saving...\n        ")
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("v-date-picker", {
        attrs: {
          value: _vm.makeDate(_vm.entry.date_manufacture),
          mode: "single",
          "update-on-input": true,
          name: "date_manufacture[" + _vm.entry.id + "]",
          "input-props": {
            class: {
              "form-control": true,
              "bg-secondary text-white": _vm.savingManufactureDate
            },
            placeholder: "MM/DD/YYYY"
          }
        },
        on: {
          input: function($event) {
            return _vm.updateDate($event, _vm.entry.id)
          }
        }
      }),
      _vm._v(" "),
      _vm.postScoresheetErrors.date_manufacture
        ? _c("div", { staticClass: "invalid-feedback d-block" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.postScoresheetErrors.date_manufacture[0]) +
                "\n    "
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }