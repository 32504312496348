<template>
    <div class="row">
        <div class="col-12">
            <div
                v-if="isLoadingContest"
                class="w-100 text-center h3"
            >
                <i
                    class="fas fa-spinner fa-spin"
                ></i>&nbsp;Loading...
            </div>
            <div
                v-for="contest in contests"
                :id="'contest-' + contest.id"
                :key="contest.id"
            >
                <a
                    class="btn btn-primary mb-3 d-block w-100 btn-lg rounded-0 font-weight-bold"
                    :disabled="isLoadingClasses"
                    :class="{
                        active: (contest.id == activeContest),
                        disabled: isLoadingClasses
                    }"
                    @click.prevent="getContestClasses(contest.id)"
                >
                    <i
                        class="fas"
                        :class="{
                            'fa-chevron-up': (contest.id == activeContest && isLoadingClasses == false),
                            'fa-spinner fa-spin': (contest.id == activeContest && isLoadingClasses == true),
                            'fa-chevron-down': (contest.id != activeContest),
                        }"
                    ></i>&nbsp;
                    <i
                        class="fas"
                        :class="{
                            'fa-globe': (contest.category == 'wccc'),
                            'fa-flag-usa': (contest.category == 'usccc')
                        }"
                    ></i>
                    {{ contest.title }}
                </a>

                <!-- Final Three -->
                <div v-if="contest.id == activeContest">
                    <a
                        class="btn btn-info w-100 mb-3 font-weight-bold rounded-0"
                        :class="{
                            active: (finalThreeResultsVisible === false)
                        }"
                        @click.prevent="showFinalThreeResults"
                    >
                        <i
                            class="fas"
                            :class="{ 'fa-chevron-up' : (finalThreeResultsVisible === true), 'fa-chevron-down' : (finalThreeResultsVisible === false) }"
                        />
                        CHAMPION and Runners Up
                    </a>

                    <div
                        v-if="!isLoadingFinalThree && finalThreeResults[activeContest] && finalThreeResultsVisible == true"
                        class="container border mb-3"
                    >
                        <div class="row">
                            <div
                                v-for="(final, key) in finalThreeResults[activeContest].results"
                                :key="key"
                                class="col-12 col-md-4 p-2"
                            >
                                <h5>{{ awardTitle(key, final) }}</h5>
                                <strong>Maker:</strong><br />{{ final.maker }}<br />

                                <strong>Cheese:</strong><br />{{ final.description }}<br />
                                <strong>Location:</strong><br />
                                {{ final.company }}<br />
                                {{ final.city }}{{ (final.state) ? `,&nbsp;${final.state}` : '' }}<br />
                                {{ final.country }}<br />
                                <strong>Score:</strong><br />
                                {{ parseFloat(final.score) }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="contest.id == activeContest && !isLoadingClasses">
                    <!-- view top three per class results -->
                    <a
                        v-if="!showTop3PerClass"
                        class="btn btn-info w-100 mb-3 font-weight-bold rounded-0"
                        :class="{
                            active: (contest.id == activeContestTopThree),
                            disabled: isLoadingTopThreePerClass
                        }"
                        @click.prevent="getTopThree(contest.id)"
                    >
                        <i
                            class="fas fa-chevron-right"
                        />
                        View Top 3 Per Class
                    </a>

                    <!-- view all results -->
                    <a
                        v-if="showTop3PerClass"
                        class="btn btn-info w-100 mb-3 font-weight-bold rounded-0"
                        @click.prevent="getByClass()"
                    >
                        <i
                            class="fas fa fa-chevron-left"
                        />
                        View All Results by Class
                    </a>

                    <div class="border border-dark p-2 mb-3">
                        <h4 class="text-center py-2 bg-dark text-white">
                            <i class="fas fa-award"></i>&nbsp;Viewing {{ showTop3PerClass ? 'Top Three / Class' : 'Results by Class' }}
                        </h4>

                        <!-- The top 3 per class results -->
                        <div
                            v-show="showTop3PerClass == true"
                        >
                            <div :class="{ 'text-center': isLoadingTopThreePerClass }">
                                <i
                                    v-if="(showTop3PerClass == true && isLoadingTopThreePerClass == true)"
                                    class="fas fa-spinner fa-spin fa-3x"
                                ></i>

                                <div
                                    v-for="(result, index) in topThreeResults[contest.id]"
                                    v-show="(showTop3PerClass && !isLoadingTopThreePerClass)"
                                    :id="'result-' + result.id"
                                    :key="index"
                                >
                                    <h5 class="mt-3 bg-dark text-white p-2">
                                        {{ result.class.name }}&nbsp;({{ cleanClassNumber(result.class.number) }})
                                    </h5>
                                    <div
                                        v-for="(entry, entryId) in result.entries"
                                        :key="entryId"
                                        class="container"
                                    >
                                        <div class="d-md-none">
                                            <div class="font-weight-bold">
                                                Place: {{ entry.place }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-2">
                                                <div class="d-md-none font-weight-bold">
                                                    Team/Maker:
                                                </div>
                                                {{ entry.maker }}
                                            </div>
                                            <div class="col-12 col-md-3">
                                                <div class="d-md-none font-weight-bold">
                                                    Company:
                                                </div>
                                                {{ entry.company }}
                                            </div>
                                            <div class="col-12 col-md-3">
                                                {{ entry.city }}{{ (entry.state) ? `,&nbsp;${entry.state}` : '' }}<br />
                                                {{ entry.country }}
                                            </div>
                                            <div class="col-12 col-md-1">
                                                <div v-if="entry.score_average !== null">
                                                    <div class="d-md-none font-weight-bold">
                                                        Score:
                                                    </div>
                                                    {{ parseFloat(entry.score_average) }}
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-1">
                                                <span v-if="entry.place == 1">Best of Class</span>
                                                <span v-if="entry.place == 2">Second Award</span>
                                                <span v-if="entry.place == 3">Third Award</span>
                                            </div>
                                            <div class="col-12 col-md-2">
                                                <div class="d-md-none font-weight-bold">
                                                    Description:
                                                </div>
                                                {{ entry.brand_name }}
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- The contest class results -->
                        <div
                            v-for="contestClass in contestClasses[contest.id]"
                            v-show="!showTop3PerClass"
                            :id="'class-' + contestClass.id"
                            :key="contestClass.id"
                        >
                            <a
                                class="btn btn-light w-100 rounded-0 mb-2"
                                :disabled="isLoadingResults"
                                :class="{
                                    'font-weight-bold active': (contestClass.id == activeContestClass),
                                    disabled: (isLoadingResults == true)
                                }"
                                @click.prevent="getClassResults(contestClass.id)"
                            >
                                <i
                                    class="fas"
                                    :class="{
                                        'fa-chevron-up': (contestClass.id == activeContestClass && isLoadingResults == false),
                                        'fa-spinner fa-spin': (contestClass.id == activeContestClass && isLoadingResults == true),
                                        'fa-chevron-down': (contestClass.id != activeContestClass),
                                    }"
                                ></i>&nbsp;
                                {{ contestClass.name }}&nbsp;({{ cleanClassNumber(contestClass.number) }})
                            </a>
                            <div
                                v-if="contestClass.id == activeContestClass && !isLoadingResults"
                                class="mb-3"
                            >
                                <div
                                    v-for="(score, scoreKey) in classResults[activeContestClass].entry_scores"
                                    :key="scoreKey"
                                >
                                    <div
                                        v-for="(entry, key) in sortEntries(score.entries)"
                                        :key="key"
                                    >
                                        <div class="d-md-none">
                                            <div class="font-weight-bold">
                                                Place: {{ scoreKey + 1 }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-2">
                                                <div class="d-md-none font-weight-bold">
                                                    Team/Maker:
                                                </div>
                                                {{ entry.maker }}
                                            </div>
                                            <div class="col-12 col-md-3">
                                                <div class="d-md-none font-weight-bold">
                                                    Company:
                                                </div>
                                                {{ entry.company }}
                                            </div>
                                            <div class="col-12 col-md-3">
                                                {{ entry.city }}{{ (entry.state) ? `,&nbsp;${entry.state}` : '' }}<br />
                                                {{ entry.country }}
                                            </div>
                                            <div class="col-12 col-md-1">
                                                <div v-if="entry.score_average !== null">
                                                    <div class="d-md-none font-weight-bold">
                                                        Score:
                                                    </div>
                                                    {{ parseFloat(entry.score_average) }}
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-1">
                                                <span v-if="scoreKey == 0">Best of Class</span>
                                                <span v-if="scoreKey == 1">Second Award</span>
                                                <span v-if="scoreKey == 2">Third Award</span>
                                            </div>
                                            <div class="col-12 col-md-2">
                                                <div class="d-md-none font-weight-bold">
                                                    Description:
                                                </div>
                                                {{ entry.brand_name }}
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      showTop3PerClass: false,
      topThreeResults: {},
      activeContestTopThree: 0,
      activeContest: 0,
      activeContestClass: 0,
      clickWasContestClass: false,
      contests: [],
      contestClasses: {},
      classResults: {},
      finalThreeResults: {},
      finalThreeResultsVisible: true,
      isLoadingContest: false,
      isLoadingClasses: false,
      isLoadingFinalThree: false,
      isLoadingResults: false,
      isLoadingTopThreePerClass: false
    }
  },
  mounted: function () {
    this.getContests()
  },
  beforeUpdate: function () {
    // Scroll to if it was a contest click.
    if (this.activeContest !== 0 && this.activeContestClass === 0 && !this.clickWasContestClass) {
      const element = this.$el.querySelector('#contest-' + this.activeContest)
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 0)
    }

    // Scroll to if it was a contest class click.
    if (this.activeContest !== 0 && this.activeContestClass !== 0) {
      const element = this.$el.querySelector('#class-' + this.activeContestClass)
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 0)
    }
    this.clickWasContestClass = false
  },
  methods: {
    showFinalThreeResults () {
      this.activeContestClass = 0
      if (this.finalThreeResultsVisible === true) {
        this.finalThreeResultsVisible = false
        return
      }
      if (this.finalThreeResultsVisible === false) {
        this.finalThreeResultsVisible = true
      }
    },
    awardTitle (i, final) {
      if (i === 0) {
        return (final.contest_category === 'wccc') ? 'WORLD CHAMPION' : 'U.S. CHAMPION'
      }
      if (i === 1) {
        return 'First Runner Up'
      }
      if (i === 2) {
        return 'Second Runner Up'
      }
    },
    getByClass () {
      this.finalThreeResultsVisible = false
      this.showTop3PerClass = false
    },
    getTopThree (contestId) {
      try {
        this.finalThreeResultsVisible = false
        this.getTopThreePerClass(contestId).then(() => {
          this.showTop3PerClass = true
          this.activeContestClass = 0
        })
      } catch (ex) {
        console.log(ex)
      }
    },
    sortEntries (entries) {
      entries.sort(function (a, b) {
        if (a.company > b.company) return 1
        if (b.company > a.company) return -1
        return 0
      })
      return entries
    },
    closeContestClass () {
      this.activeContestClass = 0
    },
    cleanClassNumber (classNumber) {
      return parseFloat(classNumber)
    },
    getContestClasses (contestId) {
      try {
        this.showTop3PerClass = false
        this.activeContestClass = 0
        if (this.activeContest === contestId) {
          this.activeContest = 0
          this.activeContestClass = 0
          this.finalThreeResultsVisible = true
          return
        }
        this.getFinalThree(contestId)
        this.getClasses(contestId)
      } catch (ex) {
        console.log(ex)
      }
    },
    getClassResults (classId) {
      this.clickWasContestClass = true
      try {
        if (classId === this.activeContestClass) {
          this.closeContestClass()
          return
        }
        this.activeContestClass = classId
        this.getResults(classId).then(() => {

        })
      } catch (ex) {
        console.log(ex)
      }
    },
    setTopThree (contestId, topThree) {
      this.topThreeResults[Number(contestId)] = topThree
    },
    setContests (contests) {
      this.contests = contests
    },
    setContestClasses (contestId, classes) {
      this.contestClasses[Number(contestId)] = classes
    },
    setContestResults (classId, results) {
      this.classResults[Number(classId)] = results
    },
    getContests () {
      this.isLoadingContest = true

      if (this.contests.length === 0) {
        try {
          return axios.get(
            '/api/results/contests'
          ).then((response) => {
            this.setContests(response.data)
          }).catch((error) => {
            alert(error.message)
            this.isLoadingContest = false
            console.log(error)
            return error
          }).finally(() => {
            this.isLoadingContest = false
          })
        } catch (ex) {
          console.log(ex)
        }
      }
    },
    async getTopThreePerClass (contestId) {
      try {
        this.isLoadingTopThreePerClass = true
        this.activeContestTopThree = contestId
        if (!this.topThreeResults[Number(contestId)]) {
          axios.get(
            '/api/results/contest-top-three-per-class/' + contestId
          ).then((response) => {
            this.setTopThree(contestId, response.data.results)
          }).catch((error) => {
            alert(error.message)
            console.log(error)
            return error
          }).finally(() => {
            this.isLoadingTopThreePerClass = false
          })
        } else {
          this.isLoadingTopThreePerClass = false
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async getClasses (contestId) {
      try {
        this.activeContest = contestId
        this.activeContestClass = 0
        this.isLoadingClasses = true

        if (!this.contestClasses[Number(contestId)]) {
          axios.get(
            `/api/results/contest-classes/${contestId}`
          ).then((response) => {
            this.setContestClasses(contestId, response.data)
          }).catch((error) => {
            alert(error.message)
            this.isLoadingClasses = false
            this.activeContest = 0
            console.log(error)
            return error
          }).finally(() => {
            this.isLoadingClasses = false
          })
        } else {
          this.isLoadingClasses = false
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async getFinalThree (contestId) {
      try {
        this.isLoadingFinalThree = true
        if (!this.finalThreeResults[Number(contestId)]) {
          axios.get(
            `/api/results/contest-top-three/${contestId}`
          ).then((response) => {
            this.finalThreeResults[Number(contestId)] = response.data
          }).catch((error) => {
            alert(error.message)
            console.log(error)
            return error
          }).finally(() => {
            this.isLoadingFinalThree = false
          })
        } else {
          this.isLoadingFinalThree = false
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async getResults (classId) {
      try {
        this.isLoadingResults = true

        if (!this.classResults[Number(classId)]) {
          axios.get(
            `/api/results/contest-class-results/${classId}`
          ).then((response) => {
            this.setContestResults(classId, response.data)
          }).catch((error) => {
            alert(error.message)
            this.isLoadingResults = false
            console.log(error)
            return error
          }).finally(() => {
            this.isLoadingResults = false
          })
        } else {
          this.isLoadingResults = false
        }
      } catch (ex) {
        console.log(ex)
      }
    }
  }
}
</script>
