var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { attrs: { for: "contest-select" } }, [_vm._v("Contest")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.request.contest_id,
                expression: "request.contest_id"
              }
            ],
            class: [
              "form-control",
              _vm.errors.contest_id && _vm.errors.contest_id.length > 0
                ? "is-invalid"
                : ""
            ],
            attrs: { id: "contest-select", name: "contest_id" },
            on: {
              change: [
                function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.request,
                    "contest_id",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
                _vm.handleOnContestChange
              ]
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [
              _vm._v(
                "\n                    -- All Contests --\n                "
              )
            ]),
            _vm._v(" "),
            _vm._l(_vm.localContests, function(contest) {
              return _c(
                "option",
                { key: contest.id, domProps: { value: contest.id } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.trimTitle(contest.title)) +
                      "...\n                "
                  )
                ]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _vm.errors.contest_id && _vm.errors.contest_id.length > 0
          ? _c("div", { staticClass: "invalid-feedback" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.errors.contest_id[0]) +
                  "\n            "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _vm.localContestClasses && _vm.localContestClasses.length > 0
          ? _c("div", [
              _c("label", { attrs: { for: "class-select" } }, [
                _vm._v("Class")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.request.class_id,
                      expression: "request.class_id"
                    }
                  ],
                  class: [
                    "form-control",
                    _vm.errors.class_id && _vm.errors.class_id.length > 0
                      ? "is-invalid"
                      : ""
                  ],
                  attrs: { id: "class-select", name: "class_id" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.request,
                          "class_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.handleOnClassChange
                    ]
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(
                      "\n                        -- All Classes --\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.localContestClasses, function(contestClass) {
                    return _c(
                      "option",
                      {
                        key: contestClass.id,
                        domProps: { value: contestClass.id }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.removeTrailingZero(contestClass.number)
                            ) +
                            " - " +
                            _vm._s(contestClass.name) +
                            "\n                    "
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.errors.class_id && _vm.errors.class_id.length > 0
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.class_id[0]) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }