var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.activeJudge
      ? _c(
          "div",
          [
            _c("h5", [_vm._v("Select Your Language:")]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "div",
              _vm._l(_vm.languages, function(language, key) {
                return _c(
                  "a",
                  {
                    key: key,
                    staticClass: "btn btn-sm btn-secondary mt-1 mr-2",
                    class: { active: key === _vm.languageActive },
                    on: {
                      click: function($event) {
                        return _vm.setLanguage($event, key)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " + _vm._s(language) + "\n            "
                    )
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("h5", [_vm._v("Select Your Name:")]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm._l(_vm.judges, function(judge) {
              return _c("div", { key: judge }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", value: judge },
                    on: { click: _vm.setActiveJudge }
                  },
                  [
                    _vm._v(
                      "\n                " + _vm._s(judge) + "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("hr")
              ])
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.activeJudge
      ? _c("div", [
          _c("h5", [
            _vm._v(
              "\n            Judging as: " +
                _vm._s(_vm.activeJudge) +
                "\n           \n            "
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary mt-1 mt-md-0",
                attrs: { type: "button", role: "button" },
                on: { click: _vm.unsetActiveJudge }
              },
              [_vm._v("\n                Change\n            ")]
            )
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          !_vm.activeEntry
            ? _c(
                "div",
                _vm._l(_vm.entries, function(entry, index) {
                  return _c("div", { key: index }, [
                    _vm.checkScoredAlready(entry)
                      ? _c(
                          "div",
                          {
                            staticClass: "btn",
                            staticStyle: { cursor: "initial" }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(entry.entry.contest_class.number) +
                                ":" +
                                _vm._s(entry.entry.class_number) +
                                "\n                "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.checkScoredAlready(entry)
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button", value: index },
                            on: { click: _vm.setActiveEntry }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(entry.entry.contest_class.number) +
                                ":" +
                                _vm._s(entry.entry.class_number) +
                                "\n                "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("hr")
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeEntry !== null && !_vm.successfulSubmit
            ? _c("div", [
                _c("h4", { staticClass: "bg-dark text-white p-2" }, [
                  _vm._v(
                    "\n                Entry: " +
                      _vm._s(_vm.activeEntry.entry.contest_class.number) +
                      ":" +
                      _vm._s(_vm.activeEntry.entry.class_number) +
                      "\n                 \n                "
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-secondary",
                      on: { click: _vm.unsetActiveEntry }
                    },
                    [_vm._v("\n                    Change\n                ")]
                  )
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    attrs: { method: "post", novalidate: "novalidate" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c("label", { attrs: { for: "score-appearance" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.translations[_vm.languageActive]["Appearance"]
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.score.score_appearance,
                              expression: "score.score_appearance"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": !_vm.fieldIsValid("score-appearance")
                          },
                          attrs: {
                            id: "score-appearance",
                            type: "number",
                            step: ".01"
                          },
                          domProps: { value: _vm.score.score_appearance },
                          on: {
                            change: _vm.checkInput,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.score,
                                "score_appearance",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.fieldIsValid("score-appearance"),
                                expression: "!fieldIsValid('score-appearance')"
                              }
                            ],
                            staticClass: "invalid-feedback"
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.errors["score-appearance"]) +
                                "\n                        "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c("label", { attrs: { for: "score-flavor" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.translations[_vm.languageActive]["Flavor"]
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.score.score_flavor,
                              expression: "score.score_flavor"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": !_vm.fieldIsValid("score-flavor")
                          },
                          attrs: {
                            id: "score-flavor",
                            type: "number",
                            step: ".01"
                          },
                          domProps: { value: _vm.score.score_flavor },
                          on: {
                            change: _vm.checkInput,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.score,
                                "score_flavor",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.fieldIsValid("score-flavor"),
                                expression: "!fieldIsValid('score-flavor')"
                              }
                            ],
                            staticClass: "invalid-feedback"
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.errors["score-flavor"]) +
                                "\n                        "
                            )
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _vm.totalScoreValid
                          ? _c(
                              "div",
                              [
                                _c("hr"),
                                _vm._v(" "),
                                _c("h5", [
                                  _vm._v(
                                    "Score: " + _vm._s(_vm.score.total_score)
                                  )
                                ]),
                                _vm._v(" "),
                                _c("hr"),
                                _vm._v(" "),
                                _vm.isSubmitting
                                  ? _c(
                                      "div",
                                      { staticClass: "text-center h5" },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-spinner fa-spin"
                                        }),
                                        _vm._v(
                                          " Saving\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.postScoresheetErrors, function(
                                  postErrors,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "alert alert-danger rounded-0"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-exclamation-circle"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(postErrors[0]) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                !_vm.isSubmitting
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary w-100",
                                        attrs: {
                                          type: "button",
                                          role: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.submitScore($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-gavel"
                                        }),
                                        _vm._v(
                                          " Submit Score\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.totalScoreValid
                          ? _c("div", [
                              _c("label", { attrs: { for: "button" } }, [
                                _vm._v(" ")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "alert alert-warning rounded-0"
                                },
                                [
                                  _vm._v(
                                    "\n                                Please check for errors. Your total score is " +
                                      _vm._s(_vm.score.total_score) +
                                      ".\n                            "
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.successfulSubmit
            ? _c("div", { staticClass: "alert alert-success rounded-0" }, [
                _c("i", { staticClass: "fas fa-check-circle" }),
                _vm._v(" Your score was saved. \n            "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-success",
                    on: { click: _vm.unsetActiveEntry }
                  },
                  [
                    _vm._v(
                      "\n                Select another entry.\n            "
                    )
                  ]
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }