<template>
    <div>
        <div
            v-if="hideAll !== false"
        >
            <p class="alert alert-danger">
                {{ hideAll }}. <a :href="selfHref">Click here to reload the page and again.</a>
            </p>
        </div>
        <div v-if="hideAll == false">
            <div class="h5 font-weight-bold">
                {{ dataEntries.length }} Entries:
            </div>
            <div class="border border-dark py-1 px-2 mb-2">
                <div class="d-block d-md-inline">
                    Sort By:&nbsp;
                </div>

                <button
                    type="button"
                    class="btn btn-outline-primary btn-sm font-weight-bold"
                    value="class_number"
                    :class="{ 'active': activeSort == 'class_number'}"
                    @click="sortBy('class_number')"
                >
                    <i
                        v-show="activeSort === 'class_number'"
                        class="fas fa-check"
                    ></i>
                    Entry #
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary btn-sm font-weight-bold"
                    value="score_average"
                    :class="{ 'active': activeSort == 'score_average'}"
                    @click="sortBy('score_average')"
                >
                    <i
                        v-show="activeSort === 'score_average'"
                        class="fas fa-check"
                    ></i>
                    Score
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary btn-sm font-weight-bold"
                    value="tag"
                    :class="{ 'active': activeSort == 'tag'}"
                    @click="sortBy('tag')"
                >
                    <i
                        v-show="activeSort === 'tag'"
                        class="fas fa-check"
                    ></i>
                    Tag
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary btn-sm font-weight-bold"
                    value="picked"
                    :class="{ 'active': activeSort == 'picked'}"
                    @click="sortBy('picked')"
                >
                    <i
                        v-show="activeSort === 'picked'"
                        class="fas fa-check"
                    ></i>
                    Picked
                </button>
            </div>
            <div
                v-for="(entry, id) in dataEntries"
                :key="id"
                class="row border-bottom border-dark"
            >
                <div class="col-6 col-lg-2 my-3">
                    <span :class="{ 'h5': activeSort === 'class_number' }"><strong>Entry:</strong></span><br />
                    <h4>{{ entry.contest_class.number }}:{{ entry.class_number }}</h4>
                    <span
                        v-if="entry.scoresheets && entry.scoresheets[0] && entry.scoresheets[0].comp_score"
                        class="bg-warning rounded p-1"
                    >
                        Comp. Score
                    </span>
                </div>
                <div class="col-6 col-lg-3 my-3">
                    <span :class="{ 'h5': activeSort === 'tag' }"><strong>Tag:</strong></span><br />
                    <entry-tagging
                        :tags="tags"
                        :selected="tagSelected(entry)"
                        :entry-id="entry.id"
                        @taggingError="handleTaggingError"
                        @taggingClick="handleTaggedEvent"
                    >
                    </entry-tagging>
                </div>
                <div class="col-12 col-lg-3 my-3">
                    <span><strong>Company:</strong></span><br />
                    {{ entry.location.company }}<br />
                    {{ (entry.location.city.length > 0) ? entry.location.city : '' }}{{ (entry.location.state.length > 0) ? `, ${entry.location.state}` : '' }}
                    <div v-if="(entry.location.country.length > 0)">
                        {{ entry.location.country }}
                    </div>
                    <div class="mt-2">
                        <span><strong>Weight:</strong></span><br />
                        {{ entry.weight }} {{ entry.weight_unit }}&nbsp;
                        <span><strong>Pieces:</strong></span>&nbsp;
                        {{ entry.pieces }}<br />
                        <span><strong>Description:</strong></span><br />
                        {{ entry.description }}
                    </div>
                </div>
                <div
                    class="col-12 col-lg-1 my-3"
                    :class="{ 'h5': activeSort === 'score_average' }"
                >
                    <span><strong>Score:</strong></span><br />
                    {{ entry.scoresheets && entry.scoresheets[0] && entry.scoresheets[0].score_average ? parseFloat(entry.scoresheets[0].score_average) : 'Not Judged' }}
                </div>

                <div class="col-12 col-lg-3 my-3">
                    <div class="mb-1">
                        <span :class="{ 'h5': activeSort === 'picked' }"><strong>Picked:</strong></span>
                    </div>
                    <button-input
                        :options="[{ label: 'Yes', value: '1' }, { label: 'No', value: '0' }]"
                        input-name="picked"
                        :active="entry.picked.toString()"
                        input-label=""
                        :input-id="`entry-${id.toString()}`"
                        @clicked="setPicked"
                    >
                    </button-input>
                    <div
                        v-show="isSaving && id == isSaving"
                        class="mt-2"
                    >
                        <i class="fas fa-spinner fa-spin"></i>&nbsp;Saving
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import EntryTagging from './EntryTagging'
import ButtonInput from './ButtonInput'
import axios from 'axios'
import arraySort from 'array-sort'

export default {
  components: {
    'button-input': ButtonInput,
    'entry-tagging': EntryTagging
  },
  props: {
    tags: {
      type: Array,
      required: true
    },
    entries: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      activeSort: 'class_number',
      showSuccess: false,
      isSaving: false,
      dataEntries: this.entries,
      dataActiveEntryId: null,
      hideAll: false
    }
  },
  computed: {
    selfHref () {
      return window.location.href
    }
  },
  methods: {
    sortBy (field) {
      this.activeSort = field

      if (field === 'score_average') {
        this.dataEntries = arraySort(this.dataEntries, (a, b) => {
          // nulls sort before anything else
          if (a.contest_class_result === null) {
            return -1
          } else if (b.contest_class_result === null) {
            return 1
          } else if (Number(a.scoresheets[0].score_average) === Number(b.scoresheets[0].score_average)) {
            return 0
          } else if (Number(a.scoresheets[0].score_average) > Number(b.scoresheets[0].score_average)) {
            return -1
          }
          return 1
        })
      }

      if (field === 'class_number') {
        this.dataEntries = arraySort(this.dataEntries, 'class_number')
        return
      }

      if (field === 'tag') {
        this.dataEntries = arraySort(this.dataEntries, ['tag', 'location.company'])
        return
      }

      if (field === 'picked') {
        this.dataEntries = arraySort(this.dataEntries, ['picked', 'class_number'])
      }
    },
    handleTaggingError (object) {
      // const localId = this.dataEntries.findIndex(o => o.id === object.entryId)
    },
    handleTaggedEvent (object) {
      const localId = this.dataEntries.findIndex(o => o.id === object.entryId)
      this.dataEntries[localId].tag = object.event.target.value
    },
    tagSelected (entry) {
      if(entry && entry.tag) {
        return (entry.tag.length > 0) ? entry.tag : ''
      }
    },
    setPicked (value) {
      this.dataActiveEntryId = value. id.replace('entry-', '')

      try {
        this.isSaving = this.dataActiveEntryId
        return axios.put(
          `/api-session/picking/entry/${this.dataEntries[this.dataActiveEntryId].id}`,
          { picked: value.value }
        ).then((response) => {
          if (response.data.success) {
            this.showSuccess = true
            this.dataEntries[this.dataActiveEntryId].picked = value.value
            const vm = this
            setTimeout(function () { vm.showSuccess = false }, 2000)
          }
        }).catch((error) => {
          if (error.response && error.response.status && error.response.status === 401) {
            window.location.replace(window.location.href)
            return
          }

          this.hideAll = error.message

          console.log(error)
          return error
        }).finally(() => {
          this.isSaving = false
        })
      } catch (ex) {
        alert(ex)
        console.log(ex)
      }
    }
  }
}
</script>
