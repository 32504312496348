/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap'
import Vuelidate from 'vuelidate'
import jQuery from 'jquery'
import VCalendar from 'v-calendar'

window.Vue = require('vue')

window.Vue.use(Vuelidate)

window.Vue.use(VCalendar)

// load jquery popper and bootstrap
try {
  window.Popper = require('popper.js').default
  window.$ = window.jQuery = jQuery
  require('bootstrap')
  require('util')
} catch (e) {}

// load jquery/bootstrap tooltips based up on data-toggle.
$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./main/components', true, /\.vue$/i)
files.keys().map(key => window.Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('ContestClassSelect', require('./main/components/Form/ContestClassSelect.vue').default)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

/* eslint-disable no-unused-vars */
const app = new Vue({
  el: '#app',
  data: {
    state: window.state
  }
})
/* eslint-enable no-unused-vars */
