var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12 mb-3" },
      [
        _c(
          "button",
          {
            staticClass:
              "btn btn-info w-100 mb-2 btn-lg font-weight-bold text-uppercase",
            on: {
              click: function($event) {
                return _vm.getEntries(_vm.contest.id)
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-plus-circle" }),
            _vm._v(" \n            " + _vm._s(_vm.contest.title) + " "),
            _vm.countEntries > 0
              ? _c("span", { staticClass: "badge badge-light" }, [
                  _vm._v(_vm._s(_vm.countEntries))
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _vm.loading
          ? _c("div", { staticClass: "text-center mt-2" }, [
              _c("i", { staticClass: "fas fa-spinner fa-2x fa-spin" })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.entries.length > 0
          ? _c(
              "button",
              {
                staticClass: "btn btn-dark w-100 mb-2 mt-2",
                on: { click: _vm.close }
              },
              [
                _c("i", { staticClass: "fas fa-chevron-up" }),
                _vm._v(" \n            Close\n        ")
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.entries, function(entry, index) {
          return _c(
            "div",
            { key: entry.id, staticClass: "border p-3 highlight mx-2" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("h5", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(parseFloat(entry.contest_class.number)) +
                        ":" +
                        _vm._s(entry.class_number) +
                        " -\n                        " +
                        _vm._s(entry.contest_class.name) +
                        "\n                        "
                    ),
                    !entry.order.completed
                      ? _c("span", { staticClass: "badge badge-warning" }, [
                          _vm._v("Order Not Complete "),
                          _c("i", { staticClass: "fas fa-exclamation-circle" })
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("hr")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-md-6" }, [
                  _c("strong", [_vm._v("Company:")]),
                  _c("br"),
                  _vm._v(
                    "\n                    " + _vm._s(entry.location.company)
                  ),
                  _c("br"),
                  _vm._v(" "),
                  entry.location.address
                    ? _c("div", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(entry.location.address)
                        ),
                        _c("br")
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n                    " +
                      _vm._s(entry.location.city) +
                      _vm._s(
                        entry.location.state ? ", " + entry.location.state : ""
                      ) +
                      _vm._s(entry.location.zip ? " " + entry.location.zip : "")
                  ),
                  _c("br"),
                  _vm._v(" "),
                  entry.location.country
                    ? _c("div", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(entry.location.country)
                        ),
                        _c("br")
                      ])
                    : _vm._e(),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Date Created:")]),
                  _c("br"),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.manufactureDate(entry.created_at))
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Product Name/Brand Name:")]),
                  _c("br"),
                  _vm._v("\n                    " + _vm._s(entry.brand_name)),
                  _c("br"),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Description:")]),
                  _c("br"),
                  _vm._v("\n                    " + _vm._s(entry.description)),
                  _c("br"),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Maker/Team:")]),
                  _c("br"),
                  _vm._v("\n                    " + _vm._s(entry.maker)),
                  _c("br"),
                  _c("br"),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6 col-md-6" },
                      [
                        _c("user-entries-mfr-date", {
                          attrs: { "entry-prop": entry }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "mt-3",
                      attrs: { for: "lot-number-" + index }
                    },
                    [_c("strong", [_vm._v("Lot Number / Information:")])]
                  ),
                  _vm._v(" "),
                  _vm.savingInformation === "lot_number"
                    ? _c(
                        "span",
                        {
                          staticClass: "text-center mt-2 d-inline text-primary"
                        },
                        [
                          _c("i", { staticClass: "fas fa-spinner fa-spin" }),
                          _vm._v(" Saving...\n                    ")
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: entry.lot_number,
                        expression: "entry.lot_number"
                      }
                    ],
                    staticClass: "form-control w-75 d-inline",
                    class: {
                      "bg-secondary text-white":
                        _vm.savingInformation === "lot_number",
                      "is-invalid": _vm.postScoresheetErrors.lot_number
                    },
                    attrs: {
                      id: "lot-number-" + index,
                      name: "lot_number",
                      disabled: _vm.savingInformation === "lot_number",
                      type: "text"
                    },
                    domProps: { value: entry.lot_number },
                    on: {
                      focusout: function($event) {
                        return _vm.saveInformation($event, index, "lot_number")
                      },
                      focusin: function($event) {
                        return _vm.setFocus("lot_number")
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(entry, "lot_number", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.fieldFocus === "lot_number"
                    ? _c(
                        "button",
                        { staticClass: "btn btn-primary d-inline" },
                        [
                          _vm._v(
                            "\n                        Save\n                    "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.postScoresheetErrors.lot_number
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.postScoresheetErrors.lot_number[0]) +
                            "\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "mt-3",
                      attrs: { for: "moisture-" + index }
                    },
                    [_c("strong", [_vm._v("Moisture Percentage:")])]
                  ),
                  _vm._v(" "),
                  _vm.savingInformation === "moisture"
                    ? _c(
                        "span",
                        {
                          staticClass: "text-center mt-2 d-inline text-primary"
                        },
                        [
                          _c("i", { staticClass: "fas fa-spinner fa-spin" }),
                          _vm._v(" Saving...\n                    ")
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: entry.moisture,
                        expression: "entry.moisture"
                      }
                    ],
                    staticClass: "form-control w-75 d-inline",
                    class: {
                      "bg-secondary text-white":
                        _vm.savingInformation === "moisture",
                      "is-invalid": _vm.postScoresheetErrors.moisture
                    },
                    attrs: {
                      id: "moisture-" + index,
                      name: "moisture",
                      disabled: _vm.savingInformation === "moisture",
                      type: "text"
                    },
                    domProps: { value: entry.moisture },
                    on: {
                      focusout: function($event) {
                        return _vm.saveInformation($event, index, "moisture")
                      },
                      focusin: function($event) {
                        return _vm.setFocus("moisture")
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(entry, "moisture", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.fieldFocus === "moisture"
                    ? _c(
                        "button",
                        { staticClass: "btn btn-primary d-inline" },
                        [
                          _vm._v(
                            "\n                        Save\n                    "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.postScoresheetErrors.moisture
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.postScoresheetErrors.moisture[0]) +
                            "\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "mt-3",
                      attrs: { for: "user_notes-" + index }
                    },
                    [_c("strong", [_vm._v("Notes for Your Personal Use:")])]
                  ),
                  _vm._v(" "),
                  _vm.savingInformation === "user_notes"
                    ? _c(
                        "span",
                        {
                          staticClass: "text-center mt-2 d-inline text-primary"
                        },
                        [
                          _c("i", { staticClass: "fas fa-spinner fa-spin" }),
                          _vm._v(" Saving...\n                    ")
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: entry.user_notes,
                        expression: "entry.user_notes"
                      }
                    ],
                    staticClass: "form-control w75 d-inline",
                    class: {
                      "bg-secondary text-white":
                        _vm.savingInformation === "user_notes",
                      "is-invalid": _vm.postScoresheetErrors.user_notes
                    },
                    attrs: {
                      id: "user_notes-" + index,
                      name: "user_notes",
                      disabled: _vm.savingInformation === "user_notes"
                    },
                    domProps: { value: entry.user_notes },
                    on: {
                      focusout: function($event) {
                        return _vm.saveInformation($event, index, "user_notes")
                      },
                      focusin: function($event) {
                        return _vm.setFocus("user_notes")
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(entry, "user_notes", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.fieldFocus === "user_notes"
                    ? _c(
                        "button",
                        { staticClass: "btn btn-primary d-inline" },
                        [
                          _vm._v(
                            "\n                        Save\n                    "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.postScoresheetErrors.user_notes
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.postScoresheetErrors.user_notes[0]) +
                            "\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.postScoresheetErrors.id
                    ? _c(
                        "div",
                        { staticClass: "alert alert-danger rounded-0 mt-3" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.postScoresheetErrors.id[0]) +
                              "\n                    "
                          )
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-md-3 mt-3 mt-md-0" }, [
                  entry.contest_class.judging_complete &&
                  entry.scoresheets_count === 2
                    ? _c("div", [
                        _c("strong", [_vm._v("Average Score:")]),
                        _c("br"),
                        _vm._v(" "),
                        _c("h5", { staticClass: "mb-0" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(entry.scoresheets[0].score_average) +
                              "\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("strong", [_vm._v("Place:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                entry.contest_class_result
                                  ? entry.contest_class_result.place
                                  : "n/a"
                              )
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "h5",
                            attrs: {
                              target: "_blank",
                              href: "/user/scoresheets/entry-pdf/" + entry.id
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-file-pdf" }),
                            _vm._v(" Scoresheet")
                          ]
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-md-3" }, [
                  entry.contest_class.judging_complete && entry.no_arrival === 1
                    ? _c("div", [_vm._m(0, true)])
                    : _vm._e(),
                  _vm._v(" "),
                  entry.contest_class.judging_complete &&
                  entry.scoresheets_count === 2
                    ? _c("div", [
                        _c("strong", [_vm._v("Individual Scores:")]),
                        _c("br"),
                        _vm._v(
                          "\n                        " +
                            _vm._s(entry.scoresheets[0].score) +
                            " - " +
                            _vm._s(entry.scoresheets[0].judges_name)
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                        " +
                            _vm._s(entry.scoresheets[1].score) +
                            " - " +
                            _vm._s(entry.scoresheets[1].judges_name) +
                            "\n                    "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ]
          )
        }),
        _vm._v(" "),
        _vm.entries.length > 0
          ? _c(
              "button",
              {
                staticClass: "btn btn-dark w-100 mb-2 mt-2",
                on: { click: _vm.close }
              },
              [_vm._v("\n            Close\n        ")]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("span", { staticClass: "badge badge-warning" }, [
        _c("i", { staticClass: "fas fa-exclamation-circle" }),
        _vm._v(" No Arrival")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }